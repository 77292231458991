import React, {useEffect} from 'react';

import {Rings} from 'react-loader-spinner';

export const AppLoader = () => {

	useEffect(() => {

		const script1 = document.createElement('script');
		script1.src = 'https://code.createjs.com/1.0.0/createjs.min.js';
		document.head.appendChild(script1);

		let script2;

		script1.onload = () => {

			script2 = document.createElement('script');
			script2.src = '/assets/js/preloader.js?=v=1.1';
			document.head.appendChild(script2);

			script2.onload = () => {

				window.LoaderInit();

			};

		};

		return () => {
			document.head.removeChild(script1);
			if(script2 !== undefined) {
				document.head.removeChild(script2);
			}

		};

	}, [])

	return (

		<>
			<div className={"main-loading"}>
				<div id="animation_container">
					<canvas id="canvas" width="285" height="285"></canvas>
					<div id="dom_overlay_container">
					</div>
				</div>
			</div>
		</>

	);

}

export const Loader = ({text, theme}) =>{
	return (
		<div className={'component-loader'}>
			<div className={'loader-icon d-flex flex-column align-items-center'}>
				<Rings
					height="80"
					width="80"
					color={theme === 'light' ? '#ffffff' : '#5100b9'}
					radius="6"
					wrapperStyle={{}}
					wrapperClass=""
					visible={true}
					ariaLabel="rings-loading"
				/>
				{text}
			</div>
		</div>

	);
}

export const LoaderOverlay = () => {
	return(
		<div className="position-fixed top-0 start-0 w-100 h-100 overlay-loading d-flex justify-content-center align-items-center">
			<Rings height="125" width="125" color="#5100b9" radius="6" visible={true} ariaLabel="rings-loading"/>
		</div>
	)
}