import {User} from 'react-feather';

export default {
	currency: {
		pln: 'pln',
		usd: 'usd',
		eur: 'eur'
	},
	languages: {
		pl: 'Polski',
		en: 'Angielski',
		uk: 'Ukraiński'
	},
	job: {
		contract: {
			0: 'anyAgreement',
			1: 'b2b',
			2: 'employmentContract',
			3: 'orderAgreement',
			4: 'contractForWork',
			5: 'internshipAgreement'
		},
		working_mode: {
			0: 'remoteWork',
			1: 'hybridWork',
			2: 'officeWork'
		},
		working_time: {
			0: 'fullTime',
			1: 'partTime',
			2: 'internship',
			3: 'freelance'
		},
		sorting: {
			0: "newest",
			1: "oldest",
			2: "popular",
			3: "unpopular"
		},
		salary_type: {
			0: "hourlyWage",
			1: "weeklySalary",
			2: "monthlySalary"
		}
	},
	report: {
		reasons: {
			0: "spam",
			1: "nudity",
			2: "hateSpeech",
			3: "violence",
			4: "illegalGoods",
			5: "harassment",
			6: "suicide",
			7: "eatingDisorders",
			8: "scam",
			9: "falseInformation",
			10: "dontLike",
			11: "againstLaw",
		}
	},
	ranges: {
		1: '+1 km',
		2: '+2 km',
		5: '+5 km',
		10: '+10 km',
		25: '+25 km',
		50: '+10 km',
	},
	range_default: 1,
	amenities_icons: {
		user: <User/>
	}
};