import React from 'react';

const SkeletonUserList = () => {
	return(
		<div className="skeleton-user-list">
			<div className={'skeleton skeleton-chat'}></div>
			<div className={'skeleton skeleton-chat'}></div>
			<div className={'skeleton skeleton-chat'}></div>
			<div className={'skeleton skeleton-chat'}></div>
			<div className={'skeleton skeleton-chat'}></div>
		</div>
	)
}

export default SkeletonUserList;