import React, {useEffect} from "react";
import {Col, Row} from 'react-bootstrap';

const SingleSkeleton = () => {

	useEffect(() => {
		window.scroll({
			top: 0,
			left: 0,
			behavior: 'smooth'
		});

	}, []);

	return (
		<div className="skeleton-job mb-5">
			<div className="skeleton mt-4 mb-4 pt-2 pb-2">
				&nbsp;
			</div>
			<Row>
				<Col md={4}>
					<div className={'img skeleton rounded-4'}>
						&nbsp;
					</div>
				</Col>
				<Col md={8} className={'job-header'}>
					<Row className="mb-2">
						<Col md={4}>
							<div className="skeleton">
								&nbsp;
							</div>
						</Col>
					</Row>
					<Row>
						<Col md={3}>
							<div className="skeleton">
								&nbsp;
							</div>
						</Col>
						<Col md={3}>
							<div className="skeleton">
								&nbsp;
							</div>
						</Col>
					</Row>
					<div className="skeleton mt-4 desc">
						&nbsp;
					</div>
					<div className="mt-4">
						<Row>
							<Col md={2}>
								<div className="skeleton">
									&nbsp;
								</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col md={3}>
								<div className="skeleton">
									&nbsp;
								</div>
							</Col>
							<Col md={3}>
								<div className="skeleton">
									&nbsp;
								</div>
							</Col>
							<Col md={3}>
								<div className="skeleton">
									&nbsp;
								</div>
							</Col>
						</Row>
					</div>
					<div className="mt-4">
						<Row>
							<Col md={2}>
								<div className="skeleton">
									&nbsp;
								</div>
							</Col>
						</Row>
						<Row className="mt-2">
							<Col md={3}>
								<div className="skeleton">
									&nbsp;
								</div>
							</Col>
							<Col md={3}>
								<div className="skeleton">
									&nbsp;
								</div>
							</Col>
						</Row>
					</div>
				</Col>
			</Row>
		</div>
	)
}

export default SingleSkeleton;
