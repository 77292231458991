import {Formik} from "formik";
import {Form} from "react-bootstrap";
import ValidationError from "../../Parts/ValidationError";
import React, {useState} from "react";
import {Rings} from "react-loader-spinner";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import {resetPassword} from 'aws-amplify/auth';
import ProfileError from "../../Profile/Edit/Parts/ProfileError";

const ForgotForm = ({setAuthType}) => {

	const { t } = useTranslation();

	const [loading, setLoading] = useState(false)

	const [error, setError] = useState({
		status: false,
		text: ''
	})

	const initial = {
		email: localStorage.getItem('forgot-email') ? localStorage.getItem('forgot-email') : ''
	}

	const handleSubmit = async (values) => {
		setLoading(true)
		try{
			const {isPasswordReset, nextStep} = await resetPassword({username: values.email});
			if(nextStep.resetPasswordStep === 'CONFIRM_RESET_PASSWORD_WITH_CODE' && !isPasswordReset){
				setAuthType('forgot-verify')
				localStorage.setItem('forgot-email', values.email)
				setLoading(false)
			}
		}
		catch(err){
			setError({
				status: true,
				text: err.message
			})
			setLoading(false)
		}
	}

	const handleBack = () => {
		localStorage.removeItem('forgot-email')
		setAuthType('login')
	}

	const Schema = {
		email: Yup.string().required(t('auth.register.registerForm.validation.email.required')).email(t('auth.register.registerForm.validation.email.email')),
	};

	return(
		<Formik validateOnChange={false} validateOnBlur={false} initialValues={initial} onSubmit={handleSubmit} validationSchema={Yup.object().shape(Schema)}>
			{({handleSubmit, values, handleBlur, handleChange, errors, touched}) => (
				<Form onSubmit={handleSubmit} className="auth-form mb-5">
					<span className="font-weight-lg font-lg">
						Enter email:
					</span>
					<Form.Group id="email" className="my-3 position-relative">
						<Form.Control className={`${errors.email ? 'border border-danger' : 'border border-white'}`} type="email" name="email" value={values.email} placeholder="E-mail" onChange={handleChange} onBlur={handleBlur}/>
						<ProfileError profileError={errors.email} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'} />
					</Form.Group>
					<button disabled={loading} className="btn btn-primary w-100 mt-3 font-lg auth-submit-button d-flex justify-content-center" type={'submit'}>
						{!loading && <>Send Restart Link</>}
						{loading && <Rings height="28" width="28" color="#fff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
					</button>
					<ValidationError isErrorVisible={error.status} errorMessage={error.text} additionalClass={'position-absolute'} />
					<div className="d-flex justify-content-center mt-4">
						<button type="submit" onClick={handleBack} className="d-flex justify-content-center bg-transparent border-0 text-decoration-underline font-lg font-weight-md">
							Back
						</button>
					</div>
				</Form>
			)}
		</Formik>
	)
}

export default ForgotForm;