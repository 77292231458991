import React, { useState } from 'react';
import { ReactComponent as Hide } from '../../assets/svg/home/hide.svg';
import { ReactComponent as Show } from '../../assets/svg/home/show.svg';

const TogglePassword = ({ passwordVisible, onToggle }) => {
	return (
		<span
			tabIndex={-1}
			className="show-hide-password position-absolute top-50"
			onClick={onToggle}
		>
			{passwordVisible ? <Hide /> : <Show />}
		</span>
	);
};

// bryar.berk@moonversion.com
export default TogglePassword;