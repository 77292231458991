import React, {useEffect, useRef, useState} from "react";
import {Container} from 'react-bootstrap';
import SkeletonLoop from '../Browse/Skeleton/SkeletonLoop';
import {Page} from '../Layout/Page';
import {useTranslation} from 'react-i18next';
import TopBar from './TopBar';
import useApi from "../../Hooks/useApi";
import {Configure, useInfiniteHits, useInstantSearch} from "react-instantsearch";
import useUser from "../../Hooks/ReduxHooks/useUser";
import Item from "../Browse/Item/Item";
import {HitsPerPage} from "../../Config/AlgoliaConfig";
import '../Browse/Browse.scss';
import SliderPopup from "../Browse/SliderPopup/SliderPopup";
import useWatchlist from "../../Hooks/ReduxHooks/useWatchlist";
const DisplayItems = ({hits, setIndex, sentinelRef}) => {

	/**
	 *
	 * Handle click on item to open popup
	 * @param item
	 *
	 */

	const {user} = useUser();

	const handleClick = (item) => {

		const findIndex = hits.findIndex(hit => hit.uuid === item.uuid);

		if(findIndex !== -1) {

			setIndex(findIndex);

		}

		else {

			alert('unable to open popup, cannot find index');

		}

	};

	const parsedHits = user ? hits.filter(hit => user.hidden_profiles.includes(hit.uuid) === false) : hits;

	return (
		<div className="app-browse-loop">
			{parsedHits.map((hit, index) => <Item key={index} item={hit} handleClick={handleClick}/>)}
			<div ref={sentinelRef} aria-hidden="true"></div>
		</div>
	)
}

const DisplayObservations = () => {

	const {hits, isLastPage, showMore} = useInfiniteHits();

	const [hitsData, setHitsData] = useState(hits);

	const sentinelRef = useRef(null);

	const [loading, setLoading] = useState(true);

	const {status = 'loading'} = useInstantSearch();

	const {observations, observeLoading} = useWatchlist();

	const [index, setIndex] = useState(-1);

	useEffect(() => {
		setHitsData(hits)
	}, [hits]);

	useEffect(() => {

		if(status === 'loading') {

			setLoading(true);

		}
		else {

			setLoading(false);

		}

	}, [status])

	/**
	 * Infinite scroll
	 */

	useEffect(() => {
		if(sentinelRef.current !== null) {
			const observer = new IntersectionObserver((entries) => {
				entries.forEach((entry) => {
					if(entry.isIntersecting && !isLastPage && hits.length === HitsPerPage) {
						showMore();
					}
				});
			});
			observer.observe(sentinelRef.current);
			return () => {
				observer.disconnect();
			};
		}
	}, [isLastPage, showMore]);

	/**
	 * No results
	 */

	if((hitsData.length === 0 && status !== 'loading') || observations.length === 0 || observeLoading) {

		return <div>Brak obserwacji</div>

	}

	const observationsListObjectID = observations.map(el => 'objectID:' +el)

	return (
		<div className={'mt-4'}>
			<SkeletonLoop count={20} loading={loading || status === 'loading' || observations.length === 0}/>
			<Configure filters={observationsListObjectID.join(' OR ')} />
			<DisplayItems hits={hitsData} sentinelRef={sentinelRef} setIndex={setIndex}/>
			{index !== -1 && <SliderPopup index={index} setIndex={setIndex} showMore={showMore} total={hits.length} items={hits}/>}
		</div>
	)
}

const Observations = () => {

	const {t} = useTranslation();

	const {handleFetchObservations} = useWatchlist();

	useEffect(() => {
		handleFetchObservations();
	} ,[])

	return (
		<Page title={t('pageTitles.observations')}>
			<section className="profile">
				<Container>
					<TopBar title={t('profile.observations.title')} desc={t('profile.observations.text')} />
					<DisplayObservations />
				</Container>
			</section>
		</Page>
	);

};

export default Observations;
