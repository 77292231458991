const timestampToDate = (timestamp, language, type = 'all') => {
	const date = new Date(timestamp);
	const isToday = date.toDateString() === new Date().toDateString();
	const now = new Date();
	const yesterday = new Date(now.setDate(now.getDate() - 1));
	const isYesterday = date.toDateString() === yesterday.toDateString();

	const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: language !== 'pl' };
	const dateOptions = language === 'pl'
		? { day: '2-digit', month: '2-digit', year: 'numeric' }
		: { month: '2-digit', day: '2-digit', year: 'numeric' };

	const prefix = isToday ? (language === 'pl' ? 'Dzisiaj' : 'Today') :
		isYesterday ? (language === 'pl' ? 'Wczoraj' : 'Yesterday') : '';

	// Handling different types of output based on the 'type' parameter
	switch(type) {
	case 'date':
		return date.toLocaleDateString(language === 'pl' ? 'pl-PL' : 'en-US', dateOptions);
	case 'time':
		return date.toLocaleTimeString(language === 'pl' ? 'pl-PL' : 'en-US', timeOptions);
	case 'all':
	default:
		if (prefix) {
			return `${prefix} ${date.toLocaleTimeString(language === 'pl' ? 'pl-PL' : 'en-US', timeOptions)}`;
		}
		else {
			const combinedOptions = { ...dateOptions, ...timeOptions };
			return date.toLocaleString(language === 'pl' ? 'pl-PL' : 'en-US', combinedOptions);
		}
	}
};

export default timestampToDate;