import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import callToAPI from "../../api";

export const fetchObservations = createAsyncThunk(
	'watchlist/fetch',
	async () => {
		return await callToAPI({
			url: '/profile/observations',
			method: 'get',
		})
	}
)

const initialState = {
	observations: [],
	observeLoading: false,
}

const watchlistSlice = createSlice({
	name: 'watchlist',
	initialState,
	reducers: {
		setObserveLoadings(state, action){
			state.observeLoading = action.payload
		},
		setObservations(state, action){
			state.observations = action.payload;
		}
	},
	extraReducers: (builder) => {
		builder.addCase(fetchObservations.fulfilled, (state, action) => {
			state.observations = action.payload;
			state.observeLoading = false;
		})
		builder.addCase(fetchObservations.pending, (state, actions) => {
			state.observeLoading = true;
		})
	}
})

export const {
	setObserveLoadings,
	setObservations
} = watchlistSlice.actions;

export default watchlistSlice.reducer;