import {Alert} from "react-bootstrap";
import useError from "../../../Hooks/ReduxHooks/useError";

const ProfileStatus = () => {

	const {indexStatusValue} = useError();

	if(indexStatusValue === 'initial' || indexStatusValue === undefined){
		return
	}

	const statusConfig = {
		rejected: {
			text: 'Account verification was declined,',
			variant: 'danger'
		},
		pending: {
			text: 'The profile is being verified. Verification will take up to 24 hours',
			variant: 'info'
		},
		visible: {
			text: 'Profile successfully verified!',
			variant: 'success'
		},
		disabled: {
			text: 'Your profile is disabled from indexing',
			variant: 'info'
		}
	}

	return(
		<Alert variant={statusConfig[indexStatusValue].variant} className="mt-4">
			<p className="font-family-open-sans mb-0">
				{statusConfig[indexStatusValue].text}
			</p>
		</Alert>
	)
}

export default ProfileStatus;