import React from "react";
import './Item.scss';
import ItemFooter from './ItemFooter';
import useItem from '../../../Hooks/useItem';
import {Col, Row} from 'react-bootstrap';
import Share from '../../Parts/Misc/Share';
import MoreOptions from '../../Parts/Misc/MoreOptions';
import {Link} from "react-router-dom";
import ObserverButton from "../Parts/ObserverButton";
import placeholderImage from '../../../assets/images/placeholder.jpg'
const Item = ({item, children, handleClick, setHitsData, className = 'float-end position-absolute end-0'}) => {

	const {itemLink} = useItem(item);

	return (
		<div className={`app-browse-loop-item ${item.isPremium ? 'is-premium' : 'is-normal'}`}>
			<div className={'thumbnail'}>
				{item.avatar ?
					<img src={item.avatar} alt={item.name}/>
					:
					<img src={placeholderImage} />
				}
				<div className={'video-preview cursor-pointer'} onClick={() => handleClick(item)}></div>
				<Row className={'top-icons'}>
					<Col xs={8} className={'float-start d-flex align-items-start'}>
						<ObserverButton size={24} isText={false} uid={item.uuid}/>
						<Share size={24} item={item}/>
					</Col>
					<Col className={className}>
						<MoreOptions item={item} options={['Share', 'Observe', 'ShowSimilar', 'NoMore', 'Report', 'Hide', 'Close']}/>
					</Col>
				</Row>
				<div className={'title text-limit'}>
					<Link to={itemLink()}>
						{item.name}
					</Link>
				</div>
			</div>
			<ItemFooter item={item}/>
			{children}
		</div>
	);

};

export default Item;
