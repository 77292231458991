import React from "react";
import {CiSquareChevRight} from "react-icons/ci";

const BecomeSeller = () => {
	return(
		<div className='bg-primary d-flex align-items-center pt-3 pb-3 ps-md-5 ps-3 pe-3 rounded-4 become-seller'>
			<p className="text-white mb-0 font-xxxl" style={{lineHeight: 1.1}}>
				Have any skills?
			</p>
			<a rel="noreferrer" href={"https://site.jobmultiverse.com/seller"} target={'_blank'} className='ms-3 font-xxl d-flex align-items-center pt-3 pb-3 pe-3 ps-4 rounded-4'>
				<span className="me-2 become-seller-text">
					Become a Seller
				</span>
				<span>
					<CiSquareChevRight size={32} />
				</span>
			</a>
		</div>
	)
}

export default BecomeSeller;