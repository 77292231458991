import React, {useEffect, useState} from "react";
import {Volume2, VolumeX} from 'react-feather';
import ProgressBar from './ProgressBar';
import {ReactComponent as PlayIcon} from '../../../../assets/svg/video/play.svg';

const Controls = ({forwardRef, autoplay, duration, loading, muted = false}) => {

	const BtnPlay = () => {

		const [playing, setPlaying] = useState(autoplay);

		useEffect(() => {

			if (autoplay === true) {

				forwardRef.current.play().catch(()=>{

				})

				setPlaying(true);

			}

			else {
				setPlaying(false);
			}
		}, [autoplay]);

		const handleClick = () => {

			if(forwardRef.current.paused === true) {

				forwardRef.current.play().catch(()=>{

				})

				setPlaying(true);

			}
			else {

				forwardRef.current.pause();

				setPlaying(false);

			}

		};

		return (
			<div onClick={handleClick} className={'controls-moderation large position-absolute top-0 w-100 h-100 d-flex justify-content-center align-items-center position-relative'}>
				<div className={`circle pulse ${(playing || loading ? '' : 'active')}`}>
					<button className={`bg-primary btn play-icon ${(playing || loading ? '' : 'active')}`}>
						<PlayIcon />
					</button>
				</div>

			</div>
		);

	};

	const BtnMute = () => {

		const [mute, setMute] = useState(localStorage.getItem('video-muted') === "true" ? true : false);

		if(muted) {

			setMute(true);

		}

		useEffect(() => {

			if (mute) {

				forwardRef.current.muted = true;

			}

			else {

				forwardRef.current.muted = false;

			}
		}, [mute]);

		const handleClick = () => {

			if(forwardRef.current.muted === true) {

				forwardRef.current.muted = false;

				localStorage.setItem('video-muted', false)

				setMute(false)
			}
			else {

				forwardRef.current.muted = true;

				localStorage.setItem('video-muted', true)

				setMute(true);
			}

		};

		return (
			<button type="button" onClick={handleClick}>
				{mute ? <VolumeX size={20}/> : <Volume2 size={20}/>}
			</button>
		);

	};

	const BtnSpeedUp = () => {
		const[currentSpeed, setCurrentSpeed] = useState(1)

		useEffect(() => {
			forwardRef.current.playbackRate = currentSpeed
		}, [currentSpeed])
		if(!forwardRef.current ){
			return <></>
		}
		const handleClick = () => {
			currentSpeed === 1 ? setCurrentSpeed(1.75) : setCurrentSpeed(1)
		}
		return(
			<button onClick={handleClick} className="btn btn-primary btn-speed-up">
				{currentSpeed === 1 ? '1x' : '1.75x'}
			</button>
		)
	}

	return (
		<>
			<BtnPlay />
			<div className={'video-controls'}>
				<div className={'center'}>
					<BtnMute/>
					<BtnSpeedUp />
				</div>
				<ProgressBar duration={duration} forwardRef={forwardRef}/>
			</div>
		</>
	);

};

export default Controls;
