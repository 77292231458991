import React, {useState} from 'react';
import {Rings} from 'react-loader-spinner';
import {useTranslation} from 'react-i18next';
import Notification from '../../../utils/NotificationUtils';
import useApi from "../../../Hooks/useApi";
import useError from "../../../Hooks/ReduxHooks/useError";

const ProfileReset = () => {
	const {t} = useTranslation();
	const [loading, setLoading] = useState(false);
	const [confirm, setConfirm] = useState(false);
	const {request} = useApi();
	const {handleSetIndexStatusValue} = useError();
	const resetProfile = () => {
		if(confirm) {
			setLoading(true);
			request(({
				url: '/profile',
				method: 'post',
				body: {
					action: "reset_hidden_profiles"
				},
				onSuccess: () => {
					Notification.success(t('profile.profile.tabs.settingsTab.profileReset.notification.text'), t('profile.profile.tabs.settingsTab.profileReset.notification.save'));
					setConfirm(false);
					handleSetIndexStatusValue('pending');
				}
			})).finally(() => setLoading(false));

		}
		else {
			setConfirm(true);
		}
	};

	return (
		<>
			<h3 className={'font-xxxl'}>{t('profile.profile.tabs.settingsTab.profileReset.title')}</h3>
			<button className="mt-3 reset-profile-button btn-has-loader d-flex btn btn-primary text-uppercase" onClick={resetProfile}>
				{loading ?
					<Rings height="28" width="28" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/> : (confirm ? t('profile.profile.tabs.settingsTab.profileReset.confirm') : t('profile.profile.tabs.settingsTab.profileReset.reset'))}
			</button>
		</>
	);
};

export default ProfileReset;