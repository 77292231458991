import React from "react";
import {useSortBy} from 'react-instantsearch';
import {Form} from 'react-bootstrap';

const SortBy = (props) => {
	const { currentRefinement, options, refine } = useSortBy(props);

	return (
		<Form.Select size="sm" onChange={(event) => refine(event.target.value)} value={currentRefinement}>
			{options.map((option) => (
				<option key={option.value} value={option.value}>
					{option.label}
				</option>
			))}
		</Form.Select>
	);
}

export default SortBy;