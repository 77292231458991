export default {
	errors: {
		422: "error422",
		503: "error503",
		401: "error401",
		403: "error403",
		500: "error500",
		404: "error404",
		400: "error400",
	}
};