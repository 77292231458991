import AuthHeader from "../Parts/AuthHeader";
import LoginForm from "./LoginForm";
import AuthFooter from "../Parts/AuthFooter";

const Login = ({setAuthType}) => {

	const buttonHandleClick = () => {
		setAuthType('register')
	}

	return(
		<div>
			<AuthHeader title="Sign in" subtitle="Don’t have an account?" buttonText="Join" buttonHandleClick={buttonHandleClick} />
			<LoginForm setAuthType={setAuthType} />
			<AuthFooter />
		</div>
	)
}

export default Login;