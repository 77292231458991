import galleryIcon from '../../../assets/svg/premium/gallery.svg';
import tagsIcon from '../../../assets/svg/premium/tags.svg';
import thumbnailIcon from '../../../assets/svg/premium/thumbnail.svg';
import chatIcon from '../../../assets/svg/premium/chat.svg';
import categoriesIcon from '../../../assets/svg/premium/categories.svg';
import linkIcon from '../../../assets/svg/premium/link.svg';

const PremiumFeatures = () => {

	const featuresConfiguration = [
		{
			icon: galleryIcon,
			text: 'Unlock more media files to your portfolio max. 8 files'
		},
		{
			icon: tagsIcon,
			text: 'Pick more tags to your profile'
		},
		{
			icon: thumbnailIcon,
			text: 'Premium badge on profile thumbnail'
		},
		{
			icon: chatIcon,
			text: 'Unlimited Chat history'
		},
		{
			icon: categoriesIcon,
			text: 'Choose more categories for your profile'
		},
		{
			icon: linkIcon,
			text: 'Links to your portfolio'
		},
	]

	const FeaturesLoop = () => {
		return(
			<ul className="list-unstyled features-loop mt-4">
				{featuresConfiguration.map((featureItem, index) => {
					return(
						<li className="d-flex align-items-center mb-2" key={index}>
							<img src={featureItem.icon} alt={featureItem.text} />
							<p className="mb-0 ms-2 font-family-open-sans">{featureItem.text}</p>
						</li>
					)
				})}
			</ul>
		)
	}

	return(
		<div className="premium-features">
			<h1 className="font-weight-md">
                Enjoy Premium Features
			</h1>
			<p className="font-xl font-family-open-sans">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent congue sem ut malesuada pellentesque. Ut tincidunt fringilla posuere. Duis quam mauris, commodo nec porta dictum, sodales id velit. Suspendisse nec lacinia lectus. Morbi pharetra tempor sem, ac consectetur risus pellentesque vitae.
			</p>
			<div className="d-flex align-items-center">
				<h3 className="font-xl font-weight-md text-nowrap mb-0 me-4">WHAT’S INCLUDED</h3>
				<span className="line w-100 bg-primary"></span>
			</div>
			<FeaturesLoop />
		</div>
	)
}

export default PremiumFeatures;