export const hideEmail = (email) => {
	return email.replace(/(\w{3})[\w.-]+@([\w.]+\w)/, "$1***@$2")
}

export const firstLetterUpper = (str) => {
	return `${str.slice(0,1).toUpperCase()}${str.slice(1)}`
}
export const Slugify = str =>
	str
		.toLowerCase()
		.trim()
		.replace(/[^\w\s-]/g, '')
		.replace(/[\s_-]+/g, '-')
		.replace(/^-+|-+$/g, '');

export const isValidUrl = url => {

	const regex = /^(https?:\/\/)?([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})(\/\S*)?$/;

	return regex.test(url);

}