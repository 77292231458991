import {createSlice} from "@reduxjs/toolkit";
import React from "react";

const initialState = {
	popupContent: null,
};

const popupSlice = createSlice({
	name: 'popup',
	initialState,
	reducers: {
		showPopup(state, action){
			const {component, props} = action.payload;
			document.body.style.overflow = 'hidden';
			const ComponentToRender = component;
			state.popupContent = <div className="app-popup"><ComponentToRender {...props} /></div>;
		},
		hidePopup(state){
			document.body.style.overflow = 'visible';
			state.popupContent = null;
		}
	}
})

export const { showPopup, hidePopup } = popupSlice.actions;

export default popupSlice.reducer;