import SkeletonMessagesContent from "../Skeleton/SkeletonMessagesContent";
import {Link} from "react-router-dom";

const MoreHistoryPopup = () => {
	return(
		<div className={'position-relative'}>
			{/*<Link to={'/premium'} className={'btn btn-primary position-absolute start-50 top-50 translate-middle font-xxl z-3'}>*/}
			{/*	Get Premium*/}
			{/*</Link>*/}
			{/*<SkeletonMessagesContent />*/}
		</div>
	)
}

export default MoreHistoryPopup;