import React from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import BottomBar from '../../Layout/Footer/BottomBar';
import useUser from '../../../Hooks/ReduxHooks/useUser';

const Footer = () => {

	const {isMobile} = useUser();

	const {t} = useTranslation();
	const currentYear = new Date().getFullYear();
	const LinkFooter = ({href, text}) => {
		return (
			<li>
				<Link to={href}>{text}</Link>
			</li>
		);
	};

	return (
		<>
			{isMobile && <BottomBar/>}
			<div className={'page-footer'}>
				<div className="page-footer-menu">
					<ul className="d-flex">
						<LinkFooter href={"/about"} text={t('parts.authFooter.about')}/>
						<LinkFooter href={"/availability"} text={t('parts.authFooter.availability')}/>
						<LinkFooter href={"/help"} text={t('parts.authFooter.help')}/>
						<LinkFooter href={"/faq"} text={t('parts.authFooter.faq')}/>
						<LinkFooter href={"/privacy"} text={t('parts.authFooter.privacy')}/>
					</ul>
				</div>
				<div className="page-footer-copy">
					jobmultiverse.com &copy; {currentYear}
				</div>
			</div>
		</>
	)

};

export default Footer;