import timestampToDate from '../../../utils/timestampToDate';

const DrawDateDivider = ({ messages, messageIndex }) => {
	let value = '';

	const drawDateDivider = (index) => {
		if (messages[index + 1]) {
			const currentDate = new Date(Number(messages[index].time)).toDateString();
			const nextDate = new Date(Number(messages[index + 1].time)).toDateString();

			if (currentDate !== nextDate) {
				value = timestampToDate(
					Number(messages[index + 1].time),
					localStorage.getItem('i18nextLng'),
					'date'
				);
				return true;
			}
		}
		return false;
	};

	return (
		<>
			{drawDateDivider(messageIndex) && (
				<div className="w-100 d-flex justify-content-center border-bottom font-xs draw-date-divider">
					{value}
				</div>
			)}
		</>
	);
};

export default DrawDateDivider;
