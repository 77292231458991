import React from "react";
import Skeleton from './Skeleton';

const SkeletonLoop = ({count, loading}) => {

	if(!loading) return;

	return (
		<div className="app-browse-loop">
			{[...Array(count)].map((x, i) => {
				return <Skeleton key={i}/>;
			})}
		</div>
	);

};

export default SkeletonLoop;