import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	progress: 0,
	isUploading: false,
	controller: new AbortController()
};

const backgroundSlice = createSlice({
	name: 'background',
	initialState,
	reducers: {
		updateProgress: (state, action) => {
			state.progress = action.payload;
			state.isUploading = action.payload > 0 && action.payload < 100;
		},
		resetController: (state) => {
			state.controller.abort();
			state.controller = new AbortController();
		}
	}
});

export const { updateProgress, resetController } = backgroundSlice.actions;
export default backgroundSlice.reducer;
