import React from "react";
import {Share2} from 'react-feather';
import ShareWindow from './ShareWindow';
import useUser from "../../../Hooks/ReduxHooks/useUser";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";

const Share = ({ item, size = 24 }) => {
	const { openPopup } = usePopup();
	const {isIOS} = useUser();
	return (
		<button title="Udostępnij" className={'share icon-button'} onTouchStart={() => isIOS ? openPopup(ShareWindow, { item }) : undefined} onClick={() => !isIOS ? openPopup(ShareWindow, { item }) : undefined}>
			<div className={'share-icon'}>
				<Share2 size={size}/>
			</div>
		</button>
	);
};

export default Share;
