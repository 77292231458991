import {Eye} from 'react-feather';
import {Link, useNavigate} from 'react-router-dom';
import React from 'react';
import FloatingButtonsTooltip from '../../Parts/FloatingButtons/FloatingButtonsTooltip';
import {useTranslation} from 'react-i18next';

const ProfileButtons = () => {
	const navigate = useNavigate()
	const { t } = useTranslation();

	return(

		<div className="popup-wrapper flex-column align-items-end">
			<FloatingButtonsTooltip id="view-profile" tooltipValue={t('parts.floatingButtonsTooltip.tooltipValues.profilePreview')} clickFunc={() => navigate('/profile')}>
				<Link to="/account" className="floating-button">
					<Eye/>
				</Link>
			</FloatingButtonsTooltip>
		</div>
	)
}

export default ProfileButtons