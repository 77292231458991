import {useTranslation} from 'react-i18next';
import {Rings} from 'react-loader-spinner';
import React from 'react';
import {ReactComponent as CameraPlus} from '../../../../assets/svg/video/camera-plus.svg';
const ThumbnailUploadFormButton = ({loading, abort, videoRef, setLoading, isThumbnailActive}) => {

	const {t} = useTranslation();

	const handleClick = () => {

		if(loading) {

			abort();

			setLoading(false)

		}

		else {

			videoRef.current.click();

		}

	};

	if(loading.state === 'pending' || isThumbnailActive) {

		return <></>;

	}

	return (
		<>
			{loading && loading.state !== 'pending' &&
				<>
					<Rings height="28" width="28" color="#ffffff" radius="6" ariaLabel="rings-loading"/>
				</>
			}
			{!loading && <CameraPlus onClick={handleClick} className={'cursor-pointer'} />}
		</>
	);

};

export default ThumbnailUploadFormButton