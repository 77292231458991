import React from "react";
import FreeRepeater from '../Common/FreeRepeater';
import {useTranslation} from 'react-i18next';

const TabCharacter = () => {

	const {t} = useTranslation();

	return (
		<>
			<FreeRepeater title={t('profile.profile.tabs.profileTab.index.sections.character.title')} buttonTitle={t('profile.profile.tabs.profileTab.index.sections.character.buttonTitle')} section={'character'}/>
		</>
	);

};
export default TabCharacter;
