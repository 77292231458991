import {useFormikContext} from 'formik';
import {useRef, useState} from 'react';
import {Col, Row} from 'react-bootstrap';
import ProfileError from "../Parts/ProfileError";

const ApplicationWaysInputDisplay = ({way, forwardRef, disabled}) => {

	const {setFieldValue, handleBlur, errors} = useFormikContext();

	const handleChangeInput = (e) => {

		if(way.name === 'is_visible'){
			setFieldValue(`is_visible`, e.target.value)
		}

		else{
			setFieldValue(`contact_methods.${way.name}`, e.target.value.length === 0 ? ' ' : e.target.value)
		}

	}

	return <>
		<input className={`input ${(errors.contact_methods?.[way.name]) ? 'border border-danger' : ''}`} defaultValue={way.initialValue} ref={forwardRef} disabled={disabled} name={way.name} onChange={handleChangeInput} onBlur={handleBlur}/>
		<label className="label-input">{way.title}</label>
	</>

}

const ApplicationWaysInput = ({way}) => {
	const {handleBlur, setFieldValue, errors} = useFormikContext();

	const InputRef = useRef();

	const [disabled, setDisabled] = useState(way.inputDisabled);
	const handleChangeCheckbox = (e, name) => {

		if(way.hasInput) {

			if(e.target.checked === false) {
				setFieldValue(`contact_methods.${way.name}`, null)
				setDisabled(true);
				InputRef.current.value = 'I don\'t want to provide...';

			}
			else {

				setDisabled(false);
				InputRef.current.value = way.defaultValue;
				InputRef.current.focus();

				setFieldValue(`contact_methods.${way.name}`, InputRef.current.value.length === 0 ? ' ' : InputRef.current.value)

			}

		}
		else {

			if(way.name === 'is_visible'){

				setFieldValue(`${way.name}`, e.target.checked)

			}

			else{

				setFieldValue(`contact_methods.${way.name}`, e.target.checked)

			}

		}

	};

	return (
		<Col sm={12} md={6} className="mb-md-0 mb-4">
			<div className="application-type-wrapper mb-md-4 mb-0">
				{!way.hasInput ? <div className={`input text-uppercase ${(errors.contact_methods?.[way.name]) ? 'border border-danger' : ''}`}>{way.title}</div> : <ApplicationWaysInputDisplay way={way} disabled={disabled} forwardRef={InputRef}/>}
				<div className="position-absolute toggle-wrapper d-flex align-items-center">
					<label className="toggle d-flex justify-content-between align-items-center">
						<input className="toggle-checkbox" type="checkbox" name={way.hasInput ? way.name + '_checkbox' : way.name} onChange={handleChangeCheckbox} onBlur={handleBlur} defaultChecked={way.defaultChecked}/>
						<div className="toggle-switch"></div>
					</label>
					<div className="ms-2">
						{way.desc}
					</div>
					<ProfileError profileError={errors.contact_methods?.[way.name]} additionalStyles={'ms-1'} />
				</div>
			</div>
		</Col>
	);

};

const CommunicationWays = ({waysConfiguration}) => {
	if(!waysConfiguration){
		return <></>
	}

	return (
		<Row id="commucation-ways-guide">
			{waysConfiguration.map((way, index) => <ApplicationWaysInput key={index} way={way}/>)}
		</Row>
	)
}

export default CommunicationWays;