import React from "react";
import { useFormikContext } from 'formik';
import Taxonomy from "../../../Common/Taxonomy";

const TabCategory = () => {
	const { values, setFieldValue } = useFormikContext();

	return (
		<div className="mb-5">
			<Taxonomy
				categories={values.categories}
				tags={values.tags}
				onChangeCategories={item => setFieldValue('categories', values.categories.concat(item.id))}
				onRemoveCategories={item => setFieldValue('categories', values.categories.filter((i) => i !== item.id))}
				onChangeTags={item => setFieldValue('tags', values.tags.concat(item.name))}
				onRemoveTags={item => setFieldValue('tags', values.tags.filter((i) => i !== item.name))}
			/>
		</div>
	);
};

export default TabCategory;
