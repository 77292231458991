import React, {useState} from "react";
import {Formik} from "formik";
import {Button, Form} from "react-bootstrap";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import VerificationInput from "react-verification-input";
import {confirmUserAttribute, sendUserAttributeVerificationCode, updateUserAttribute} from "aws-amplify/auth";
import Notification from "../../../../utils/NotificationUtils";
import {Rings} from "react-loader-spinner";
import ProfileError from "../../Edit/Parts/ProfileError";
import {TooltipHelper} from "../../../Parts/Tooltips";
import { FaLock } from "react-icons/fa";
import useCountdown from "../../../../Hooks/useCountdown";
import {useNavigate} from "react-router";
const ChangeEmail = () => {

	const { t } = useTranslation();

	const {user, handleLogout} = useUser();

	const { startCountdown, timeLeft, isTimeoutRemaining } = useCountdown();

	const isServiceActive = user.loginType === 'User'
	const navigate = useNavigate();
	const [changeEmailVisible, setChangeEmailVisible] = useState(false)
	const [codeValue, setCodeValue] = useState();
	const [isCodeComplete, setIsCodeComplete] = useState()
	const [isCodeValid, setIsCodeValid] = useState(true)
	const [loading, setLoading] = useState();
	const [dataInvalid, setDataInvalid] = useState(false)
	const [isCodeVisible, setIsCodeVisible] = useState(false)
	const [newEmail, setNewEmail] = useState('')

	const initialData = {
		newEmail: '',
		emailConfirmation: '',
	}

	const handleSubmit = async (values) => {
		setLoading(true)

		try{
			await updateUserAttribute({
				userAttribute: {
					attributeKey: 'email',
					value: values.newEmail
				}
			});
			setNewEmail(values.newEmail)
			setIsCodeVisible(true)
			setDataInvalid(false)
			setLoading(false)
		}

		catch (error){
			setDataInvalid(true)
			setLoading(false)
		}
	};

	const handleConfirmUserAttribute = async () => {
		if(!codeValue){
			return
		}
		try{
			await confirmUserAttribute({
				userAttributeKey: 'email',
				confirmationCode: codeValue
			});
			Notification.success(t('profile.profile.tabs.settingsTab.changeEmailForm.notification.text'), t('profile.profile.tabs.settingsTab.changeEmailForm.notification.save'));
			setIsCodeValid(true)
			setIsCodeVisible(false)
			await handleLogout();
			navigate('/?auth-popup=login')
		}
		catch(error){
			setIsCodeValid(false)
		}
	}

	const handleComplete = (code) => {
		setCodeValue(code)
		setIsCodeComplete(true)
	}

	const handleChange = () => {
		setIsCodeComplete(false)
		setIsCodeValid(true)
	}

	const resendCode = async (values) => {
		await handleSubmit(values)
		startCountdown(20);
	}

	const sendCode = async () => {
		try {
			await sendUserAttributeVerificationCode({ userAttributeKey: 'email' });
			setIsCodeVisible(true);
			startCountdown(20);
		} catch (err) {
			console.log(err);
		}
	};

	return(
		<div className='mb-4'>
			{isCodeVisible ?
				<>
					{!isCodeValid && <p className="error-message text-left">The code is incorrect</p>}
					<div>
						<p>
							{isTimeoutRemaining ?
								<>
									Didnt you get the code? <span onClick={() => sendCode()} className="cursor-pointer text-decoration-underline">Resend</span>
								</>
								:
								<>
									Resend code again in {timeLeft}
								</>
							}
						</p>
					</div>
					<VerificationInput autoFocus onChange={handleChange} onComplete={handleComplete} validChars="0-9" inputProps={{ inputMode: "numeric" }} placeholder="" classNames={{character: "character", container: "container", characterSelected: "character-selected",}} />
					<button disabled={!isCodeComplete} className="btn btn-primary mt-4 w-100" onClick={handleConfirmUserAttribute}>
						Confirm
					</button>

				</>
				:
				<>
					{dataInvalid && <p className="error-message text-left">{t('profile.profile.tabs.settingsTab.changeEmailForm.validations.dataInvalid')}</p>}
					<Formik initialValues={initialData} onSubmit={(values) => handleSubmit(values)} validationSchema={Yup.object().shape({
						newEmail: Yup.string().required(t('profile.profile.tabs.settingsTab.changeEmailForm.validations.email.required')).email(t('profile.profile.tabs.settingsTab.changeEmailForm.validations.email.email')),
						emailConfirmation: Yup.string().oneOf([Yup.ref('newEmail'), null], t('profile.profile.tabs.settingsTab.changeEmailForm.validations.emailConfirmation.oneOf')).required(t('profile.profile.tabs.settingsTab.changeEmailForm.validations.emailConfirmation.required')),
					})}>
						{({handleSubmit, values, handleChange, handleBlur, errors, touched}) => (
							<Form onSubmit={handleSubmit}>
								<Form.Group className={'mb-4'}>
									<div className="position-relative">
										<Form.Label className={'bg-transparent'}>E-mail</Form.Label>
										<Form.Control disabled={!isServiceActive} placeholder={'Your current email'} name="email" value={user.email} />
										{isServiceActive ?
											<>
												{!changeEmailVisible &&
													<button type='button' onClick={() => setChangeEmailVisible(prevState => !prevState)} className={"position-absolute top-50 end-0 bg-transparent translate-middle-y me-3  border-0 text-primary text-decoration-underline font-md"}>
														change
													</button>
												}
											</>
											:
											<>
												<TooltipHelper className={'position-absolute top-50 end-0 translate-middle-y me-3'} icon={FaLock}>
													<p className={'mb-0'}>
														Change of email blocked
													</p>
												</TooltipHelper>
											</>
										}
									</div>
								</Form.Group>
								{changeEmailVisible &&
									<>
										<Form.Group className={'mb-4'}>
											<div className="position-relative">
												<Form.Label>New E-mail</Form.Label>
												<Form.Control placeholder={'Your new e-mail'} className={errors.newEmail ? 'border border-danger' : ''} name="newEmail" value={values.newEmail} onChange={handleChange} onBlur={handleBlur} />
												<ProfileError profileError={errors.newEmail} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-3'} />
											</div>
										</Form.Group>
										<Form.Group className={'mb-4'}>
											<div className="position-relative">
												<Form.Label>New E-email Confirmation</Form.Label>
												<Form.Control placeholder={'Your new e-email confirmation'} className={errors.emailConfirmation ? 'border border-danger' : ''} name="emailConfirmation" value={values.emailConfirmation} onChange={handleChange} onBlur={handleBlur} />
												<ProfileError profileError={errors.emailConfirmation} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-3'} />
											</div>
										</Form.Group>
										<Button className={'btn-has-loader d-flex w-100'} type={'submit'} disabled={loading || (errors.email || errors.emailConfirmation)}>
											{loading &&
												<Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/>}
											<span>Change Email</span>
										</Button>
									</>
								}
							</Form>
						)}
					</Formik>
				</>
			}
		</div>
	)
}

export default ChangeEmail;