import React from "react";
import {useFormikContext} from 'formik';
import useApi from "../../../../../Hooks/useApi";
import useUser from "../../../../../Hooks/ReduxHooks/useUser";
import DisplayRepeater from "../../../Common/DisplayRepeater";

const FreeRepeater = ({ title, section }) => {

	const { values, setFieldValue } = useFormikContext();
	const { lang } = useUser();
	const { request } = useApi();

	const defaultFetchHints = async (query) => {
		const response = await request({
			method: "get",
			url: `/profile/hints?section=${section}${query.length === 0 ? '' : `&query=${query}`}&lang=${lang}`
		});

		if (response && response.length !== 0) {
			return response.map(item => ({
				id: item.hintSection,
				name: item.hint
			}));
		}

		return [];
	};

	const defaultAssignHint = (item, finished) => {
		setFieldValue(section, values[section].concat(item.name));
		finished();
	};

	const fetchHints = defaultFetchHints;
	const assignHint = defaultAssignHint;

	const parseItems = () => {
		let items = [];
		if (Array.isArray(values[section]) && values[section].length > 0) {
			values[section].forEach((name, id) => {
				items.push({
					id: id,
					name: typeof name === 'object' ? name.language : name,
					level: name.level || ""
				});
			});
		}
		return items;
	};

	const handleRemove = (item, finished) => {
		setFieldValue(section, values[section].filter((name) => (typeof name === 'object' ? name.language : name) !== item.name));
		finished();
	};

	return (
		<>
			<DisplayRepeater assignHint={assignHint} fetchHints={fetchHints} handleRemove={handleRemove} isBlocked={false} items={parseItems()} title={title} section={section} />
		</>
	);
};

export default FreeRepeater;
