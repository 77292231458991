import useMessage from "../../../Hooks/ReduxHooks/useMessage";
import React, {useEffect, useRef} from "react";
import SkeletonUserList from "../Skeleton/SkeletonUserList";
import Item from "./Item";
import useQueryString from "../../../Hooks/useQueryString";

const UsersList = () => {
	const {conversationsData, loadings, getConversations, chatID} = useMessage();

	const userListContainerRef = useRef(null);

	useEffect(() => {
		const handleScroll = () => {
			if (userListContainerRef.current) {
				const { scrollTop, scrollHeight, clientHeight } = userListContainerRef.current;
				const isBottom = scrollHeight - scrollTop <= clientHeight * 1.05;
				if (isBottom && !loadings.conversationsPagination && conversationsData.last) {
					getConversations(conversationsData.last)
				}
			}
		};

		const container = userListContainerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [conversationsData.last])

	if(loadings.conversationsData){
		return <SkeletonUserList />
	}

	return(
		<div className='user-list' ref={userListContainerRef}>
			{conversationsData.items.map((conversation, index) => <React.Fragment key={index}><Item conversation={conversation} /></React.Fragment>)}
			{loadings.conversationsPagination && <SkeletonUserList />}
		</div>
	)
}

export default UsersList;