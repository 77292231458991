import React, { useEffect, useState } from "react";
import { ChevronLeft, ChevronRight } from 'react-feather';
import SwipeContainer from "../../../Parts/SwipeContainer";

const Lightbox = ({ images, initialIndex = 0, opened, onClose }) => {
	const [currentIndex, setCurrentIndex] = useState(initialIndex);

	useEffect(() => {
		if (opened) {
			document.body.style.overflow = 'hidden';
		} else {
			document.body.style.overflow = 'visible';
		}
	}, [opened]);

	useEffect(() => {
		setCurrentIndex(initialIndex);
	}, [initialIndex]);

	const handlePrevImage = (e) => {
		e.stopPropagation();
		let newIndex = currentIndex <= 0 ? images.length - 1 : currentIndex - 1;
		setCurrentIndex(newIndex);
	};

	const handleNextImage = (e) => {
		e.stopPropagation();
		let newIndex = currentIndex >= images.length - 1 ? 0 : currentIndex + 1;
		setCurrentIndex(newIndex);
	};

	return (
		opened && (
			<SwipeContainer onSwipeRight={handlePrevImage} onSwipeLeft={handleNextImage}>
				<div id="lightbox" onClick={onClose}>
					<button onClick={handlePrevImage}>
						<ChevronLeft size={80} />
					</button>
					<img src={images[currentIndex].imageLink} alt="lightbox" />
					<button onClick={handleNextImage}>
						<ChevronRight size={80} />
					</button>
				</div>
			</SwipeContainer>
		)
	);
};

export default Lightbox;
