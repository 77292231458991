import React from "react";

const HintsSkeleton = () => {
	return(
		<div>
			<div className={'skeleton-hints d-flex mt-2'}>
				<div className={'skeleton skeleton-hint w-25'}></div>
				<div className={'skeleton skeleton-hint w-50 ms-3 me-3'}></div>
				<div className={'skeleton skeleton-hint w-25'}></div>
			</div>
		</div>

	)
}

export default HintsSkeleton;