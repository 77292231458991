import React, {useEffect, useState} from "react";
import {Image as ImageIcon, Plus, XCircle, ZoomIn} from 'react-feather';
import useApi from "../../../../../Hooks/useApi";
import useError from "../../../../../Hooks/ReduxHooks/useError";
import ProfileError from "../../Parts/ProfileError";
import {Link} from "react-router-dom";
import Thumbnail from "../Thumbnail/Thumbnail";
import Notification from "../../../../../utils/NotificationUtils";
import ImageCrop from "../Helpers/ImageCrop";
import usePopup from "../../../../../Hooks/ReduxHooks/usePopup";
import {useTranslation} from "react-i18next";
import axios from "axios";
import s3upload from "../Helpers/s3upload";
import {Rings} from "react-loader-spinner";
import useUser from "../../../../../Hooks/ReduxHooks/useUser";
import {useWebSocket} from "../../../../../Hooks/ReduxHooks/useWebSocket";

const Wrapper = ({children, image = false, src, loading, isError}) => {
	return (
		<div className={`gallery-item ${(image) ? 'has-image' : 'no-image'} ${(loading) ? 'loading' : 'loaded'}`} id={'gallery-item-guide'}>
			<div className={`${isError ? 'border border-danger' : ''} gallery-item-wrapper`} style={{backgroundImage: `url("${src}")`}}>
				{children}
			</div>
		</div>
	);
};

const MoreMedia = () => {
	return(
		<Wrapper>
			<div className={'w-100 h-100 rounded-4 border border-primary d-flex flex-column justify-content-between'}>
				<p className='mt-4 ms-4 text-primary font-xxxl lh-1 font-weight-md'>
					Want to <br /> share <br/> more <br/> media?
				</p>
				<Link to={'/premium'} className={'text-uppercase btn btn-primary d-flex justify-content-center ms-2 me-2 mb-2'}>
					Get premium
				</Link>
			</div>
		</Wrapper>
	)
}

const Image = ({image, setImages}) => {

	const { request } = useApi();

	const [loading, setLoading] = useState(false);

	const { handleSetIndexStatusValue, indexStatusValue } = useError();

	const [imageErrors, setImageErrors] = useState(undefined);

	const {galleryError} = useError()

	useEffect(() => {
		galleryError.map(error => {
			if(error.id === image.imageId){
				setImageErrors(error.errors)
			}
		})
	}, [indexStatusValue])

	const handleRemoveImage = (item) => {

		setLoading(true);

		request({
			method: 'delete',
			url: `/profile/gallery/${image.imageId}`,
			onSuccess: () => {
				setImages(prevState => (
					prevState.filter(i => i.imageId !== image.imageId)
				));
				setImageErrors([])
				handleSetIndexStatusValue('pending');
				setLoading(false);
			}
		});

	};

	return(
		<>
			<Wrapper loading={loading} image={true} src={image.imageLink} isError={imageErrors && indexStatusValue === 'rejected'}/>
			<ProfileError errorValue={imageErrors} additionalStyles="position-absolute bottom-0 end-0 mb-2 me-2" type="gallery"/>
			<button className={`circle-close mt-2 me-2`} disabled={loading} onClick={handleRemoveImage}>
				{loading ?
					<Rings height="26" width="26" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/> :
					<XCircle size={24}/>}
			</button>
		</>
	)
}

const Placeholder = ({setImages}) => {

	const [isEntered, setIsEntered] = useState(false);

	const [loading, setLoading] = useState(false);

	const [previewUrl, setPreviewUrl] = useState(null);

	const [selectedFile, setSelectedFile] = useState(null);

	const { t } = useTranslation();

	const { openPopup } = usePopup();

	const { request } = useApi();

	const { user } = useUser();

	const { register, unregister } = useWebSocket();

	const { handleSetIndexStatusValue } = useError();

	useEffect(() => {
		if (!user) return;
		const handleGalleryUploadCompletedImage = (data) => {
			setImages(prevState => {
				if (prevState.some(img => img.imageId === data.imageId)) {
					return prevState;
				}
				handleSetIndexStatusValue('pending');
				return [{ imageLink: data.url, imageId: data.imageId }, ...prevState];
			});
			setLoading(false);
		}

		register('gallery_upload_completed_image', handleGalleryUploadCompletedImage);

		return () => {
			unregister('gallery_upload_completed_image', handleGalleryUploadCompletedImage);
		};
	}, [user]);

	const handleChangeImage = async (e) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (!file) return;

		const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp)$/i;
		const fileSizeKiloBytes = file.size / 1024;
		const MAX_FILE_SIZE = 4096;

		if (fileSizeKiloBytes > MAX_FILE_SIZE) {
			Notification.error(t('profile.edit.gallery.imageUploadForm.notification.text'), 'error');
			return;
		}

		if (!allowedExtensions.exec(file.name)) {
			Notification.error(t('profile.edit.gallery.imageUploadForm.notification.text2'), 'error');
			return;
		}

		const image = new window.Image();
		image.onload = function () {
			if (image.width < 600 || image.height < 600) {
				Notification.error('Minimum image size up to 600x600px.');
				return;
			}
			else {
				setSelectedFile(file);
				const src = URL.createObjectURL(file);
				openPopup(ImageCrop, { previewUrl: src, setPreviewUrl, setSelectedFile });
			}
		};
		image.src = URL.createObjectURL(file);
	};

	useEffect(() => {
		if (previewUrl && selectedFile) {
			setLoading(true);

			const newCancelToken = axios.CancelToken.source();

			request({
				method: 'get',
				url: `/profile/upload/url?upload_type=gallery&resource_type=${selectedFile.type}`,
				onSuccess: async data => {
					await s3upload(data.url, selectedFile, (e) => {
						setLoading(true);
					}, () => {
						setLoading(false);
					}, () => { }, newCancelToken);
				}
			});
		}
	}, [previewUrl]);

	return(
		<Wrapper>
			<div className={'gallery-placeholder'}>
				{loading &&
					<div className={'item-loader w-100 h-100 bg-transparent d-flex align-items-center justify-content-center flex-column'}>
						<Rings color={'#5100b9'} />
						Loading
					</div>
				}
				{!loading &&
					<>
						{isEntered ? <Plus size={46} /> : <ImageIcon size={46}/>}
						<input type="file" name="newImage" className="opacity-0 position-absolute w-100 h-100 cursor-pointer" data-max-file-size="3M" accept="image/jpeg, image/png, image/webp" onChange={handleChangeImage}/>
					</>
				}
			</div>
		</Wrapper>
	)
}

const GalleryItems = ({images, setImages}) => {

	const { user } = useUser();
	const maxSize = user.plan_id === 'free' ? 4 : 10;
	const numberOfPlaceholders = maxSize - images.length;

	return (
		<>
			<Thumbnail/>
			{images.map((image, index) => (
				<div className={'position-relative'} key={index}>
					<Image image={image} setImages={setImages} />
				</div>
			))}
			{[...Array(numberOfPlaceholders)].map((_, index) => (
				<React.Fragment key={index + images.length}>
					<Placeholder setImages={setImages} />
				</React.Fragment>
			))}
			<MoreMedia />
		</>
	);

};

export default GalleryItems;
