import React, {useEffect} from "react";
import {useWebSocket} from "../../Hooks/ReduxHooks/useWebSocket";
import useUser from "../../Hooks/ReduxHooks/useUser";
import useMessage from "../../Hooks/ReduxHooks/useMessage";
import UsersList from "./UsersList/UsersList";
import {Col, Container, Row} from "react-bootstrap";
import './Assets/messages.scss';
import './Assets/messages-media-query.css';
import Chat from "./Chat/Chat";
import useQueryString from "../../Hooks/useQueryString";
const Messages = () => {

	const {register, unregister, socket, isSocketActive} = useWebSocket();

	const {user, isMobile} = useUser();

	const {addQuery} = useQueryString();

	const searchParams = new URLSearchParams(location.search);
	const uidFromURL = searchParams.get('uid');

	const {
		handleSetChatID,
		getConversations,
		handleSetConversationsData,
		handleSetLoadings,
		handleSetConversationData,
		chatID,
		getConversation,
		getConversationHistory,
		handleSetConversationHistory,
		conversationHistory,
		handleSetIsNewMessage,
		conversationsData,
		loadings,
		handleSetUnreadCount
	} = useMessage();

	useEffect(() => {
		if(!user && !socket && !isSocketActive) return null;
		const handleConversations = (data) => {
			const sortedDataItems = data.data.items.sort((x, y) => {
				return y.lastMessage - x.lastMessage;
			})
			handleSetConversationsData({
				count: data.data.count + conversationsData.count,
				items: conversationsData.items.length === 0 ? data.data.items : [...conversationsData.items, ...sortedDataItems],
				last: data.data.last
			});
			handleSetLoadings(conversationsData.items.length === 0 ? 'conversationsData' : 'conversationsPagination', false)
		}

		const handleConversation = (data) => {
			handleSetConversationData(data.data);
			handleSetLoadings('conversationData', false)
			handleSetLoadings('conversationHistoryPagination', false);
		}

		const handleHistory = (data) => {
			handleSetConversationHistory({
				count: data.data.count + conversationHistory.count,
				items: conversationHistory?.items?.length === 0 ? data.data.items : [...data.data.items, ...conversationHistory.items],
				last: data.data.last
			});
			handleSetLoadings('conversationHistory', false)
			handleSetLoadings('conversationHistoryPagination', false);
		}

		const handleNewMessage = (data) => {
			if(data.data.senderId === chatID || data.data.senderId === user.id){
				handleSetConversationHistory({...conversationHistory, items: conversationHistory.items ? [...conversationHistory.items, data.data] : data.data});
				handleSetIsNewMessage(true);
			}
			handleSetLoadings('sendMessage', false)
		}

		const handleArchived = (data) => {
			const newConversationsData = conversationsData.items.filter(item => item.receiverId !== data.id);
			handleSetConversationsData({
				count: conversationsData.count - 1,
				items: newConversationsData,
				last: null
			})
			handleSetChatID('JOBOT2a1-f8ae-401c-9c7c-d8ec17488229');
		}

		register('conversations', handleConversations);
		register('conversation', handleConversation);
		register('history', handleHistory);
		register('newMessage', handleNewMessage);
		register('archived', handleArchived);

		return () => {
			unregister('conversations', handleConversations);
			unregister('conversation', handleConversation);
			unregister('history', handleHistory);
			unregister('newMessage', handleNewMessage);
			unregister('archived', handleArchived);
		};

	}, [user, socket, conversationHistory, conversationsData]);

	useEffect(() => {
		if(chatID && isSocketActive){
			getConversation()
			getConversationHistory();
		}
	}, [chatID, isSocketActive, user.id, location.pathname])

	useEffect(() => {
		if (isSocketActive && user.id && location.pathname === '/messages') {
			getConversations();
		}
	}, [isSocketActive, user.id, location.pathname])

	useEffect(() => {
		handleSetUnreadCount(0)
		if(uidFromURL === null && !isMobile && conversationsData.items[0]){
			handleSetChatID(conversationsData.items[0].receiverId)
		}
		else{
			handleSetChatID(uidFromURL)
		}
	}, []);

	useEffect(() => {
		if(chatID){
			addQuery('uid', chatID)
		}
	}, [chatID, uidFromURL])

	return(
		<section className="profile">
			<div className="messages">
				<Container className={'pt-5'}>
					{conversationsData.count === 0 && isSocketActive && !loadings.conversationsData ?
						<div role="alert" className="fade alert alert-info show mt-4"><p className="mb-0 font-family-open-sans">No messages</p></div>
						:
						<Row>
							<Col sm={12} lg={3}>
								<UsersList />
							</Col>
							<Col className="ms-0 ms-lg-4 messages-popup-wrapper">
								<Chat />
							</Col>
						</Row>
					}

				</Container>
			</div>
		</section>
	)
}

export default Messages;