import AuthHeader from "../Parts/AuthHeader";
import AuthFooter from "../Parts/AuthFooter";
import RegisterForm from "./RegisterForm";

const Register = ({setAuthType}) => {

	const buttonHandleClick = () => {
		setAuthType('login')
	}

	return(
		<div>
			<AuthHeader title="Create a new account" subtitle="Already have an account" buttonText="Sign in" buttonHandleClick={buttonHandleClick} />
			<RegisterForm setAuthType={setAuthType} />
			<AuthFooter />
		</div>
	)
}

export default Register;