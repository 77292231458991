import React, {useEffect, useRef, useState} from "react";
import './Assets/single.scss';
import './Assets/single-media-query.scss';
import {Col, Container, Row} from 'react-bootstrap';
import Freelance from './Type/Freelance/Freelance';
import SingleSkeleton from './SingleSkeleton';
import Gallery from './Type/Parts/Gallery';
import SingleHeader from './Header/SingleHeader';
import useApi from "../../Hooks/useApi";
import ProfileStatus from "../Profile/Common/ProfileStatus";
import Thumbnail from '../Browse/Thumbnail/Thumbnail';
import FloatingButtons from "../Parts/FloatingButtons";

const useSingle = (id) => {

	const {request} = useApi();

	const [loading, setLoading] = useState();

	const [item, setItem] = useState(false);

	const [gallery, setGallery] = useState();

	const [thumbnail, setThumbnail] = useState()

	const containerRef = useRef();

	/**
	 *
	 * Get job/profile contents
	 *
	 */

	useEffect(() => {

		setLoading(true);

		const controller = new AbortController();

		request({
			method: "get",
			url: id ? `/user/${id}` : "/profile/preview",
			onSuccess: (data) => {
				setItem(data.details);
				setGallery(data.gallery);
				setThumbnail(data);
			},
			onError: () => {
				setItem(404);
			}
		}).finally(() => {

			setLoading(false);

		})

		return () => { controller.abort(); };

	}, [id]);

	/**
	 *
	 * Return single
	 *
	 */

	const Content = () => {

		if(loading) {

			return (
				<Container>
					<SingleSkeleton/>
				</Container>
			);

		}

		return (

			<div className={`page-single type-${item && item.type}`} ref={containerRef}>
				<ProfileStatus />
				{item &&
					<>
						<SingleHeader loading={loading} item={item}/>
						<Container>
							<Row>
								<Col md={12} lg={4}>
									<div className={`thumbnail-preview`}>
										<Thumbnail item={thumbnail} autoplay={false}/>
									</div>
									<div className={`single-gallery`}>
										<Gallery gallery={gallery}/>
									</div>
								</Col>
								<Col md={12} lg={8} className="mt-lg-0 mt-3 position-relative">
									<Freelance item={item} loading={loading} gallery={gallery} />
									{!loading && <FloatingButtons data={item} containerRef={containerRef} />}
								</Col>
							</Row>
						</Container>
					</>
				}
			</div>

		);

	};

	return {Content, loading, item};

};

export default useSingle;
