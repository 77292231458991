import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import callToAPI from "../../api";

export const fetchConfig = createAsyncThunk('config/fetchConfig', async () => {

	try {
		const response = await callToAPI({ method: 'get', url: '/options' });

		if(!response) {

			throw new Error('Error in request');

		}

		return response;

	}
	catch (error) {

		throw new Error('Error in request');

	}
});

const initialData = {
	config: null,
	loading: true,
	offline: true
}

const configSlice = createSlice({
	name: 'config',
	initialState: initialData,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchConfig.fulfilled, (state, action) => {
				state.loading = false;
				state.offline = false;
				state.config = action.payload.variables;
			})
			.addCase(fetchConfig.rejected, (state, action) => {
				console.log('API ERROR: ', action.error.message);
				state.loading = false;
				state.offline = true;
			});
	}

})

export default configSlice.reducer;