import useMessage from "../../../../Hooks/ReduxHooks/useMessage";
import {ChevronLeft} from "react-feather";
import MoreOptions from "../../../Parts/Misc/MoreOptions";
import React from "react";
import SkeletonTopPanel from "../../Skeleton/SkeletonTopPanel";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import placeholderImage from "../../../../assets/images/placeholder.jpg";

const UserLogoContent = ({url}) => {

	const handleError = (e) => {
		e.target.src = placeholderImage;
	}

	return(
		<div className="avatar rounded-5">
			{url ? <img src={url} alt="Placeholder Image" onError={handleError} /> : <img src={placeholderImage} alt="Placeholder Image" />}
		</div>
	)
}

const TopPanel = () => {

	const {conversationData, handleSetChatID, loadings, chatID} = useMessage()

	const {isMobile} = useUser();

	return(
		<div className="p-3 top-panel border-bottom d-flex justify-content-between align-items-center w-100">
			{loadings.conversationData ?
				<SkeletonTopPanel />
				:
				<div className="d-flex align-items-center">
					{isMobile && <ChevronLeft onClick={() => handleSetChatID(null)} className="me-2 text-primary cursor-pointer" />}
					<div className={`d-flex align-items-center user`}>
						<UserLogoContent url={conversationData.receiverAvatar} />
						<div className="d-flex flex-column ms-2">
							<span className={`font-weight-md ${conversationData.unreadCount > 0 ? 'unread' : ''}`}>{conversationData.receiverName !== null ? conversationData.receiverName : 'No name'}</span>
						</div>
					</div>
				</div>
			}
			<MoreOptions item={chatID} options={['Report', 'ArchiveConversation','Close']} />
		</div>
	)
}

export default TopPanel;