import { useDispatch, useSelector } from 'react-redux';
import { updateProgress, resetController } from '../../redux/slices/backgroundSlice';

const useBackground = () => {
	const dispatch = useDispatch();

	const { progress, isUploading, controller } = useSelector((state) => state.background);

	const setProgress = (newProgress) => {
		dispatch(updateProgress(newProgress));
	};

	const abortUpload = () => {
		dispatch(resetController());
	};

	return {
		progress,
		setProgress,
		isUploading,
		controller,
		abortUpload
	};
};

export default useBackground;
