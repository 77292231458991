import React, {useState} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import {Button, Form} from 'react-bootstrap';
import {Rings} from 'react-loader-spinner';
import {useTranslation} from 'react-i18next';
import ProfileError from "../../Edit/Parts/ProfileError";
import TogglePassword from "../../../Parts/TogglePassword";

const Initial = () => {

	const { t } = useTranslation();

	const [loading, setLoading] = useState();

	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleToggle = () => {
		setPasswordVisible(prev => !prev);
	};

	const handleRemoveAccount = (values) => {

		setLoading(true);

		let data = {
			current_password: values.password,
			action: 'init',
		};

		// TODO: remove account init

	}

	const ValidationSchema = Yup.object().shape({
		password: Yup.string().required(t('profile.profile.tabs.settingsTab.changePasswordForm.validations.currentPassword.required')),
	});
	return(
		<>
			<h3 className={'font-xxxl'}>Usuń konto</h3>
			<Formik initialValues={{password: "", checkboxAccept: "",}} onSubmit={(values) => handleRemoveAccount(values)} validationSchema={ValidationSchema}>
				{({errors, touched, handleSubmit, values, handleChange, handleBlur}) => (
					<Form onSubmit={handleSubmit} className="change-password-form">
						<Form.Group className={'mb-4'}>
							<div className="position-relative mt-4">
								<Form.Label>{t('profile.profile.tabs.settingsTab.changePasswordForm.currentPassword.label')}</Form.Label>
								<Form.Control className={errors.password ? 'border border-danger' : ''} placeholder={t('profile.profile.tabs.settingsTab.changePasswordForm.currentPassword.placeholder')} type={passwordVisible ? "text" : "password"} name="password" onChange={handleChange} onBlur={handleBlur} value={values.password}/>
								<TogglePassword passwordVisible={passwordVisible} onToggle={handleToggle} />
								<ProfileError profileError={errors.password} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-5'} />
							</div>
						</Form.Group>
						<Form.Group className={'mb-4'} controlId={'accept'}>
							<Form.Check className="font-lg" type="checkbox" label={'I confirm that I want to delete my JobMultiverse. com account and all related materials from my account. I am aware that all data will be deleted after 30 days.'} onChange={handleChange} />
						</Form.Group>
						<Button className={'btn-has-loader d-flex w-100'} type={'submit'} onClick={handleSubmit} disabled={loading}>
							{loading && <Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/>}
							<span>Remove the account</span>
						</Button>
					</Form>
				)}
			</Formik>
		</>
	)
}

export default Initial;
