import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Plus, X } from 'react-feather';
import { useFormikContext } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import ProfileError from "../../Parts/ProfileError"

const DisplayExperienceItem = ({ item }) => {
	const { setFieldValue, values } = useFormikContext();

	const handleRemove = async (value) => {
		await setFieldValue('experience', values.experience.filter((item) => item.value !== value));
	};

	return (
		<div className="me-3 mt-3 d-flex align-items-center font-family-open-sans pt-2 pb-2 ps-3 pe-3 font-lg bg-gray rounded-5">
			<span className="me-1">{item.from} - {item.to}</span> | <span className="ms-1">{item.value} </span>
			<X className="ms-2 cursor-pointer" size={16} onClick={() => handleRemove(item.value)} />
		</div>
	);
};

const DisplayExperienceList = () => {
	const { values } = useFormikContext();
	if (!values.experience) return null;

	return (
		<div className="mt-4 d-flex flex-wrap">
			{values.experience.map((item, index) => <DisplayExperienceItem key={index} item={item} />)}
		</div>
	);
};

const TabExperience = () => {
	const { t } = useTranslation();
	const { setFieldValue, values } = useFormikContext();

	const [errors, setErrors] = useState({
		from: { hasError: false, message: '' },
		to: { hasError: false, message: '' },
		value: { hasError: false, message: '' },
	});

	const fromRef = useRef();
	const toRef = useRef();
	const valueRef = useRef();

	useEffect(() => {
		fromRef.current.focus();
	}, []);

	const handleNewExperience = () => {
		const fromValue = fromRef.current.value.trim();
		const toValue = toRef.current.value.trim();
		const valueValue = valueRef.current.value.trim();

		let newErrors = {
			from: { hasError: false, message: '' },
			to: { hasError: false, message: '' },
			value: { hasError: false, message: '' },
		};

		if (fromValue.length === 0) {
			newErrors.from = { hasError: true, message: 'The "from" field is required' };
		}
		if (toValue.length === 0) {
			newErrors.to = { hasError: true, message: 'The "to" field is required' };
		}
		if (valueValue.length === 0) {
			newErrors.value = { hasError: true, message: 'The field "experience" is required' };
		}

		setErrors(newErrors);

		if (newErrors.from.hasError || newErrors.to.hasError || newErrors.value.hasError) {
			return;
		}

		setFieldValue('experience', [
			...values.experience,
			{
				from: fromValue,
				to: toValue,
				value: valueValue,
			}
		]);

		fromRef.current.value = '';
		toRef.current.value = '';
		valueRef.current.value = '';
	};

	return (
		<div className="experience-form">
			<div>
				<div className="d-flex align-items-center mt-4 mb-3 position-relative repeater-title">
					<h2 className="title m-0">{t('profile.profile.tabs.profileTab.index.sections.experience.title')}</h2>
					<ProfileError profileError={(errors.to.hasError || errors.from.hasError || errors.value.hasError) ? `${errors.to.message} ${errors.from.message} ${errors.value.message}` : null} />
				</div>
				<Row>
					<Col xs={12} md={6}>
						<div className={'mb-3 mb-md-0 d-flex w-100'}>
							<div className="position-relative w-100 me-3">
								<Form.Label>{t('profile.edit.tabs.content.tabExperience.formItems.from.label')}</Form.Label>
								<Form.Control type="text" ref={fromRef} placeholder={t('profile.edit.tabs.content.tabExperience.formItems.from.placeholder')} className={`profile-repeater-wrapper ${errors.from.hasError ? 'border-danger border' : ''}`}/>
							</div>
							<div className="position-relative w-100">
								<Form.Label>{t('profile.edit.tabs.content.tabExperience.formItems.to.label')}</Form.Label>
								<Form.Control type="text" ref={toRef} placeholder={t('profile.edit.tabs.content.tabExperience.formItems.to.placeholder')} className={`profile-repeater-wrapper ${errors.to.hasError ? 'border-danger border' : ''}`}/>
							</div>
						</div>
					</Col>
					<Col xs={9} md={4}>
						<div className="position-relative w-100">
							<Form.Control type="text" ref={valueRef} placeholder={t('profile.profile.tabs.common.addExp.placeholder')} size={'sm'} className={`profile-repeater-wrapper ${errors.value.hasError ? 'border-danger border' : ''}`}/>
						</div>
					</Col>
					<Col xs={3} md={2}>
						<div className="h-100">
							<button type={'button'} className="btn btn-primary md btn-circle flex-shrink-0 ms-2" onClick={handleNewExperience}>
								<Plus />
							</button>
						</div>
					</Col>
				</Row>
			</div>
			<DisplayExperienceList />
		</div>
	);
};

export default TabExperience;
