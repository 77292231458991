import React from 'react';
import Initial from './Initial';
import Confirm from './Confirm';
import Deleted from './Deleted';
import useUser from "../../../../Hooks/ReduxHooks/useUser";

const Index = () => {

	const {user} = useUser();

	return(
		<>
			{user.status === 'active' && <Initial/>}
			{user.status === 'deleting' && <Confirm/>}
			{user.status === 'deleted' && <Deleted/>}
		</>
	)
}

export default Index;
