import React from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

const BecomeSeller = ({handleChange}) => {

	return(
		<>
			<Row className="bg-primary p-4 w-100 rounded-5 m-0">
				<Col md={8} className="p-0">
					<label className="d-flex align-items-center">
						<p className={`text-uppercase mb-0 font-xxxl text-white font-weight-md`}>Become a client</p>
						<div className="toggle d-flex justify-content-between align-items-center cursor-pointer ms-3">
							<input className="toggle-checkbox" type="checkbox" onChange={() => handleChange('searcher')} />
							<div className="toggle-switch"></div>
						</div>
					</label>
					<p className="font-xl font-family-open-sans text-light line mt-2 mb-0 font-weight-sm" style={{lineHeight: '20px'}}>
						Become a seller of your services. After changing your account type to seller, you can complete your profile and join our community offering services or jobs.
						The key to success is creating a profile and recording a great Video Resume so that someone can choose you from among the competition.
						You can not only advertise your services on your profile but also promote yourself as a contractor or employee.
						You can complete your profile and share it on social networks, or simply wait for someone to find you and choose from the offers.
						The job market is yours. You choose who you want to work with.
					</p>
				</Col>
				<Col md={4}>
					VIDEO
				</Col>
			</Row>
			<Link to='/account/edit' className="btn btn-primary text-uppercase mt-3">
				Edit your profile
			</Link>
		</>
	)
}

export default BecomeSeller;