import { useDispatch, useSelector } from 'react-redux';
import { showPopup, hidePopup } from '../../redux/slices/popupSlice';

const usePopup = () => {
	const dispatch = useDispatch();

	const popupContent = useSelector((state) => state.popup.popupContent);

	const openPopup = (component, props) => {
		dispatch(showPopup({ component, props }));
	};

	const closePopup = () => {
		dispatch(hidePopup());
	};

	return {
		popupContent,
		openPopup,
		closePopup,
	};
};

export default usePopup;
