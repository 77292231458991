const AuthHeader = ({title, subtitle, buttonText, buttonHandleClick}) => {
	return(
		<div className="d-flex flex-column">
			<h2 className="auth-header-title font-weight-md">{title}</h2>
			<div className="d-flex font-xxl d-flex">
				<p>
					{subtitle}
					<span className="ms-2 text-decoration-underline font-weight-sm cursor-pointer" onClick={buttonHandleClick}>{buttonText}</span>
				</p>
			</div>
		</div>
	)
}

export default AuthHeader;