import React, {useState} from "react";
import BecomeSeller from "./BecomeSeller";
import SwitchBuyer from "./SwitchBuyer";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import useApi from "../../../../Hooks/useApi";
import {Loader} from "../../../Parts/Loaders";

const AccountType = () => {

	const {user, handleSetUser} = useUser();

	const {request} = useApi();

	const [loading, setLoading] = useState(false)

	const changeAccountType = async (type) =>{
		setLoading(true)
		await request({
			url: '/profile',
			method: 'post',
			body: {
				action: 'set_account_type',
				type: type
			},
			onSuccess: data => {
				if(data.status === 'success'){
					handleSetUser({...user, account_type: data.type})
				}
			}
		})
			.finally(() => {
				setLoading(false);
			})
	}

	if(loading){
		return <Loader/>
	}

	return(
		<div>
			<h3 className={'font-xxxl mb-3'}>Account type</h3>
			<div className={'ms-3 mt-4'}>
				<h4 className="font-xxxl">{user.account_type === 'searcher' ? 'Now you are a client' : 'Now you are a seller'}</h4>
				<p className="text-gray-444 font-xl font-family-open-sans">You can search and browse seller profiles, and contact them to use the service.</p>
			</div>
			{user.account_type === 'searcher' && <SwitchBuyer handleChange={changeAccountType} />}
			{user.account_type === 'provider' && <BecomeSeller handleChange={changeAccountType} />}
		</div>
	)
}

export default AccountType;