import {ArrowDown} from 'react-feather';
import {useTranslation} from 'react-i18next';
import useMessage from "../../../Hooks/ReduxHooks/useMessage";

const NewMessagePopup = ({scrollToBottom, isScrollBottom}) => {

	const {isNewMessage} = useMessage()

	const { t } = useTranslation();
	const checkNewMessage = () => {
		scrollToBottom()
	}
	if(!isNewMessage || isScrollBottom){
		return
	}
	return(
		<div className="new-message-popup position-sticky d-flex align-items-center justify-content-center">
			<button className="btn-primary btn" onClick={checkNewMessage}>
				{t('messages.newMessagePopup.text')}
				<ArrowDown className="ms-2" />
			</button>
		</div>
	)
}

export default NewMessagePopup;