import React from 'react';
import Video from './Video/Video';
import Image from './Image';
import placeholderImage from '../../../assets/images/placeholder.jpg';

const Thumbnail = ({autoplay, controls, fit, muted, loop, item}) => {

	const {thumbnail} = item;

	if(thumbnail.source === null || thumbnail.source === '') {

		return <div className={'image-full loaded'}><img src={placeholderImage} alt={placeholderImage}/></div>
	}

	if(['image/jpg', 'image/jpeg', 'image/png', 'image/webp'].includes(thumbnail.resource_type)) {

		return (<Image src={thumbnail.source} item={item}/>);
	}

	return (<Video src={thumbnail.source} poster={item.avatar} autoplay={autoplay} loop={loop} muted={muted} duration={thumbnail.duration} controls={controls}/>);

}

export default Thumbnail;