import React from "react";
import Section from '../Common/Section';
import {useTranslation} from 'react-i18next';
import DisplayName from '../Common/DisplayName';
import DisplayDesc from '../Common/DisplayDesc';
import DisplayInformation from '../Common/DisplayInformation';
import GetMap from '../../../Parts/Map';
import DisplayExperience from '../Common/DisplayExperience';

const Freelance = ({item}) => {
	const { t } = useTranslation();
	const sectionsMap = [
		{ title: t('jobs.single.type.freelancer.freelancer.titles.skills'), items: item.skills },
		{ title: t('jobs.single.type.freelancer.freelancer.titles.interests'), items: item.interests },
		{ title: t('jobs.single.type.freelancer.freelancer.titles.characterTraits'), items: item.character },
		{ title: t('jobs.single.type.freelancer.freelancer.titles.characterTraits'), items: item.expectations },
		{ title: t('jobs.single.type.freelancer.freelancer.titles.languages'), items: item.languages, type: 'languages' },
		{ title: t('jobs.single.type.job.job.sectionTitles.title3'), items: { contract: item.contract, time: item.working_time, mode: item.working_mode }, type: "expectations" }
	];

	return (
		<>
			<div>
				{/*<DisplayDate startsAt={item.starts_at} endsAt={item.ends_at} />*/}
				<DisplayName item={item}/>
			</div>
			<div className={'content-header'}>
				<DisplayInformation item={item} />
			</div>
			<div className={'content'}>
				<DisplayDesc desc={item.about} title={t('jobs.single.type.freelancer.freelancer.titles.aboutMe')} />
				{sectionsMap.map((section, index) => (
					<Section key={index} title={section.title} items={section.items} type={section.type} />
				))}
				<DisplayExperience experience={item.experience} item={item} />
				<GetMap location={item.locations} type="freelance" />
			</div>
		</>
	);

};

export default Freelance;
