import React, {useState} from 'react';
import CloseOutside from '../../../Parts/CloseOutside';
import Config from '../../../../Config/AppConfig';
import AnimatedDropdown, {AnimatedDropdownList, AnimatedDropdownListItem} from '../../../Parts/AnimatedDropdown';

const getRanges = (key = null) => {

	if(key !== null) {

		return Config.ranges[key];

	}

	return Config.ranges;

};

const LocationRadius = ({location, setLocation, className}) => {

	const [open, setOpen] = useState(false);

	const handleClickCurrentRange = (e) => {

		e.preventDefault();

		setOpen(!open);

	};

	const handleClickRange = (radius) => {

		setOpen(false);

		setLocation(prev => ({...prev, radius: radius}));

	};

	const MappedRadius = Object.keys(getRanges());

	if(location.lat == null) {

		return null;

	}

	return (
		<CloseOutside handleClose={() => setOpen(false)} className={`search-location-radius ${className} ${open ? 'active' : ''}`}>
			<button onClick={handleClickCurrentRange}>
				<span>+ {location.radius} km</span>
			</button>
			<AnimatedDropdown isOpen={open} className={'search-location-radius-options'}>
				<AnimatedDropdownList>
					{MappedRadius.map((radius, index) => <AnimatedDropdownListItem key={index} handleClick={() => handleClickRange(radius)}>{radius} km</AnimatedDropdownListItem>)}
				</AnimatedDropdownList>
			</AnimatedDropdown>
		</CloseOutside>
	);

};

export default LocationRadius;
