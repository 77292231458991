import {Formik} from "formik";
import {Form} from "react-bootstrap";
import ValidationError from "../../Parts/ValidationError";
import {Rings} from "react-loader-spinner";
import React, {useState} from "react";
import AuthBySocialMedia from "../Parts/AuthBySocialMedia";
import {signUp} from "aws-amplify/auth";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import ProfileError from "../../Profile/Edit/Parts/ProfileError";
import TogglePassword from "../../Parts/TogglePassword";

const RegisterForm = ({setAuthType}) => {

	const [loading, setLoading] = useState(false)

	const [error, setError] = useState({
		status: false,
		text: ''
	})

	const {t} = useTranslation();

	const initialValuesData = {
		email: '',
		password: '',
		name: ''
	}

	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleToggle = () => {
		setPasswordVisible(prev => !prev);
	};

	const handleSubmit = async(values) => {
		setLoading(true)

		try {
			const { isSignUpComplete, nextStep } = await signUp({
				username: values.email,
				password: values.password,
				options: {
					userAttributes: {
						name: values.name
					},
					autoSignIn: true
				}
			});

			localStorage.setItem('email', values.email)

			if (!isSignUpComplete && nextStep.signUpStep === "CONFIRM_SIGN_UP") {
				setAuthType('verify')
			}

		} catch (error) {
			setError({
				status: true,
				text: error.message
			})
		}

		setLoading(false)

	};

	const Schema = {
		name: Yup.string().required('Username is required'),
		email: Yup.string().required(t('auth.register.registerForm.validation.email.required')).email(t('auth.register.registerForm.validation.email.email')),
		password: Yup.string().required(t('auth.register.registerForm.validation.password.required')).min(8, t('auth.register.registerForm.validation.password.min')).matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1})((?=.*\d){1}).*$/, t('auth.register.registerForm.validation.password.matches')),
	};
	return(
		<Formik validateOnChange={false} validateOnBlur={false} initialValues={initialValuesData} onSubmit={handleSubmit} validationSchema={Yup.object().shape(Schema)}>
			{({errors, touched, handleSubmit, values, handleChange, handleBlur}) => (
				<Form onSubmit={handleSubmit} className="auth-form">
					<div>
						<span className="font-weight-lg font-lg">
							by E-mail
						</span>
						<Form.Group id="name" className="my-3 position-relative">
							<Form.Control className={`${errors.name ? 'border border-danger' : 'border border-white'}`} type="text" name="name" value={values.name} placeholder="Username" onChange={handleChange} onBlur={handleBlur}/>
							<ProfileError profileError={errors.name} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'} />
						</Form.Group>
						<Form.Group id="email" className="my-3 position-relative">
							<Form.Control className={`${errors.email ? 'border border-danger' : 'border border-white'}`} type="email" name="email" value={values.email} placeholder="E-mail" onChange={handleChange} onBlur={handleBlur}/>
							<ProfileError profileError={errors.email} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'} />
						</Form.Group>
						<Form.Group id="password">
							<div className="position-relative">
								<Form.Control className={`${errors.password ? 'border border-danger' : 'border border-white'}`} type={passwordVisible ? "text" : "password"} name="password" value={values.password} placeholder="Password" onChange={handleChange} onBlur={handleBlur}/>
								<TogglePassword passwordVisible={passwordVisible} onToggle={handleToggle} />
								<ProfileError profileError={errors.password} additionalStyles={'me-5 position-absolute end-0 top-50 translate-middle-y'} />
							</div>
						</Form.Group>
					</div>
					<button disabled={loading} className="btn btn-primary w-100 mt-3 font-lg auth-submit-button d-flex justify-content-center" type={'submit'}>
						{!loading && <>Join</>}
						{loading && <Rings height="28" width="28" color="#fff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
					</button>
					<ValidationError isErrorVisible={error.status} errorMessage={error.text} additionalClass={'position-absolute'} />
					<AuthBySocialMedia />
				</Form>

			)}
		</Formik>
	)
}

export default RegisterForm;