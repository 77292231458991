import {Formik} from "formik";
import {Form} from "react-bootstrap";
import ValidationError from "../../Parts/ValidationError";
import {Rings} from "react-loader-spinner";
import React, {useState} from "react";
import {confirmResetPassword} from 'aws-amplify/auth';
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import ProfileError from "../../Profile/Edit/Parts/ProfileError";
import TogglePassword from "../../Parts/TogglePassword";

const ResetForm = ({setAuthType}) => {

	const {t} = useTranslation();

	const [error, setError] = useState({
		status: false,
		text: ''
	})

	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleToggle = () => {
		setPasswordVisible(prev => !prev);
	};

	const [loading, setLoading] = useState(false)

	const handleSubmit = async (values) => {
		setLoading(true);
		try{
			await confirmResetPassword({username: localStorage.getItem('forgot-email'), confirmationCode: localStorage.getItem('forgot-verify-code'), newPassword: values.password})
			setLoading(false);
			setAuthType('login')
			localStorage.removeItem('forgot-email')
			localStorage.removeItem('forgot-verify-code')
		}
		catch(err){
			setError({
				status: true,
				text: err.message
			})
			setLoading(false);
		}
	}

	const initial = {
		password: '',
		passwordConfirmation: ''
	}

	const Schema = {
		password: Yup.string().required(t('auth.register.registerForm.validation.password.required')).min(8, t('auth.register.registerForm.validation.password.min')).matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1})((?=.*\d){1}).*$/, t('auth.register.registerForm.validation.password.matches')),
		passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], t('auth.register.registerForm.validation.passwordConfirmation.oneOf')).required(t('auth.register.registerForm.validation.passwordConfirmation.required')),
	}

	const handleBack = () => {
		setAuthType('forgot-verify')
		localStorage.removeItem('forgot-verify-code')
	}

	return(
		<Formik onSubmit={handleSubmit} initialValues={initial} validationSchema={Yup.object().shape(Schema)}>
			{({errors, touched, handleSubmit, values, handleChange, handleBlur}) => (
				<Form onSubmit={handleSubmit} className="auth-form mb-5">
					<div>
						<span className="font-weight-lg font-lg">
							Enter new password:
						</span>
						<Form.Group id="password" className={'position-relative'}>
							<div className="position-relative">
								<Form.Control className={`${errors.password ? 'border border-danger' : 'border border-white'}`} type={passwordVisible ? "text" : "password"} name="password" value={values.password} placeholder="Password" onChange={handleChange} onBlur={handleBlur}/>
								<TogglePassword passwordVisible={passwordVisible} onToggle={handleToggle} />
							</div>
							<ProfileError profileError={errors.password} additionalStyles={'me-5 position-absolute end-0 top-50 translate-middle-y'} />
						</Form.Group>
						<Form.Group id="retype_password" className="my-3 position-relative">
							<div className="position-relative">
								<Form.Control className={`${errors.passwordConfirmation ? 'border border-danger' : 'border border-white'}`} type={passwordVisible ? "text" : "password"} name="passwordConfirmation" placeholder="Repeat password" value={values.passwordConfirmation} onChange={handleChange} onBlur={handleBlur}/>
							</div>
							<ProfileError profileError={errors.passwordConfirmation} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'} />
						</Form.Group>
					</div>
					<button disabled={loading || errors.password || errors.passwordConfirmation} className="btn btn-primary w-100 mt-3 font-lg auth-submit-button d-flex justify-content-center" type={'submit'}>
						{!loading && <>Set new password</>}
						{loading && <Rings height="28" width="28" color="#fff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
					</button>
					<ValidationError isErrorVisible={error.status} errorMessage={error.text} />
					<div className="d-flex justify-content-center mt-4">
						<button type="submit" onClick={handleBack} className="d-flex justify-content-center bg-transparent border-0 text-decoration-underline font-lg font-weight-md">
							Back
						</button>
					</div>
				</Form>

			)}
		</Formik>
	)
}

export default ResetForm;