import React, {useState} from "react";
import ThumbnailUploadForm from './ThumbnailUploadForm';
import {Loader} from '../../../../Parts/Loaders';
import useUser from "../../../../../Hooks/ReduxHooks/useUser";
import useError from "../../../../../Hooks/ReduxHooks/useError";
import ProfileError from "../../Parts/ProfileError";
import useBackground from "../../../../../Hooks/ReduxHooks/useBackground";
import Video from '../../../../Browse/Thumbnail/Video/Video';
import Image from '../../../../Browse/Thumbnail/Image';
import LoadingProgressBar from '../../../../Browse/Thumbnail/Video/LoadingProgressBar';

const NoThumbnail = () => {
	return (
		<div className="no-video h-100 w-100 rounded-4 position-relative">

		</div>
	);
};

const Display = ({thumbnail, loading, avatar, previewUrlType}) => {

	const {progress} = useBackground()

	const {thumbnailError} = useError()

	if(loading !== false) {
		return (
			<>
				{loading.state === 'uploading' && previewUrlType?.split('/')[0] === 'video' ?
					<LoadingProgressBar progress={progress} additionalStyles="loading-progress-bar-wrapper d-flex align-items-center position-absolute bottom-50" />
					:
					<Loader text={loading.text}/>
				}
			</>

		)

	}

	if(!thumbnail.source) {

		return <NoThumbnail/>;

	}

	let formatImage = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];

	if(formatImage.includes(thumbnail.resource_type)) {

		return (
			<div className="position-relative">
				<Image src={thumbnail.source} isError={thumbnailError.errors}/>
				<ProfileError errorValue={thumbnailError.errors} additionalStyles="position-absolute bottom-0 end-0 mb-2 me-2" type='thumbnail' />
			</div>
		);
	}

	return (
		<>
			<Video src={thumbnail.source} autoplay={false} avatar={avatar}/>
			<ProfileError errorValue={thumbnailError.errors} additionalStyles="position-absolute bottom-0 end-0 mb-2 me-2" type='thumbnail' />
		</>
	);

};

const Thumbnail = () => {

	const [previewUrl, setPreviewUrl] = useState(null);

	const [previewUrlType, setPreviewUrlType] = useState(null)

	const {handleSetThumbnail, handleSetAvatar, loadingImage, handleSetImageLoading, user} = useUser()

	return (

		<>
			<div className={`profile-thumbnail w-100 position-relative ${user.thumbnail ? 'has' : 'no-has'}`} id="profile-thumbnail-guide">
				<div className={`position-relative h-100 p-2 profile-thumbnail-wrapper ${loadingImage ? 'loading' : ''} ${user.thumbnail?.resource_type?.split('/')[0] === 'video' || previewUrlType?.split('/')[0] === 'video' ? 'video' : ''}`}>
					<ThumbnailUploadForm thumbnail={user.thumbnail} setThumbnail={handleSetThumbnail} loading={loadingImage} setLoading={handleSetImageLoading} setPreviewUrl={setPreviewUrl} previewUrl={previewUrl} setAvatar={handleSetAvatar} setPreviewUrlType={setPreviewUrlType} previewUrlType={previewUrlType}/>
					<Display thumbnail={user.thumbnail} loading={loadingImage} previewUrl={previewUrl} avatar={user.avatar} previewUrlType={previewUrlType}/>
				</div>
			</div>
		</>

	);

};

export default Thumbnail;
