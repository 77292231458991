import React, {useState} from "react";
import VerificationInput from "react-verification-input";
import {Rings} from "react-loader-spinner";
import {autoSignIn, confirmSignUp} from "aws-amplify/auth";
import ValidationError from "../../Parts/ValidationError";
import {Form} from "react-bootstrap";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import {useLocation, useNavigate} from "react-router-dom";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";

const VerifyForm = ({setAuthType, verifyCodeResend, setVerifyCodeResend}) => {

	const {closePopup} = usePopup();

	const [isComplete, setIsComplete] = useState(false)

	const [codeValue, setCodeValue] = useState()

	const [loading, setLoading] = useState(false)

	const [error, setError] = useState({
		status: false,
		text: ''
	})

	const navigate = useNavigate();

	const location = useLocation();

	const {handleRefresh} = useUser();

	const handleComplete = (code) => {
		setCodeValue(code)
		setIsComplete(true)
	}

	const handleChange = () => {
		setIsComplete(false)
		setError({
			status: false,
			text: '',
		})
		setVerifyCodeResend({
			status: false,
			text: ''
		})
	}

	const handleBack = () => {
		setAuthType('register')
		localStorage.removeItem('email')
	}

	const handleSubmit = async (e) => {
		setLoading(true)
		e.preventDefault()
		const currentLocation = location.pathname;
		if(codeValue){
			try{
				const {isSignUpComplete, nextStep } = await confirmSignUp({
					username: localStorage.getItem('email'),
					confirmationCode: codeValue
				});
				if (isSignUpComplete && nextStep.signUpStep === 'COMPLETE_AUTO_SIGN_IN') {
					localStorage.removeItem('email')
					setLoading(false)
					await autoSignIn();

					await handleRefresh();

					closePopup();
					navigate(currentLocation);
				}
			}
			catch(err){
				setError({
					status: true,
					text: err.message
				})
				setLoading(false)
			}
		}
	}

	return(
		<Form className="auth-form" onSubmit={handleSubmit}>
			<span className="font-weight-lg font-lg mb-3 d-block">
				Your verification code:
			</span>
			<div className="verification-input-wrapper">
				<VerificationInput onChange={handleChange} autoFocus onComplete={handleComplete} validChars="0-9" inputProps={{ inputMode: "numeric" }} placeholder="" classNames={{character: "character", container: "container", characterSelected: "character-selected",}} />
				<p className={`mt-4 ${verifyCodeResend.status ? 'resend-error' : 'resend-successful'} font-md position-absolute`}>{verifyCodeResend.text}</p>
			</div>
			<button type="submit" className="btn-primary btn w-100 auth-submit-button d-flex justify-content-center" disabled={!isComplete}>
				{!loading && <>Confirm</>}
				{loading && <Rings height="28" width="28" color="#ffffff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
			</button>
			<ValidationError isErrorVisible={error.status} errorMessage={error.text} additionalClass={'position-relative'} />
			<div className="d-flex justify-content-center mt-4">
				<button onClick={handleBack} className="d-flex justify-content-center bg-transparent border-0 text-decoration-underline font-lg font-weight-md">
					Back
				</button>
			</div>
		</Form>
	)
}

export default VerifyForm;