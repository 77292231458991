import algoliasearch from 'algoliasearch/lite';
import {history} from 'instantsearch.js/es/lib/routers';

export const transformCurrentRefinements = (items) => {
	return items.map(item => {
		if(item.label === 'query') {
			item.label = "Query";
		}
		return item;
	});
};

export const statsTranslations = {
	rootElementText({ nbHits, processingTimeMS, nbSortedHits, areHitsSorted }) {
		return areHitsSorted && nbHits !== nbSortedHits ? `${nbSortedHits.toLocaleString()} matched results ${nbHits.toLocaleString()}` : `${nbHits.toLocaleString()} results`;
	}
};

export const HitsPerPage = 50;

const IndexName = process.env.REACT_APP_ALGOLIA_INDEX;

const refinementListMapper = [
	{
		key: 'working_mode',
		simplified: 'mode',
		separator: '-',
	},
	{
		key: 'working_time',
		simplified: 'time',
		separator: '-',
	},
	{
		key: 'categories',
		simplified: 'cat',
		separator: ',',
	},
	{
		key: 'contract',
		simplified: 'cn',
		separator: '-',
	},
	{
		key: 'languages.language',
		simplified: 'lang',
		separator: ',',
	}
]

export default {
	client: algoliasearch(
		process.env.REACT_APP_ALGOLIA_APP_ID,
		process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
	),
	index: {
		main: `${IndexName}-main`,
		suggestions: `${IndexName}-suggestions`
	},
	hierarchicalAttributes: [
		'categories'
	],
	sorting: [
		{label: 'Matched', value: `${IndexName}-main`},
		{label: 'New', value: `${IndexName}-recently-updated`},
	],
	routing:  {

		router: history({
			cleanUrlOnDispose: false,
		}),

		stateMapping: {

			stateToRoute(uiState) {

				const indexUiState = uiState[`${IndexName}-main`];

				let returnState = {
					query: indexUiState.query,
					//p: indexUiState.page,
					sort: indexUiState?.sortBy,
					hierarchicalMenu: {},
					refinementList: {},
					toggle: {}
				};

				refinementListMapper.map((obj) => {

					if(indexUiState.refinementList && obj.key in indexUiState.refinementList) {

						returnState[obj.simplified] = indexUiState.refinementList[obj.key].join(obj.separator);

					}

				});

				if('toggle' in indexUiState) {

					if('isPremium' in indexUiState.toggle) {

						returnState.premium = 'true';

					}

				}

				return returnState;
			},
			routeToState(routeState) {

				let returnState = {
					refinementList: {},
					//page: routeState.p,
					query: routeState.query,
					sortBy: routeState.sort,
					toggle: {}
				};

				refinementListMapper.map((obj) => {

					if(obj.simplified in routeState) {

						returnState.refinementList[obj.key] =routeState[obj.simplified].split('-');

					}

				});

				if('premium' in routeState) {

					returnState.toggle.isPremium = true;

				}

				return {
					[`${IndexName}-main`]: returnState
				};
			}
		}
	}
};