import logo from '../../../assets/logo.svg';
import './Header.scss';
import React, {useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Container} from 'react-bootstrap';
import useUser from "../../../Hooks/ReduxHooks/useUser";
import Notifications from './Notifications';
import Search from './Search/Search';
import Navigation from './Navigation';
import {useInstantSearch} from 'react-instantsearch';

const Logo = () => {

	const {setIndexUiState} = useInstantSearch();

	const handleClick = (e) => {

		// reset index ui state
		setIndexUiState((prev) => {});

	};

	return (
		<Link to="/" onClick={handleClick} className={'top-logo'}>
			<img src={logo} className={'logo'} alt={'logo'}/>
		</Link>
	);

};

const Header = () => {

	const {isMobile} = useUser();

	const location = useLocation();

	const [scroll, setScroll] = useState(0);

	const [hidden, setHidden] = useState(false);

	const handleScroll = () => {
		const currentScrollTop = window.scrollY;
		setScroll(prevState => {
			if(currentScrollTop > 82 && currentScrollTop > prevState) {
				setHidden(true);
			}
			else {
				setHidden(false);
			}
			return currentScrollTop;
		});
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	if(location.pathname !== '/' && isMobile) {

		return;

	}

	return (

		<div className={`app-top-bar ${hidden ? 'hidden' : 'show'}`}>
			<Notifications/>
			<Container>
				<div className={`app-top-bar-wrapper`}>
					<Logo/>
					<Search/>
					<Navigation/>
					{/*{!isMobile && <Col><UserPanel/></Col>}*/}
				</div>
			</Container>
		</div>

	);

};

export default Header;
