import ChangePassword from "./ChangePassword";
import ChangeEmail from "./ChangeEmail";
import React from "react";

const Account = () => {
	return(
		<div className="mb-4">
			<h3 className={'font-xxxl mb-3'}>Account</h3>
			<ChangeEmail />
			<ChangePassword />
		</div>
	)
}

export default Account;