const AuthFooter = () => {
	return(
		<p className="font-xs mt-4">
			By becoming a member, you are consenting to adhere to the JobMultiverse
			<a href="" className="text-decoration-underline text-dark ms-1 me-1">Terms of Service</a>
			and to occasionally receive correspondence from us.  Kindly review our
			<a href="" className="text-decoration-underline text-dark ms-1 me-1">Privacy Policy</a>
			for insights into how we handle your  personal information.
		</p>
	)
}

export default AuthFooter;