import {ArrowDownCircle} from 'react-feather';

const ArrowScrollDown = ({messageContentRef, arrowScrollDownVisible}) => {
	if(!arrowScrollDownVisible || messageContentRef.current === null){
		return
	}

	const scrollToBottom = () => {
		messageContentRef.current.scrollTop = messageContentRef.current.scrollHeight
	}

	return(
		<div className="position-sticky bottom-0 end-0 w-100 d-flex justify-content-center" onClick={scrollToBottom}>
			<ArrowDownCircle className="text-primary cursor-pointer arrow-down-icon" />
		</div>
	)
}

export default ArrowScrollDown;