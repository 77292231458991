import React from "react";
import {ReactComponent as GoogleLogo} from '../../../assets/svg/comapny-logo/google.svg';
import {ReactComponent as AppleLogo} from '../../../assets/svg/comapny-logo/apple.svg';
import {signInWithRedirect} from "aws-amplify/auth";

const AuthBySocialMedia = () => {
	return(
		<div className="mt-5 pt-3 social-media-auth">
			<button type="button" className="d-flex w-100 justify-content-center" onClick={() => signInWithRedirect({provider: "Google"})}>
				<GoogleLogo />
				<span>Continue with Google</span>
			</button>
			<button type="button" className="d-flex w-100 justify-content-center mt-2" onClick={() => signInWithRedirect({provider: "Apple"})}>
				<AppleLogo />
				<span>Continue with Apple</span>
			</button>
		</div>
	)
}

export default AuthBySocialMedia;