// hooks/useWebSocket.js

import {useDispatch, useSelector} from 'react-redux';
import {connectWebSocket, registerHandler, sendAction, unregisterHandler} from '../../redux/slices/webSocketSlice';

export const useWebSocket = () => {
	const dispatch = useDispatch();
	const { isSocketActive, socket, handlers, error } = useSelector(state => state.webSocket);

	const connect = () => {
		dispatch(connectWebSocket());
	};

	const register = (actionType, handler) => {
		dispatch(registerHandler({ actionType, handler }));
	};

	const unregister = (actionType, handler) => {
		dispatch(unregisterHandler({ actionType, handler }));
	};

	const send = (message) => {
		dispatch(sendAction(message));
	};

	return {
		isSocketActive,
		socket,
		handlers,
		error,
		connect,
		register,
		unregister,
		send,
	};
};
