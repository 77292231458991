import React from "react";
import {useTranslation} from 'react-i18next';
import AnimatedDropdown from '../../Parts/AnimatedDropdown';
import CloseIcon from '../../Parts/CloseIcon';
import useUser from "../../../Hooks/ReduxHooks/useUser";
import {ClearFilters, CustomRefinementList} from './FiltersParts';
import {Form} from 'react-bootstrap';
import ListGroup from 'react-bootstrap/ListGroup';
import {useStats} from 'react-instantsearch';

const FiltersDropdown = ({visible, setVisible}) => {

	const {t} = useTranslation();

	const {isMobile} = useUser();

	const {nbHits} = useStats();

	return (
		<AnimatedDropdown isOpen={visible} className="dropdown-filters">
			{isMobile && <CloseIcon handleClose={() => setVisible(false)} additionalStyles="mt-3 me-3"/>}
			{isMobile && <h2 className="mb-4">Filtry</h2>}
			<div className="d-flex flex-column flex-md-row">
				<div className={'column'}>
					<CustomRefinementList attribute="working_mode" sortBy={['name']} title={t('browse.filters.working_mode.title')}/>
					<CustomRefinementList attribute="working_time" sortBy={['name']} title={t('browse.filters.working_time.title')}/>
				</div>
				<div className={'column'}>
					<CustomRefinementList attribute="contract" sortBy={['name']} title={t('browse.filters.contract.title')}/>
				</div>
				<div className={'column'}>
					<CustomRefinementList attribute="languages.language" sortBy={['isRefined', 'count']} limit={10} showMore={true} showMoreLimit={99999} title={t('browse.filters.languages.title')}/>
				</div>
				<div className={'column'}>
					<div className={'title'}>
						{t('browse.filters.earnings.title')}
					</div>
					<ListGroup as="ul" className="mt-2 d-flex flex-md-column flex-row">
						<Form.Control placeholder="min." className="mb-md-2 mb-0"/>
						<Form.Control placeholder="max."/>
					</ListGroup>
				</div>
			</div>
			<div className="d-flex justify-content-center">
				<div className="filter-buttons">
					<button onClick={() => setVisible(false)} type="button" className="w-100 btn btn-primary mt-2 text-uppercase">{t('parts.header.searchOptions.restartFilters')} {nbHits}</button>
					<ClearFilters handleReset={() => setVisible(false)}/>
				</div>
			</div>
		</AnimatedDropdown>
	);
};

export default FiltersDropdown;
