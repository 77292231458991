import {useDispatch, useSelector} from "react-redux";
import Auth from "../../Components/Auth/Auth";
import useUser from "./useUser";
import usePopup from "./usePopup";
import {fetchObservations, setObservations, setObserveLoadings} from "../../redux/slices/watchlistSlice";
import useApi from "../useApi";
import {useState} from "react";

const useWatchlist = (uid) => {
	const dispatch = useDispatch();

	const { observations, observeLoading } = useSelector(state => state.watchlist);

	const {request} = useApi();

	const {user, handleSetUser} = useUser();

	const {openPopup} = usePopup();

	const [observedLoading, setObservedLoading] = useState(false)

	const [observed, setObserved] = useState(() => {

		if(!user) {

			return undefined;

		}

		return user.observations.includes(uid)

	});

	const handleSetObserveLoadings = (boolean) => dispatch(setObserveLoadings(boolean));

	const handleSetObservations = (data) => dispatch(setObservations(data));

	const handleFetchObservations = () => dispatch(fetchObservations());

	const handleObserve = async (uid) => {

		if (!user) {
			openPopup(Auth, {type: 'login'});
			return;
		}

		setObservedLoading(true)

		await request({
			method: 'post',
			url: `/profile/observations/${uid}`,
			onSuccess: (data) => {
				setObserved(data.status === 'created');
				handleSetUser(({...user, observations: user.observations.filter(el => el !== uid).concat(data.status === 'created' ? uid : [])}));
				setObservedLoading(false)
				if(data.status === 'deleted' && location.pathname === '/observations'){
					handleSetObservations(observations.filter(el => el !== `user-${data.userId}`))
				}
			},
			onError: () => {
				setObservedLoading(false)
				alert('User not found.')
			}
		});
	}

	return{
		handleObserve,
		handleSetObserveLoadings,
		handleSetObservations,
		handleFetchObservations,
		observations,
		observed,
		setObserved,
		observedLoading,
		observeLoading
	}
}

export default useWatchlist;