import React, {useRef} from 'react';
import Location from './Location';
import Query from './Query';
import {useInstantSearch, useSearchBox} from 'react-instantsearch';
import {useNavigate} from 'react-router-dom';
import {FaSearch} from 'react-icons/fa';
import useUser from '../../../../Hooks/ReduxHooks/useUser';

export const ButtonBrowse = ({queryRef, setVisible}) => {

	const {refresh} = useInstantSearch();

	const navigate = useNavigate();

	const searchBox = useSearchBox();

	const {isMobile} = useUser();

	const handleClick = () => {

		refresh();

		searchBox.refine(queryRef.current.value);

		const currentParams = new URLSearchParams(location.search);

		navigate(`/?${currentParams.toString()}`);

		if(isMobile) setVisible(false)

	};
	return (
		<button className="search-button" onClick={handleClick}>
			<FaSearch/>
		</button>
	);
};

const Search = () => {

	const queryRef = useRef(null);

	const {isMobile} = useUser();

	return (
		<div className={'top-search'}>
			<Query queryRef={queryRef}/>
			{!isMobile && <Location/>}
			{!isMobile && <ButtonBrowse queryRef={queryRef}/>}
		</div>
	);

};

export default Search;
