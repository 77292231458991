import React, {useEffect, useRef, useState} from "react";
import {Loader} from '../../../Parts/Loaders';
import Controls from './Controls';
import {Video as VideoIcon} from 'react-feather';
import './Video.scss';

const Video = ({src, poster, autoplay, duration = null, controls = true, muted = false, loop = false, setUploadFormVisible = false}) => {

	const [ended, setEnded] = useState(false)

	const videoRef = useRef();

	const [loading, setLoading] = useState(true);

	const [autoPlay, setAutoPlay] = useState(false);

	/**
	 *
	 * handle Load video
	 *
	 */

	const handleVideoLoaded = () => {

		setLoading(false);

	};

	/**
	 *
	 * Autoplay if it's true, pause if autoplay its changed
	 *
	 */

	useEffect(() => {

		if(videoRef.current !== null) {

			if(autoplay === true && ended !== true) {
				setAutoPlay(true);
				videoRef.current.play().catch(()=>{

				})

			}
			else {

				setAutoPlay(false);
				videoRef.current.pause()
				setEnded(false)

			}

			if(muted) {

				videoRef.current.muted = true;

			}

			if(loop) {

				videoRef.current.muted = true;

			}

		}

	}, [autoplay, ended]);

	/**
	 *
	 * handle Play and Pause
	 *
	 */

	const onPlaying = () => {

	}

	const onPause = () => {

	}

	const onEnded = () => {

		setEnded(true)

	}

	return (
		<div className={`video-full ${loading ? 'loading' : 'loaded'}`}>
			<Loader/>
			<VideoIcon className="video-icon d-none" size={24} />
			<video poster={poster} src={src} ref={videoRef} onProgress={handleVideoLoaded} onPlaying={onPlaying} onPause={onPause} autoPlay={false} onEnded={onEnded} playsInline></video>
			{controls && <Controls forwardRef={videoRef} src={src} autoplay={autoPlay} loading={loading} duration={duration} setUploadFormVisible={setUploadFormVisible}/>}
		</div>
	);

};
export default Video;
