import React, {useState} from 'react';
import {MoreVertical} from 'react-feather';
import CloseOutside from '../CloseOutside';
import useUser from "../../../Hooks/ReduxHooks/useUser";
import useHide from '../../../Hooks/useHide';
import {useTranslation} from 'react-i18next';
import usePopup from '../../../Hooks/ReduxHooks/usePopup';
import useMessage from '../../../Hooks/ReduxHooks/useMessage';
import ShareWindow from './ShareWindow';
import Auth from '../../Auth/Auth';
import ReportViolation from './ReportViolation';
import AnimatedDropdown from '../AnimatedDropdown';
import ObserverButton from "../../Browse/Parts/ObserverButton";

const MoreOptionsPopup = ({setVisible, item, options = []}) => {

	const liStyles = 'list-group-item bg-transparent border-0 p-0 mb-2';

	const {user} = useUser();

	const {t} = useTranslation();

	const {openPopup} = usePopup();

	const {archiveConversation} = useMessage();
	
	const Hide = ({item}) => {

		const {handleHide, isHidden, loading} = useHide(item.uuid);

		if(isHidden) {
			return ;
		}

		const handleClick = () => {

			if(user) {
				handleHide();
				setVisible(false);
			}
			else {
				openPopup(Auth, { type: 'login' });
			}
		}

		return (
			<li className={`${liStyles}`} onClick={handleClick} >
				{loading ? 'Please wait' : t('parts.misc.moreOptionsPopup.options.noMore.title')}
			</li>
		);
	};

	const renderOptions = () => {

		return options.map((option, index) => {
			switch (option) {
			case 'Share': {
				return <Share showPopup={() => openPopup(ShareWindow, { item })} key={index} />
			}
			case 'Observe': {
				return <Observe key={index} />
			}
			case 'ShowSimilar':
				return <ShowSimilar key={index} />;
			case 'Hide':
				return <Hide item={item}/>;
			case 'Report':
				return <Report key={index} showPopup={() => openPopup(user ? ReportViolation : Auth, user ? { item } : {type: 'login'})} />;
			case 'Close':
				return <Close key={index} />;
			case 'ArchiveConversation':
				return <ArchiveConversation key={index} />
			default:
				return null;
			}
		});
	};

	const Share = ({ showPopup }) => {

		return (
			<>
				<li className={`${liStyles}`} onClick={showPopup}>
					{t('parts.misc.moreOptionsPopup.options.share.title')}
				</li>
			</>
		);
	};
	const Observe = () => {
		if(user){
			if (((user.id === item.uid) || (user.id === item.id))) {
				return <></>;
			}
		}
		return (
			<li className={`${liStyles}`}>
				<ObserverButton isText={true} uid={item.uuid} />
			</li>
		);
	};
	const ShowSimilar = () => {
		const showSimilar = () => {
			alert('todo');
		}
		return (
			<li className={`${liStyles}`} onClick={showSimilar}>
				{t('parts.misc.moreOptionsPopup.options.showSimilar.title')}
			</li>
		);
	};
	const Report = ({showPopup}) => {
		return (
			<>
				<li className={`${liStyles}`} onClick={showPopup}>
					{t('parts.misc.moreOptionsPopup.options.report.title')}
				</li>
			</>
		);
	};
	const Close = () => {
		const closePopup = () => {
			setVisible(false);
		};

		return (
			<li className={`${liStyles}`} onClick={closePopup}>
				{t('parts.misc.moreOptionsPopup.options.close.title')}
			</li>
		);
	};
	const ArchiveConversation = () => {
		return(
			<li onClick={archiveConversation}>
				Archive chat
			</li>
		)
	}

	return (
		<ul className={'p-0'}>
			{renderOptions()}
		</ul>
	);
};

const MoreOptions = ({item, options}) => {

	const[visible, setVisible] = useState(false);

	const {isIOS} = useUser();

	const toggleVisible = () => setVisible(prevState => !prevState)

	return (
		<div className={'item-more-options'}>
			<button type="button" className={'more-options'} onClick={!isIOS ? toggleVisible : undefined} onTouchStart={isIOS ? toggleVisible : undefined}>
				<div title="Więcej opcji" className={'more-options-icon'}>
					<MoreVertical size={23}/>
				</div>
			</button>
			<CloseOutside handleClose={() => setVisible(false)}>
				<AnimatedDropdown isOpen={visible} className={'dropdown-more-options end-0'}>
					<MoreOptionsPopup visible={visible} setVisible={setVisible} item={item} options={options}/>
				</AnimatedDropdown>
			</CloseOutside>
		</div>
	)
}

export default MoreOptions;
