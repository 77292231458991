import React, {useState} from 'react';
import {Alert, Button} from 'react-bootstrap';
import {Rings} from 'react-loader-spinner';

const Initial = () => {

	const [visible, setVisible] = useState(false);

	const [code, setCode] = useState(false);

	const [loading, setLoading] = useState({
		confirm: false,
		resend: false,
	})

	const [success, setSuccess] = useState({
		confirm: false,
		resend: false,
	})

	const handleChange = (code) => {

		setCode(code);

		if(code.length === 6) {

			setVisible(true)

		}

		else {

			setVisible(false);

		}

	}
	handleChange()

	const handleResend = () => {

		setLoading(prevState => ({
			...prevState,
			resend: true
		}));

		// TODO: handle resend

	}

	const handleSubmit = () => {

		setLoading(prevState => ({
			...prevState,
			confirm: true
		}));

		// TODO: handle remove account

	}

	return(
		<>
			<h3 className={'font-xxxl'}>Confirm account removal</h3>
			<Alert variant={'info'}>
				We have received your request to delete your account. Enter in the box below 6 - the digital code that we sent to the email address associated with your account.
			</Alert>
			{visible && <Button className={'btn-has-loader d-flex mt-3'} type={'submit'} onClick={handleSubmit} disabled={loading.confirm}>
				{loading.confirm && <Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/>}
				<span>{'Confirm'}</span>
			</Button>}
			<hr/>
			<div className={'mt-4'}>
				<h3>Didnt get the code?</h3>
				{! success.resend && <Button className={'btn-has-loader btn-sm d-flex mb-3'} type={'submit'} onClick={handleResend} disabled={loading.resend}>
					{loading.resend && <Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/>}<span>{'Send again'}</span>
				</Button>}
				{success.resend && <Alert variant={'success'}>New code has been successfully sent to your email address</Alert> }
			</div>
		</>
	)
}

export default Initial;
