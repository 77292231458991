import Cropper from 'react-easy-crop';
import React, {useState} from 'react';
import getCroppedImg from "./cropImage";
import {RotateCcw, RotateCw} from 'react-feather';
import CloseIcon from "../../../../Parts/CloseIcon";
import usePopup from "../../../../../Hooks/ReduxHooks/usePopup";

const ImageCrop = ({previewUrl, setPreviewUrl, setSelectedFile}) => {
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const {closePopup} = usePopup();
	const [rotation, setRotation] = useState(0)
	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		setCroppedAreaPixels(croppedAreaPixels);
	};

	const handleCropImage = async () => {
		try {
			const croppedImage = await getCroppedImg(
				previewUrl,
				croppedAreaPixels,
				rotation
			);
			const croppedImageBlob = await fetch(croppedImage).then(res => res.blob());
			setSelectedFile(croppedImageBlob);
			if(setPreviewUrl !== null){
				setPreviewUrl(URL.createObjectURL(croppedImageBlob));
			}
			closePopup();
		} catch (e) {
			console.error(e);
		}
	};

	const cropRotate = (direction) => {
		setRotation(prevState => direction === 'left' ? prevState - 90 : prevState + 90)
	}

	return(
		<div className="">
			<CloseIcon handleClose={closePopup} additionalStyles="z-3 m-3" />
			<Cropper
				image={previewUrl}
				crop={crop}
				zoom={zoom}
				rotation={rotation}
				maxZoom={2}
				aspect={4 / 4}
				onCropChange={setCrop}
				onCropComplete={onCropComplete}
				onZoomChange={setZoom}
				zoomSpeed={0.2}
			/>
			<div className="z-3 position-absolute bottom-0 start-0 w-100 d-flex justify-content-center mb-2">
				<button onClick={() => cropRotate('right')} className="btn btn-primary btn-circle sm me-2">
					<RotateCw />
				</button>
				<button onClick={() => cropRotate('left')} className="btn btn-primary btn-circle sm me-2">
					<RotateCcw />
				</button>
				<button onClick={handleCropImage} className="btn btn-primary">
					Crop and accept
				</button>
			</div>
		</div>
	);
};

export default ImageCrop;