import { useEffect, useState } from 'react';

const useCountdown = (initialSeconds) => {
	const [timeLeft, setTimeLeft] = useState(0);
	const [isTimeoutRemaining, setIsTimeoutRemaining] = useState(true);

	useEffect(() => {
		let intervalId;

		if (timeLeft > 0) {
			intervalId = setInterval(() => {
				setTimeLeft(prevTimeLeft => {
					const newTimeLeft = Math.max(0, prevTimeLeft - 1);
					if (newTimeLeft === 0) {
						setIsTimeoutRemaining(true);
						clearInterval(intervalId);
					}
					return newTimeLeft;
				});
			}, 1000);
		}

		return () => clearInterval(intervalId);
	}, [timeLeft]);

	const startCountdown = (seconds) => {
		setIsTimeoutRemaining(false);
		setTimeLeft(seconds);
	};

	const displayTimeLeft = () => {
		let minutesLeft = Math.floor(timeLeft / 60);
		let secondsLeft = timeLeft % 60;
		minutesLeft = minutesLeft.toString().padStart(2, '0');
		secondsLeft = secondsLeft.toString().padStart(2, '0');
		return `${minutesLeft}:${secondsLeft}`;
	};

	return { startCountdown, timeLeft: displayTimeLeft(), isTimeoutRemaining };
};

export default useCountdown;
