import React from 'react';
import {Outlet} from 'react-router-dom';
import '@algolia/autocomplete-theme-classic/dist/theme.css';
import usePopup from "../../Hooks/ReduxHooks/usePopup";
import Header from '../Layout/Header/Header';
import Footer from '../Layout/Footer/Footer';
import {useTranslation} from 'react-i18next';
import {AlertCircle} from 'react-feather';

const Default = () => {

	const {popupContent} = usePopup()

	return (
		<>
			<Header/>
			<Outlet/>
			<Footer/>
			{popupContent}
		</>
	);
};

const Offline = ({Message, Refresh}) => {

	const {t} = useTranslation();

	return (
		<div className={"main-error"}>
			<AlertCircle size={100}/>
			<h3>Error</h3>
			<p>
				{Message}
			</p>
			<button className={'btn btn-outline-secondary'} onClick={Refresh}>{t('main.offline.tryAgain')}</button>
		</div>
	);

}

export {
	Default,
	Offline
};
