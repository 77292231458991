import React from "react";

const ImagesSkeletonItem = () => {
	return (
		<div className="gallery-item">
			<div className={'gallery-item-wrapper'}>
				<div className={'skeleton'}>
				</div>
			</div>

		</div>
	);
};

const ImagesSkeleton = () => {
	return Array.from({ length: 6 }).map((_, index) => (<ImagesSkeletonItem key={index} />));
};

export default ImagesSkeleton;
