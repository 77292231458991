import React, {useState} from 'react';
import {Alert, Button} from 'react-bootstrap';
import {Rings} from 'react-loader-spinner';
import useUser from "../../../../Hooks/ReduxHooks/useUser";

const Deleted = () => {

	const [loading, setLoading] = useState();

	const {user} = useUser();

	const handleSubmit = () => {

		setLoading(true);

		// TODO: handle cancel delete account

	}

	return(
		<>
			<h3 className={'font-xxxl'}>Your account is being deleted.</h3>
			<Alert variant={'info'}>
				Your account will be deleted on {user.deleted_at}.
			</Alert>
			<Button className={'btn-has-loader d-flex mt-3'} type={'submit'} onClick={handleSubmit} disabled={loading}>
				{loading && <Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/>}
				<span>{'Cancel'}</span>
			</Button>

		</>
	)
}

export default Deleted;
