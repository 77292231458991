import {X} from 'react-feather';
import React from 'react';

const CloseIcon = ({handleClose, additionalStyles}) => {
	return(
		<button className={`close-icon btn-circle sm position-absolute top-0 end-0 ${additionalStyles}`} onClick={handleClose}>
			<X size={18}/>
		</button>
	)
}

export default CloseIcon;