import {TooltipHelper} from "../../../Parts/Tooltips";
import { TbAlertCircleFilled } from "react-icons/tb";
import React from "react";
import useError from "../../../../Hooks/ReduxHooks/useError";
import ValidationError from "../../../Parts/ValidationError";

const ProfileError = ({errorValue, additionalStyles, type, profileError}) => {
	const {indexStatusValue} = useError();

	if((!errorValue || errorValue.length === 0 || indexStatusValue !== 'rejected') && !profileError){
		return
	}

	return(
		<>
			<TooltipHelper icon={TbAlertCircleFilled} className={`${additionalStyles} red`}>
				{errorValue &&
					<>
						{errorValue.map((item, index) => (
							<div key={index}>
								{type === 'details' &&
									<>
										{item.value}

										<ul className="list-inline">
											{item?.errors.map((error, index) => (
												<li key={index} className="text-nowrap">
													{error}
												</li>
											))}
										</ul>

									</>
								}
								{(type === 'gallery' || type === 'thumbnail') &&
									<>
										{item}
									</>
								}
							</div>
						))}
					</>
				}
				{profileError && <ValidationError isErrorVisible={!!(profileError)} errorMessage={profileError} additionalClass="m-0 text-center" guideID="profile-validation-name-form-guide" />}
			</TooltipHelper>
		</>
	)
}

export default ProfileError;