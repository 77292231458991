// errorSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import callToAPI from "../../api";

export const fetchProfileStatus = createAsyncThunk(
	'error/fetchProfileStatus',
	async (_, { rejectWithValue, getState }) => {
		const { user } = getState();
		const defaultIndexStatusValue = user.indexStatus;

		try {
			const response = await callToAPI({
				url: '/profile/status',
				method: 'get',
			});
			return { ...response, defaultIndexStatusValue };
		} catch (err) {
			return rejectWithValue(err.response.data);
		}
	}
);

const errorSlice = createSlice({
	name: 'error',
	initialState: {
		galleryError: [],
		thumbnailError: [],
		detailsError: [],
		indexStatusValue: 'initial',
		isValid: true,
	},
	reducers: {
		setIndexStatusValue: (state, action) => {
			state.indexStatusValue = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(fetchProfileStatus.fulfilled, (state, action) => {
			state.isValid = action.payload.valid;
			state.galleryError = action.payload.gallery;
			state.thumbnailError = action.payload.thumbnail;
			state.detailsError = action.payload.details;
			state.indexStatusValue = action.payload.defaultIndexStatusValue;
		});
	},
});

export const { setIndexStatusValue } = errorSlice.actions;
export default errorSlice.reducer;
