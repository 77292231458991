import React, {useEffect, useState} from "react";
import Gallery from './Gallery';
import Tabs from './Tabs';
import useApi from "../../../Hooks/useApi";
import './Assets/edit.scss';
import './Assets/edit-media-query.scss';
import Joyride from "react-joyride";
import useGuide from "../../../Hooks/useGuide";

const Index = () => {

	const isGallerySeparately = window.innerWidth < 991;

	const [data, setData] = useState();

	const [loading, setLoading] = useState(true);

	const {request} = useApi();

	/**
	 *
	 * Fetch user details
	 *
	 */

	useEffect(() => {
		const fetchData = () => {

			request({
				method: 'get',
				url: '/profile/details',
				onSuccess: data => {
					setData(
						{
							...data,
						});
				}
			}).finally(() => setLoading(false));

		};

		return fetchData();

	}, []);

	// const stepConfig = [
	// 	{
	// 		content: 'Samouczek uzupełniania profilu',
	// 		placement: 'center',
	// 		target: 'body',
	// 	},
	// 	{
	// 		target: '#profile-thumbnail-guide',
	// 		content: 'Tutaj możesz dodać swój film/zdjęcie wyróżniające.',
	// 		placement: 'top',
	// 	},
	// 	{
	// 		target: '#gallery-item-guide',
	// 		content: 'Tutaj możesz dodać zdjęcie do galerii.',
	// 		placement: 'top',
	// 	},
	// 	{
	// 		target: '#profile-name-form-guide',
	// 		content: 'Uzupełniaj swoje dane',
	// 		placement: 'top',
	// 		spotlightClicks: true,
	// 		disableBeacon: true,
	// 		disableOverlayClose: true,
	// 		hideCloseButton: true,
	// 	},
	// ]

	// const {Guide, handleRunGuide, setRun} = useGuide(stepConfig)
	return (
		<section className={`profile-edit`}>
			{/*<button onClick={handleRunGuide}>Run</button>*/}
			{/*<Guide />*/}
			{!isGallerySeparately && <Gallery/>}
			<Tabs data={data} setData={setData} loading={loading} setLoading={setLoading}/>
		</section>
	);

};

export default Index;
