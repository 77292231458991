import {Formik} from "formik";
import {Button, Form} from "react-bootstrap";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {Rings} from "react-loader-spinner";
import VerificationInput from "react-verification-input";
import {confirmUserAttribute, sendUserAttributeVerificationCode, updatePassword} from "aws-amplify/auth";
import Notification from "../../../../utils/NotificationUtils";
import * as Yup from "yup";
import ProfileError from "../../Edit/Parts/ProfileError";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import { FaLock } from "react-icons/fa";
import {TooltipHelper} from '../../../Parts/Tooltips';
import useCountdown from "../../../../Hooks/useCountdown";
import {useNavigate} from "react-router";
import TogglePassword from "../../../Parts/TogglePassword";
const ChangePassword = () => {
	const {user, handleLogout} = useUser();
	const [passwordInvalid, setPasswordInvalid] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const { t } = useTranslation();
	const { startCountdown, timeLeft, isTimeoutRemaining } = useCountdown();
	const [loading, setLoading] = useState(false)
	const isServiceActive = user.loginType === 'User'
	const [changePasswordVisible, setChangePasswordVisible] = useState(false);
	const [isCodeVisible, setIsCodeVisible] = useState(false);
	const [codeValue, setCodeValue] = useState();
	const [isCodeComplete, setIsCodeComplete] = useState()
	const [isCodeValid, setIsCodeValid] = useState(true)

	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleToggle = () => {
		setPasswordVisible(prev => !prev);
	};

	const navigate = useNavigate();

	const handleComplete = (code) => {
		setCodeValue(code)
		setIsCodeComplete(true)
	}

	const handleChangeCode = () => {
		setIsCodeComplete(false)
		setIsCodeValid(true)
	}

	const initialData = {
		currentPassword: '',
		newPassword: '',
		passwordConfirmation: ''
	}

	const handleSubmit = async (values) => {
		if (!codeValue) {
			return;
		}
		setLoading(true);
		try {
			await confirmUserAttribute({
				userAttributeKey: 'email',
				confirmationCode: codeValue
			});
			await updatePasswordHandler(values);
			setIsCodeVisible(false)
			await handleLogout()
			navigate('/?auth-popup=login')
		} catch (error) {
			setErrorMessage(error.message);
			setPasswordInvalid(true);
		} finally {
			setLoading(false);
		}
	}

	const updatePasswordHandler = async (values) => {
		try {
			await updatePassword({ oldPassword: values.currentPassword, newPassword: values.newPassword });
			Notification.success(t('profile.profile.tabs.settingsTab.changePasswordForm.notification.text'), t('profile.profile.tabs.settingsTab.changePasswordForm.notification.save'));
			values.currentPassword = '';
			values.newPassword = '';
			values.passwordConfirmation = '';
		} catch (err) {
			setErrorMessage(err.message);
			setPasswordInvalid(true);
		}
	};

	const sendCode = async () => {
		setLoading(true);
		try {
			await sendUserAttributeVerificationCode({ userAttributeKey: 'email' });
			setIsCodeVisible(true);
		} catch (err) {
			setErrorMessage(err.message);
		} finally {
			setLoading(false);
		}
	};

	const sendCodeAgain = async () => {
		await sendCode();
		startCountdown(20);
	}

	return(
		<>
			{(passwordInvalid || errorMessage.length !== 0) && <p className="error-message">{errorMessage}</p>}
			<Formik initialValues={initialData} onSubmit={handleSubmit} validationSchema={Yup.object().shape({
				currentPassword: Yup.string().required(t('profile.profile.tabs.settingsTab.changePasswordForm.validations.currentPassword.required')),
				newPassword: Yup.string().required(t('profile.profile.tabs.settingsTab.changePasswordForm.validations.newPassword.required')).min(8, t('profile.profile.tabs.settingsTab.changePasswordForm.validations.newPassword.min')).matches(/^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})((?=.*[A-Z]){1}).*$/, t('profile.profile.tabs.settingsTab.changePasswordForm.validations.newPassword.matches')),
				passwordConfirmation: Yup.string().oneOf([Yup.ref('newPassword'), null], t('profile.profile.tabs.settingsTab.changePasswordForm.validations.passwordConfirmation.oneOf')).required(t('profile.profile.tabs.settingsTab.changePasswordForm.validations.passwordConfirmation.required')),
			})}>
				{({handleSubmit, values, setFieldValue, handleChange, handleBlur, errors, touched}) => (
					<Form onSubmit={handleSubmit}>
						{isCodeVisible ?
							<>
								{!isCodeValid && <p className="error-message text-left">The code is incorrect</p>}
								<div>
									<p>
										{isTimeoutRemaining ?
											<>
												Didnt you get the code? <span onClick={sendCodeAgain} className="cursor-pointer text-decoration-underline">Resend</span>
											</>
											:
											<>
												Resend code again in {timeLeft}
											</>
										}
									</p>
								</div>
								<VerificationInput autoFocus onChange={handleChangeCode} onComplete={handleComplete} validChars="0-9" inputProps={{ inputMode: "numeric" }} placeholder="" classNames={{character: "character", container: "container", characterSelected: "character-selected",}} />
								<button disabled={!isCodeComplete} type='submit' className="btn btn-primary mt-4 w-100">
									Confirm
								</button>
							</>
							:
							<>
								<Form.Group className={'mb-4'}>
									<div className="position-relative">
										<Form.Label className={`${isServiceActive ? '' : 'bg-transparent'}`}>Current Password</Form.Label>
										<Form.Control disabled={!isServiceActive} placeholder={'Current Password'} className={errors.currentPassword ? 'border border-danger' : ''} type={passwordVisible ? "text" : "password"} name="currentPassword" value={values.currentPassword} onChange={handleChange} onBlur={handleBlur} readonly={changePasswordVisible ? false : 'readonly'} />
										<ProfileError profileError={errors.currentPassword} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-5'} />
										{isServiceActive ?
											<>
												{changePasswordVisible ?
													<TogglePassword passwordVisible={passwordVisible} onToggle={handleToggle} />
													:
													<button type='button' onClick={() => {
														setFieldValue('currentPassword', '');
														setChangePasswordVisible(prevState => !prevState)
													}} className="position-absolute top-50 end-0 translate-middle-y me-3 bg-white border-0 text-primary text-decoration-underline font-md">
														change
													</button>
												}
											</>
											:
											<>
												<TooltipHelper className={'position-absolute top-50 end-0 translate-middle-y me-3'} icon={FaLock}>
													<p className={'mb-0'}>
														Change of email blocked
													</p>
												</TooltipHelper>
											</>
										}
									</div>
									{changePasswordVisible &&
										<>
											<Form.Group className={'mb-4 mt-4'}>
												<div className="position-relative">
													<Form.Label>{t('profile.profile.tabs.settingsTab.changePasswordForm.newPassword.label')}</Form.Label>
													<Form.Control placeholder={t('profile.profile.tabs.settingsTab.changePasswordForm.newPassword.placeholder')} className={errors.newPassword ? 'border border-danger' : ''} type={passwordVisible ? "text" : "password"} name="newPassword" onChange={handleChange} onBlur={handleBlur} value={values.newPassword} />
													<ProfileError profileError={errors.newPassword} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-3'} />
												</div>
											</Form.Group>
											<Form.Group className={'mb-4'}>
												<div className="position-relative">
													<Form.Label>{t('profile.profile.tabs.settingsTab.changePasswordForm.passwordConfirmation.label')}</Form.Label>
													<Form.Control placeholder={t('profile.profile.tabs.settingsTab.changePasswordForm.passwordConfirmation.placeholder')} className={errors.passwordConfirmation ? 'border border-danger' : ''} type={passwordVisible ? "text" : "password"} name="passwordConfirmation" onChange={handleChange} onBlur={handleBlur} value={values.passwordConfirmation} />
													<ProfileError profileError={errors.passwordConfirmation} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-3'} />
												</div>
											</Form.Group>
											<Button className={'btn-has-loader d-flex w-100'} type={'button'} onClick={sendCode} disabled={loading || (errors.currentPassword || errors.newPassword || errors.passwordConfirmation)}>
												{loading &&
													<Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/>}
												<span>Change Password</span>
											</Button>
										</>
									}
								</Form.Group>
							</>
						}

					</Form>
				)}
			</Formik>
		</>
	)
}

export default ChangePassword;