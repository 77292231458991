import {isValidUrl} from "../../../../utils/AppUtils";
import {Facebook, Instagram, Link, Linkedin} from "react-feather";

const DisplayIcon = ({url}) => {

	const DefaultIcon = <Link/>

	const iconsConfig = {
		'facebook.com' : <Facebook size={26}/>,
		'linkedin.com' : <Linkedin size={26}/>,
		'instagram.com' : <Instagram size={26}/>,
	}

	const getHost = url => {

		if(!isValidUrl(url)) {

			return null;

		}

		const parse = new URL(url);

		return parse.host.replace(/^www\./, '');

	}

	const host = getHost(url);

	if(host !== null && iconsConfig[host]) {

		return <>{iconsConfig[host]}</>;

	}
	else {

		return <>{DefaultIcon}</>;

	}

}

const DisplayIcons = ({items}) => {

	if(Array.isArray(items)) {

		return (
			<div>
				{
					items.map((url, index) => {

						return (
							<a className={'ms-2 me-2'} rel="noreferrer" href={url} key={index} target={'_blank'}>
								<DisplayIcon url={url}/>
							</a>
						)

					})
				}
			</div>
		)

	}

	return <></>

}

const DisplayName = ({item}) => {

	if(item.name === '' || item.name === null) {

		item.name = 'No name..'

	}

	return(
		<div className="d-flex justify-content-between align-items-center name-popup mt-1">
			<h2 className="mb-0">{item.name}</h2>
			<div>
				<DisplayIcons items={item.social}/>
			</div>
		</div>
	)
}

export default DisplayName;
