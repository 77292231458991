import React, {Component, Suspense, useEffect} from 'react';
import './assets/main.scss';
import 'simplebar-react/dist/simplebar.min.css';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';
import {Amplify} from 'aws-amplify';
import AmplifyConfig from './Config/AmplifyConfig';
import routes from './routes';
import AlgoliaConfig from './Config/AlgoliaConfig';
import {InstantSearch} from 'react-instantsearch';
import useConfig from './Hooks/ReduxHooks/useConfig';
import useUser from './Hooks/ReduxHooks/useUser';
import {Offline} from './Components/Layout/Layouts';
import {Head} from './Components/Layout/Page';
import {AppLoader} from './Components/Parts/Loaders';
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import {useWebSocket} from "./Hooks/ReduxHooks/useWebSocket";
import notificationSound from "./assets/mp3/notification.mp3";
import useMessage from "./Hooks/ReduxHooks/useMessage";
Amplify.configure(AmplifyConfig);

const router = createBrowserRouter(routes);

const Wrapper = ({ children }) => {

	const config = useConfig();
	const user = useUser();

	const handleWindowSizeChange = () => {

		if (window.innerWidth <= 991) {

			user.handleSetIsMobile(true);

		} else {

			user.handleSetIsMobile(false);

		}

	};

	useEffect(() => {

		config.loadConfig();

		window.addEventListener('resize', handleWindowSizeChange);

		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};

	}, []);

	useEffect(() => {

		/**
		 * Handle user if page is not offline and config loading is false
		 */
		if(!config.offline || !config.loading) {

			user.handleRefresh();

		}

	}, [config.loading]);

	/**
	 *
	 */

	if (config.loading || user.loading) {
		return <AppLoader/>;
	}

	/**
	 *
	 */

	if(config.offline) {
		return <Offline Message={'Page offline'} Refresh={() => config.loadConfig()} />;
	}

	return (
		<>
			<Head/>
			<div className={`main-page`}>
				{children}
			</div>
		</>
	);

};

const MessageWrapper = ({children}) => {

	const sound = new Audio(notificationSound)

	const {chatID,handleSetUnreadCount, unreadCount} = useMessage();

	const {register, unregister, socket} = useWebSocket();

	const {user} = useUser();

	useEffect(() => {
		const handleNewMessage = (data) => {
			if(data.data.senderId !== chatID && data.data.senderId !== user.id){
				sound.play()
				handleSetUnreadCount(unreadCount + 1);
			}
		}
		register('newMessage', handleNewMessage);
		return () => {
			unregister('newMessage', handleNewMessage);
		};
	}, [socket, user, chatID]);

	return(
		<>
			{children}
		</>
	)
}

const App = () => {
	return (
		<Suspense fallback={<AppLoader/>}>
			<Wrapper>
				<MessageWrapper>
					<ToastContainer />
					<InstantSearch stalledSearchDelay={3000} indexName={AlgoliaConfig.index.main} searchClient={AlgoliaConfig.client} routing={AlgoliaConfig.routing} future={{preserveSharedStateOnUnmount: true}}>
						<RouterProvider router={router}/>
					</InstantSearch>
				</MessageWrapper>
			</Wrapper>
		</Suspense>
	);
}

export default App;
