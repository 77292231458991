import React, {useEffect, useRef, useState} from "react";

const ProgressBar = ({duration, forwardRef}) => {

	const [progress, setProgress] = useState(0);

	const [focused, setFocused] = useState(0);

	const [pointer, setPointer] = useState(0);

	const [touchMoveActive, setTouchMoveActive] = useState(false)

	const ProgressRef = useRef();

	/**
	 *
	 *
	 * Update progressbar
	 *
	 */

	useEffect(() => {

		const video = forwardRef.current;

		if(duration == null) {

			duration = video.duration;

		}

		const updateTime = () => {
			const value = (100000 / duration) * video.currentTime;

			setProgress(value);

		};

		forwardRef.current.addEventListener("timeupdate", updateTime);

		return () => {

			if(forwardRef.current !== null) {

				forwardRef.current.removeEventListener("timeupdate", updateTime);

			}

		};

	}, []);

	/**
	 *
	 * @param event
	 */

	const handleMouseMove = (event) => {

		const element = ProgressRef.current;

		if (element) {

			const left = event.clientX - element.getBoundingClientRect().left;

			setPointer(left)

		}

	}

	const handleMouseOver = () => {

		setFocused(true);

	}

	const handleMouseOut = () => {

		setFocused(false);

	}

	const handleClick = (event) => {
		if(touchMoveActive){
			return
		}

		const element = ProgressRef.current;

		if (element) {

			const newPointer = window.innerWidth > 767 ? pointer : event.clientX - element.getBoundingClientRect().left

			const percentOfClick = newPointer/element.offsetWidth;

			forwardRef.current.currentTime = (duration / 1000 * percentOfClick)
		}

	}

	const handleTouchMove = (event) => {
		setTouchMoveActive(true)
		const element = ProgressRef.current;
		if (element) {

			const left = event.changedTouches[0].pageX - element.getBoundingClientRect().left;

			const percentOfClick = left/element.offsetWidth;

			forwardRef.current.currentTime = duration * percentOfClick;

		}

	}

	const handleTouchEnd = () => {
		setTouchMoveActive(false)
		setFocused(false);
	}

	return (
		<>
			<div className={'progressBar'} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} onClick={handleClick} onMouseMove={handleMouseMove} ref={ProgressRef} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
				<div className={'progressInner'} style={{ width: `${progress}%` }}></div>
				<div className={'progressPointer'} style={{ left: `${focused ? 0 : progress}%`, transform: `translateX(${focused ? pointer : -6}px)`}}></div>
			</div>
		</>

	)

}
export default ProgressBar;
