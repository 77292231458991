import AuthHeader from "../Parts/AuthHeader";
import AuthFooter from "../Parts/AuthFooter";
import ResetForm from "./ResetForm";

const Reset = ({setAuthType}) => {

	return(
		<div>
			<AuthHeader title="New password" subtitle="Enter new password" />
			<ResetForm setAuthType={setAuthType} />
			<AuthFooter />
		</div>
	)
}

export default Reset;