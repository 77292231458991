import React, {useState} from "react";
import {Image as ImageIcon} from 'react-feather';
import imagePlaceholder from '../../../assets/images/avatar_placeholder.png';
import {Loader} from '../../Parts/Loaders';

const Image = ({src, isError}) => {

	const [loading, setLoading] = useState(true);

	if(!src) {

		return <img src={imagePlaceholder} alt={imagePlaceholder}/>

	}

	return (
		<div className={`image-full ${isError ? 'border border-danger' : ''} ${loading ? 'loading' : 'loaded'}`}>
			<Loader/>
			<img src={src} alt={src} onLoad={() => setLoading(false)}/>
			<ImageIcon className="image-icon d-none" size={24} />
		</div>
	);

};

export default Image;
