import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from 'react-i18next';
import {Plus, X} from 'react-feather';
import {Page} from '../../../../Layout/Page';
import {useFormikContext} from 'formik';
import {Col, Form, Row} from 'react-bootstrap';
import CommunicationWays from '../../Common/CommunicationWays';
import TabCategory from './TabCategory';
import {TooltipHelper} from '../../../../Parts/Tooltips';
import useUser from "../../../../../Hooks/ReduxHooks/useUser";
import useError from "../../../../../Hooks/ReduxHooks/useError";
import ProfileError from "../../Parts/ProfileError";
import { FaLock } from "react-icons/fa";
import {Link} from "react-router-dom";
const FormItems = ({item}) => {

	const {values, errors, handleBlur,handleChange} = useFormikContext();

	const {detailsError} = useError()

	const textareaRef = useRef(null);

	useEffect(() => {
		if (textareaRef?.current?.style) {
			textareaRef.current.style.height = 'auto';
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [values.about]);

	return(
		<>
			{item.key === 'name' &&
				<Col className="mb-4">
					<Form.Group className="position-relative">
						<Form.Label className="d-flex align-items-center font-family-open-sans">{item.label}</Form.Label>
						<Form.Control id={`profile-name-form-guide`} className={`${detailsError.name || errors.name ? 'border border-danger' : ''}`} placeholder={item.placeholder} name={item.key} type="text" onChange={handleChange} onBlur={() => handleBlur} value={values[item.key]}/>
						<ProfileError errorValue={detailsError.name} additionalStyles="me-3 position-absolute end-0 top-50 translate-middle-y" type="details" profileError={errors.name} />
					</Form.Group>
				</Col>
			}
			{item.key === 'about' &&
				<Form.Group className="position-relative">
					<Form.Label className="d-flex align-items-center">{item.label}</Form.Label>
					<Form.Control ref={textareaRef} className={`${detailsError.about || errors.about ? 'border border-danger' : ''} overflow-hidden`} id="profile-about-form-guide" as="textarea" placeholder={item.placeholder} name={item.key} type="text" onChange={handleChange} onBlur={() => handleBlur} value={values[item.key]}/>
					<ProfileError errorValue={detailsError.about} additionalStyles="me-4 mb-3 position-absolute end-0 bottom-0" type="details" profileError={errors.about} />
				</Form.Group>
			}
		</>
	)
}

const SocialMedia = ({item, planID}) => {
	const [isBlocked, setIsBlocked] = useState({status: false, text: '', getPremium: false})
	const freeLinkLimit = 0;
	const premiumLinkLimit = 5;
	const { t } = useTranslation();
	const {values, setFieldValue} = useFormikContext();
	const socialRef = useRef()
	const [error, setError] = useState()
	const urlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
	const addSocialMedia = () => {
		if(socialRef.current.value.trim() === '' || values.social.find((element) => element.trim() === socialRef.current.value.trim())){
			setError(t('profile.profile.edit.schemaValidation.social.incorrect'))
			return
		}
		else if(values.social.length >= 5){
			setError(t('profile.profile.edit.schemaValidation.social.max'))
			return
		}
		else if(!urlRegex.test(socialRef.current.value)){
			setError('Incorrect link structure');
			return;
		}
		setFieldValue('social', values.social.concat(socialRef.current.value.toLowerCase()))
		socialRef.current.value = ''
	}

	const handleRemoveSocialMedia = (el) => {
		const newSocialMediaArray = values.social.filter((social) => social !== el)
		setFieldValue('social', newSocialMediaArray)
	}

	const handleKeyDown = (event) => {
		if(event.keyCode === 13){
			addSocialMedia()
		}
	}

	useEffect(() => {
		if(values.social.length >= freeLinkLimit && planID === 'free'){
			setIsBlocked({status: true, text: 'Social media links limit reached in free plan', getPremium: true})
		}
		else if(values.social.length >= premiumLinkLimit){
			setIsBlocked({status: true, text: 'Social media links limit reached in premium plan 5/5', getPremium: false})
		}
	}, [values.social]);

	return(
		<div className="mt-4">
			<div className="d-flex mb-2">
				<Form.Group className="position-relative w-100">
					<Form.Label className={`d-flex align-items-center ${isBlocked.status ? 'bg-transparent' : ''}`}>{item.label}</Form.Label>
					<Form.Control disabled={isBlocked.status} onChange={() => setError()} className={error ? 'border border-danger h-100' : 'h-100'} placeholder={item.placeholder} ref={socialRef} name={item.key} type="text" onKeyDown={handleKeyDown}/>
					<ProfileError profileError={error} additionalStyles={'position-absolute end-0 top-50 translate-middle-y me-2'} />
					{isBlocked.status &&
						<TooltipHelper className={'position-absolute top-50 end-0 me-3 translate-middle-y'} icon={FaLock}>
							<div>
								<p className={'mb-0'}>
									{isBlocked.text}
								</p>
								{isBlocked.getPremium &&
									<Link className={'btn btn-primary ps-2 pe-2 font-sm pt-1 pb-1 mt-1 mb-1'} to={'/premium'}>Get Premium</Link>
								}
							</div>
						</TooltipHelper>
					}
				</Form.Group>
				<button disabled={isBlocked.status} onClick={addSocialMedia} type="button" className="btn btn-primary btn-circle md flex-shrink-0 ms-2" title={t('profile.edit.tabs.common.locations.add')}>
					<Plus />
				</button>
			</div>
			<div className="d-flex mt-3 flex-wrap">
				{values.social.map((el, index) =>
					<div className="me-2 mt-2 pt-2 pb-2 ps-3 pe-3 font-lg bg-gray rounded-5 mw-100 d-inline-block text-break" key={index}>
						{el}
						<X className={'ms-1 cursor-pointer'} size={14} onClick={() => handleRemoveSocialMedia(el)}/>
					</div>
				)}
			</div>
		</div>
	)
}

const TabBasic = ({type}) => {

	const {t} = useTranslation();

	const {values} = useFormikContext();

	const {user} = useUser();

	const waysConfiguration = [
		{
			name: 'phone',
			title: t('profile.profile.edit.waysConfiguration.phone.title'),
			desc: <TooltipHelper>{t('profile.profile.edit.waysConfiguration.phone.desc')}</TooltipHelper>,
			defaultChecked: values.contact_methods.phone !== null,
			initialValue: values.contact_methods.phone !== null ? values.contact_methods.phone : 'I don\'t want to provide...',
			defaultValue: values.contact_methods.phone ? values.contact_methods.phone : null,
			inputDisabled: values.contact_methods.phone === null,
			hasInput: true,
		},
		{
			name: 'email',
			title: t('profile.profile.edit.waysConfiguration.email.title'),
			desc: <TooltipHelper>{t('profile.profile.edit.waysConfiguration.email.desc')}</TooltipHelper>,
			defaultChecked: values.contact_methods.email !== null,
			initialValue: values.contact_methods.email !== null ? values.contact_methods.email : t('profile.profile.edit.waysConfiguration.dontShow'),
			defaultValue: user.email,
			inputDisabled: values.contact_methods.phone === null,
			hasInput: true,
		},
		{
			name: 'direct',
			title: t('profile.profile.edit.waysConfiguration.direct.title'),
			desc: <TooltipHelper>{t('profile.profile.edit.waysConfiguration.direct.desc')}</TooltipHelper>,
			defaultChecked: values.contact_methods.direct,
			hasInput: false,
		},
		{
			name: 'is_visible',
			title: t('profile.profile.edit.waysConfiguration.visible.title'),
			desc: <TooltipHelper>{t('profile.profile.edit.waysConfiguration.visible.tooltipExplanation')}</TooltipHelper>,
			defaultChecked: values.is_visible,
			hasInput: false,
		}
	];

	const TabBasicConfiguration = [
		{
			key: 'name',
			label: type === 'employer' ? t('profile.profile.basic.companyName.label') : t('profile.profile.basic.name.label'),
			placeholder: type === 'employer' ? t('profile.profile.basic.companyName.placeholder') : t('profile.profile.basic.name.placeholder'),
		},
		{
			key: 'about',
			label: t('profile.profile.basic.description.label'),
			placeholder: t('profile.profile.basic.description.placeholder')
		},
		{
			key: 'social',
			label: t('profile.profile.basic.socialMedia.label'),
			placeholder: t('profile.profile.basic.socialMedia.placeholder')
		}
	]

	return (
		<>
			<Page title={`${t('pageTitles.updateBasicInformation')}`}>
				<div className={'profile-edit-basic'}>
					<h3 className="title">{t('profile.profile.basic.title')}</h3>
					<Row className={'mt-4 d-flex basic-form'}>
						{TabBasicConfiguration.map((item, index) => <FormItems key={index} item={item} />)}
					</Row>
				</div>
				<TabCategory />
				<CommunicationWays waysConfiguration={waysConfiguration}/>
				<div className="basic-form">
					<SocialMedia item={TabBasicConfiguration[TabBasicConfiguration.length - 1]} planID={user.plan_id} />
				</div>
			</Page>
		</>

	);

};

export default TabBasic;
