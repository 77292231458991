import {Formik} from "formik";
import {Form} from "react-bootstrap";
import ValidationError from "../../Parts/ValidationError";
import {Rings} from "react-loader-spinner";
import {signIn} from "aws-amplify/auth";
import React, {useState} from "react";
import Notification from "../../../utils/NotificationUtils";
import {useTranslation} from "react-i18next";
import AuthBySocialMedia from "../Parts/AuthBySocialMedia";
import * as Yup from "yup";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";
import ProfileError from "../../Profile/Edit/Parts/ProfileError";
import useQueryString from "../../../Hooks/useQueryString";
import TogglePassword from "../../Parts/TogglePassword";

const LoginForm = ({setAuthType}) => {

	const {t} = useTranslation();

	const [loading, setLoading] = useState(false);

	const { removeQuery } = useQueryString();

	const {handleRefresh} = useUser();

	const {closePopup} = usePopup();

	const [error, setError] = useState({
		status: false,
		text: ''
	});

	const initialValuesData = {
		email: '',
		password: ''
	};

	const [passwordVisible, setPasswordVisible] = useState(false);

	const handleToggle = () => {
		setPasswordVisible(prev => !prev);
	};

	const handleSubmit = async(values) => {
		setLoading(true);
		try {
			const {isSignedIn} = await signIn({username: values.email, password: values.password});

			if(isSignedIn) {

				setLoading(false);

				try {

					await handleRefresh();

					Notification.success(t('auth.login.loginForm.notification.text'), t('auth.login.loginForm.notification.title'));

					closePopup();

					removeQuery('auth-popup')

				} catch(err) {

					console.log("getCurrentUser error: ", err);

				}

			}

		} catch(error) {
			setError({
				status: true,
				text: error.message
			});
		}

		setLoading(false);

	};

	return (
		<Formik validateOnChange={false} validateOnBlur={false} initialValues={initialValuesData} onSubmit={handleSubmit} validationSchema={Yup.object().shape({
			email: Yup.string().required(t('auth.login.loginForm.validation.email.required')).email(t('auth.login.loginForm.validation.email.email')),
			password: Yup.string().required(t('auth.login.loginForm.validation.password.required'))
		})}>
			{({errors, touched, handleSubmit, values, handleChange, handleBlur}) => (
				<Form onSubmit={handleSubmit} className="auth-form">
					<div>
						<span className="font-weight-lg font-lg">
							by E-mail
						</span>
						<Form.Group id="email" className="my-3 position-relative">
							<Form.Control className={`${errors.email ? 'border border-danger' : 'border border-white'}`} type="email" name="email" value={values.email} placeholder="E-mail" onChange={handleChange} onBlur={handleBlur}/>
							<ProfileError profileError={errors.email} additionalStyles={'me-3 position-absolute end-0 top-50 translate-middle-y'} />
						</Form.Group>
						<Form.Group id="password">
							<div className="position-relative">
								<Form.Control className={`${errors.password ? 'border border-danger' : 'border border-white'}`} type={passwordVisible ? "text" : "password"} name="password" value={values.password} placeholder="Password" onChange={handleChange} onBlur={handleBlur}/>
								<ProfileError profileError={errors.password} additionalStyles={'me-5 position-absolute end-0 top-50 translate-middle-y'} />
								<TogglePassword passwordVisible={passwordVisible} onToggle={handleToggle} />
							</div>
						</Form.Group>
					</div>
					<button disabled={loading} className="btn btn-primary w-100 mt-3 font-lg auth-submit-button d-flex justify-content-center" type={'submit'}>
						{!loading && <>Sign in</>}
						{loading &&
							<Rings height="28" width="28" color="#fff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
					</button>
					<div className={'mt-1 d-flex justify-content-end'}>
						<button type="button" onClick={() => setAuthType('forgot')} className="font-sm text-decoration-underline text-primary bg-transparent border-0">
							Forgot your password?
						</button>
					</div>
					<ValidationError isErrorVisible={error.status} errorMessage={error.text} additionalClass={'position-absolute'}/>
					<AuthBySocialMedia/>
				</Form>

			)}
		</Formik>
	);
};

export default LoginForm;