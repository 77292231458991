import React from "react";
import PrevLocationBack from '../../Parts/PrevLocationBack';

const SingleHeaderLeft = () => {

	return (
		<div className={'d-flex align-items-center'}>
			<PrevLocationBack text={true} />
		</div>
	);
};

export default SingleHeaderLeft;
