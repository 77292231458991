import {Button, Form} from "react-bootstrap";
import React, {useRef} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import Notification from '../../../utils/NotificationUtils';
import useItem from '../../../Hooks/useItem';
import CloseOutside from '../CloseOutside';
import CloseIcon from '../CloseIcon';
import {BsFacebook, BsLinkedin, BsReddit, BsTwitterX, BsWhatsapp} from "react-icons/bs";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";

const SocialMediaLink = ({item, url}) => {

	return(
		<Link to={`${item.link}${url}`} target="_blank">
			{item.icon}
		</Link>
	)

}

const ShareWindowLinks = ({url, config}) => {

	return config.map((item, index) => <SocialMediaLink key={index} item={item} url={url}/>)

}

const ShareWindow = ({item}) => {

	const {closePopup} = usePopup();

	const handleClose = () => {
		closePopup();
	}

	const { t } = useTranslation();

	const linkRef = useRef(null);

	const {itemLink} = useItem(item)

	const config = [
		{
			icon: <BsFacebook className="social-icon" />,
			link: `https://www.facebook.com/dialog/share?app_id=1133180561273070&href=${encodeURIComponent(itemLink)}&display=popup&redirect_uri=${itemLink}`,
		},
		{
			icon: <BsReddit className="social-icon" />,
			link: 'https://www.reddit.com/submit?url=',
		},
		{
			icon: <BsTwitterX className="social-icon" />,
			link: 'https://twitter.com/intent/tweet?url=',
		},
		{
			icon: <BsWhatsapp className="social-icon" />,
			link: 'https://api.whatsapp.com/send/?text=',
		},
		{
			icon: <BsLinkedin className="social-icon" />,
			link: 'https://www.linkedin.com/shareArticle/?url=',
		}
	]

	const handleCopyUrl = () => {

		if (linkRef.current) {
			linkRef.current.select();
			document.execCommand('copy');
			Notification.success(t('parts.misc.shareWindow.notification'));
		}
	};

	return(
		<>
			<CloseOutside handleClose={handleClose}>
				<div className="share-window">
					<CloseIcon handleClose={handleClose} additionalStyles="mt-3 me-3" />
					<div className="d-flex justify-content-between">
						<h3>{t('parts.misc.shareWindow.title')}</h3>
					</div>
					<div className="mt-3 mb-3 d-flex justify-content-between">
						<ShareWindowLinks link={itemLink(true)} config={config}/>
					</div>
					<div className="d-flex">
						<div className="position-relative copy-link-wrapper">
							<label>{t('parts.misc.shareWindow.label')}</label>
							<Form.Control ref={linkRef} type="text" defaultValue={itemLink(true)} readOnly/>
						</div>
						<Button className="link ms-3" onClick={handleCopyUrl}>{t('parts.misc.shareWindow.button')}</Button>
					</div>
				</div>
			</CloseOutside>
		</>
	)
}

export default ShareWindow;
