import React from 'react';
import {useGeoSearch, useInstantSearch, useRefinementList} from 'react-instantsearch';
import {useTranslation} from 'react-i18next';
import {Button, Form, ListGroup, Badge} from 'react-bootstrap';
import {PiSlidersHorizontalLight} from 'react-icons/pi';
import useConfig from '../../../Hooks/ReduxHooks/useConfig';
import SimpleBar from 'simplebar-react';
/*
 *
 * VirtualRefinementList. Algolia will not render the component, but will use it to manage the state of the refinement list.
 *
 */
export function VirtualRefinementList(props) {
	useRefinementList(props);
	return null;
}

export function VirtualGeoSearch(props) {
	useGeoSearch(props);
	return null;
}

export const ClearFilters = ({handleReset = () => {}}) => {

	const {setIndexUiState} = useInstantSearch()

	const handleClick = (e) => {

		e.preventDefault();

		handleReset();

		setIndexUiState([]);

	}

	const {t} = useTranslation();

	return (
		<Button variant={'light'} className={'w-100 mt-2'} onClick={handleClick}>
			{t('jobs.browse.parts.filters.filtersContents.clear')}
		</Button>
	);

};

/*
 * CustomRefinementList
 *
 */
export const CustomRefinementList = (props) => {

	const {items, refine, toggleShowMore, canToggleShowMore, isShowingMore, searchForItems} = useRefinementList(props);

	const {t} = useTranslation();

	const Item = ({item}) => {

		const {config} = useConfig();

		if(props.attribute === 'languages.language') {

			const {value = 'Unknown language'} = config.languages.items.find(language => language.key === item.value);

			item.label = value;

		}
		else {

			item.label = t(`browse.filters.${props.attribute}.values.${item.value}`);

		}

		return (
			<ListGroup.Item as="li" variant="secondary" key={item.value} className="bg-transparent border-0 p-0 d-flex justify-content-between align-items-start">
				<Form.Group controlId={`${props.attribute}-${item.value}`}>
					<Form.Check id={`${props.attribute}-${item.value}`} className="text-nowrap font-lg" type="checkbox" label={item.label} onChange={() => refine(item.value)} value={item.value} checked={item.isRefined}/>
				</Form.Group>
				<Badge bg={''}>{item.count}</Badge>
			</ListGroup.Item>
		);
	};

	const ShowMoreButton = () => {
		return (
			<button className={'show-more-link'} onClick={toggleShowMore} disabled={!canToggleShowMore}>
				{isShowingMore ? t('browse.filters.showLess') : t('browse.filters.showMore')}
			</button>
		);
	};

	const SearchInput = () => {
		return (
			<Form.Control size="sm" placeholder="Search.." type="search" autoComplete="off" autoCorrect="off" autoCapitalize="off" spellCheck={false} maxLength={512} onChange={(event) => searchForItems(event.currentTarget.value)}/>
		)
	}

	if(items.length === 0) {
		return null;
	}

	return (
		<>
			{props.title && <div className={'title'}>{props.title}</div>}
			<SimpleBar style={{maxHeight: 268}} className={isShowingMore ? 'showing-more' : 'showing-less'}>
				{/*{isShowingMore && <SearchInput/>}*/}
				<ListGroup as="ul" className="mt-2">
					{items.map((item, index) => <Item key={index} item={item}/>)}
				</ListGroup>
			</SimpleBar>
			{canToggleShowMore && <ShowMoreButton/>}
		</>
	);

};
/*
 *
 * FiltersToggleButton
 *
 */
export const FiltersToggleButton = ({setVisible}) => {

	const {t} = useTranslation();

	// get indexUiState from InstantSearch
	const {indexUiState} = useInstantSearch();

	// get count of filters
	const count = 'refinementList' in indexUiState ? Object.keys(indexUiState.refinementList).length : 0;

	return (
		<div className="d-flex justify-content-start align-items-center me-3">
			<Button variant="light" className="filters-toggle align-items-center font-lg d-flex text-primary shadow-sm p-3 bg-white" onClick={() => { setVisible(prev => !prev);}}>
				<p className="mb-0 me-1 ms-2 me-3 d-lg-block d-none">{t('jobs.browse.parts.filters.moreFilters')}</p>
				<PiSlidersHorizontalLight size={34}/>
				{count !== 0 && <span>{count}</span>}
			</Button>
		</div>
	);

};