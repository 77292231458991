import React, {useEffect, useRef, useState} from "react";
import Notification from '../../../../../utils/NotificationUtils';
import ThumbnailUploadFormButton from '../../Common/ThumbnailUploadFormButton';
import {useTranslation} from 'react-i18next';
import axios from 'axios';
import useApi from "../../../../../Hooks/useApi";
import ImageCrop from "../Helpers/ImageCrop";
import s3upload from "../Helpers/s3upload";
import usePopup from "../../../../../Hooks/ReduxHooks/usePopup";
import useError from "../../../../../Hooks/ReduxHooks/useError";
import useBackground from "../../../../../Hooks/ReduxHooks/useBackground";
import {Rings} from 'react-loader-spinner';
import {XCircle} from 'react-feather';
import {toast} from "react-toastify";

const ThumbnailUploadForm = ({thumbnail, setThumbnail, loading, setLoading, setPreviewUrl, previewUrl, setAvatar, setPreviewUrlType}) => {
	const {setProgress, isUploading} = useBackground();
	const {t} = useTranslation();
	const videoRef = useRef();
	const {request} = useApi();
	const [selectedFile, setSelectedFile] = useState(null);
	const [cancelTokenSource, setCancelTokenSource] = useState(null);
	const {openPopup} = usePopup();
	const {handleSetIndexStatusValue} = useError();
	const [dragOver, setDragOver] = useState(false);

	const handleVideoChange = async(file) => {
		if (!file) return;

		const fileType = file.type.split('/')[0];
		const validVideoExtensions = ['mp4', 'webm', 'mov', 'avi', 'mkv', 'wmv'];
		const validImageExtensions = ['jpg', 'jpeg', 'png', 'webp'];
		const fileExtension = file.name.split('.').pop().toLowerCase();

		if(fileType === 'video') {
			const videoDuration = await getVideoDuration(file);
			const maxVideoDurationSeconds = 60;
			if(videoDuration > maxVideoDurationSeconds){
				Notification.error('The video is longer than a minute');
				return;
			}
			else if(!validVideoExtensions.includes(fileExtension)) {
				Notification.error(t('videoUploadForm.errors.invalidVideoExtension'), 'error');
				return;
			}
		}

		if(fileType === 'image' && !validImageExtensions.includes(fileExtension)) {
			Notification.error(t('videoUploadForm.errors.invalidImageExtension'), 'error');
			return;
		}

		const fileSizeMB = file.size / 1024 / 1024;
		const maxFileSize = 500;
		if(fileSizeMB > maxFileSize) {
			Notification.error(t('videoUploadForm.errors.fileTooLarge', {maxFileSize}), 'error');
			return;
		}

		if (fileType === 'image') {
			const image = new Image();
			image.onload = function () {
				if (image.width < 600 || image.height < 600) {
					Notification.error('Minimum image size up to 600x600px.');
					return;
				} else {
					const src = URL.createObjectURL(file);
					setPreviewUrlType(file.type);
					setSelectedFile(file);
					openPopup(ImageCrop, {previewUrl: src, setPreviewUrl: setPreviewUrl, setSelectedFile: setSelectedFile});
				}
			};
			image.src = URL.createObjectURL(file);
		}
		else {
			const src = URL.createObjectURL(file);
			setPreviewUrlType(file.type);
			setSelectedFile(file);
			setPreviewUrl(src);
		}
	};

	const getVideoDuration = async(file) => {
		return new Promise((resolve, reject) => {
			const video = document.createElement('video');
			video.preload = 'metadata';
			video.onloadedmetadata = function() {
				window.URL.revokeObjectURL(video.src);
				resolve(video.duration);
			};
			video.onerror = function() {
				reject('Unable to retrieve video duration');
			};
			video.src = URL.createObjectURL(file);
		});
	};

	useEffect(() => {
		if(!selectedFile) return;

		setPreviewUrl(null);
		setLoading({
			state: 'uploading',
			text: t('profile.edit.gallery.thumbnailUploadForm.loading.uploading')
		});

		const newCancelToken = axios.CancelToken.source();
		setCancelTokenSource(newCancelToken);

		request({
			method: 'get',
			url: `profile/upload/url?upload_type=thumbnail&resource_type=${selectedFile.type}`,
			onSuccess: async(data) => {
				await s3upload(data.url, selectedFile, () => {
					setLoading({
						state: 'pending',
						text: t('profile.edit.gallery.thumbnailUploadForm.loading.pending')
					});
					handleSetIndexStatusValue('pending');
				}, () => {
					setLoading(false);
				}, (progress) => {
					setProgress(progress);
				}, newCancelToken);
			},
			onError: () => {
				Notification.error(t('videoUploadForm.errors.uploadFailed'), 'error');
				setLoading(false);
			}
		});
	}, [previewUrl]);

	const handleCancelUpload = () => {
		if(cancelTokenSource) {
			cancelTokenSource.cancel("User has cancelled the upload.");
		}
	};

	const handleRemoveThumbnail = () => {
		if(previewUrl) {
			setPreviewUrl(null);
		}
		else {
			handleSetIndexStatusValue('pending');
			setLoading({
				state: 'pending',
				text: 'Removing'
			});
			request({
				method: 'post',
				url: `profile`,
				body: {
					action: "reset_thumbnail"
				},
				onSuccess: () => {
					setThumbnail({source: null});
					setAvatar(null);
					setLoading(false);
				},
				onError: () => {
					Notification.error(t('videoUploadForm.errors.removeFailed'), 'error');
					setLoading(false);
				}
			}).finally(() => setLoading(false));
		}
	};

	useEffect(() => {
		if(isUploading) {
			setLoading({
				state: 'uploading',
				text: t('profile.edit.gallery.thumbnailUploadForm.loading.uploading')
			});
		}
	}, [isUploading]);

	const handleDragOver = (e) => {
		e.preventDefault();
		setDragOver(true);
	};

	const handleDragLeave = () => {
		setDragOver(false);
	};

	const handleDrop = (e) => {
		e.preventDefault();
		setDragOver(false);
		const file = e.dataTransfer.files[0];
		handleVideoChange(file);
	};

	return (
		<>
			{(thumbnail.source || previewUrl) &&
				<button className={`circle-close mt-2 me-2`} disabled={loading} onClick={handleRemoveThumbnail}>
					{loading ?
						<Rings height="26" width="26" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/> :
						<XCircle size={24}/>
					}
				</button>
			}
			<div className={`upload-video ${dragOver ? 'drag-over' : ''}`} onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
				<input type="file" name="video" ref={videoRef} className="dropify" accept="video/mp4, video/webm, video/mov, video/avi, video/mkv, video/wmv, image/jpeg, image/png, image/webp" onChange={(e) => handleVideoChange(e.target.files[0])}/>
				<ThumbnailUploadFormButton loading={loading} abort={handleCancelUpload} videoRef={videoRef} setLoading={setLoading} isThumbnailActive={thumbnail.source || previewUrl}/>
			</div>
		</>
	);
};

export default ThumbnailUploadForm;
