import React, {useRef} from "react";
import './Assets/single.scss';
import './Assets/single-media-query.scss';
import {useParams} from 'react-router-dom';
import {Alert, Container} from 'react-bootstrap';
import SingleNotFound from './SingleNotFound';
import useSingle from './useSingle';
import {useTranslation} from 'react-i18next';
import useUser from "../../Hooks/ReduxHooks/useUser";
import FloatingButtons from "../Parts/FloatingButtons";

const Single = () => {
	const { t } = useTranslation();
	const {user} = useUser();
	let {id} = useParams();
	const {Content, item} = useSingle(id);
	const containerRef = useRef()

	if(item === 404) {

		return (
			<Container>
				<SingleNotFound/>
			</Container>
		);

	}

	const ProfileVisibleStatusAlert = () => {
		if(id){
			return <></>
		}
		if(user.is_visible === 'visible' || !user.is_visible){
			return <></>
		}

		return(
			<>
				{user.is_visible === 'pending' &&
					<Alert variant="success" className="mt-4">
						<>{t('jobs.single.single.alerts.pending')} <strong>48H</strong>.</>
						{user.is_visible === 'rejected' && <>{t('jobs.single.single.alerts.rejected')}</>}
					</Alert>
				}
				{user.is_visible === 'rejected' &&
					<Alert variant="danger" className="mt-4">
						{user.is_visible === 'rejected' && <>{t('jobs.single.single.alerts.rejected')}</>}
					</Alert>
				}
			</>
		)
	}

	return (
		<Container ref={containerRef} className={'overflow-hidden'}>
			<ProfileVisibleStatusAlert />
			<Content/>
			<FloatingButtons data={item} containerRef={containerRef} />
		</Container>
	);

};

export default Single;
