import React from "react";

const GalleryImages = ({gallery, handleClickImage}) => {

	const DisplayImage = ({item, image}) => {

		return (
			<>
				<img src={image} onClick={() => handleClickImage(item)}/>
			</>
		)

	}

	return gallery.map((item, index) => {

		return (
			<div className={'gallery-item'} key={index}>
				{
					item.imageLink && <DisplayImage item={item} image={item.imageLink}/>
				}
			</div>
		);

	});

};

export default GalleryImages;
