import AuthHeader from "../Parts/AuthHeader";
import VerifyForm from "./VerifyForm";
import AuthFooter from "../Parts/AuthFooter";
import { resendSignUpCode } from 'aws-amplify/auth';
import { useState } from "react";
import useCountdown from "../../../Hooks/useCountdown";
import {hideEmail} from "../../../utils/AppUtils";

const Verify = ({ setAuthType }) => {
	const { startCountdown, timeLeft, isTimeoutRemaining } = useCountdown();
	const [verifyCodeResend, setVerifyCodeResend] = useState({
		status: false,
		text: ''
	});

	const buttonHandleClick = async () => {
		if (!isTimeoutRemaining) return;

		try {
			await resendSignUpCode({ username: localStorage.getItem('email') });
			startCountdown(20);
			setVerifyCodeResend({
				status: false,
				text: `The code has been sent to your email: ${hideEmail(localStorage.getItem('email'))}`
			})
		} catch (error) {
			setVerifyCodeResend({
				status: true,
				text: error.message
			});
		}
	};

	return (
		<>
			<AuthHeader title="Confirm your account" subtitle={!isTimeoutRemaining ? `Resend code again in ${timeLeft}` : "You didn't get the code?"} buttonText={!isTimeoutRemaining ? '' : 'Resend'} buttonHandleClick={buttonHandleClick}/>
			<VerifyForm setAuthType={setAuthType} verifyCodeResend={verifyCodeResend} setVerifyCodeResend={setVerifyCodeResend}/>
			<AuthFooter />
		</>
	);
};

export default Verify;
