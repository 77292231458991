import {ChevronLeft} from 'react-feather';
import {useNavigate} from 'react-router-dom';
import React from 'react';
import {useTranslation} from 'react-i18next';

const PrevLocationBack = ({text = false}) => {
	const {t} = useTranslation();
	const navigate = useNavigate()
	const previousLocation = localStorage.getItem('previousLocation')
	const handleClick = () => {
		navigate(previousLocation)
	}

	return(
		<>
			<div className="d-flex align-items-center" onClick={handleClick}>
				<button className="btn-primary btn-circle sm btn me-3">
					<ChevronLeft size={24} />
				</button>
				{text && <p className="mb-0 font-xl cursor-pointer font-family-open-sans">{t('parts.prevLocationBack.text')}</p>}
			</div>
		</>

	)
}

export default PrevLocationBack;