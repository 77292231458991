import React from "react";
import {Col, Container, Row} from 'react-bootstrap';
import SingleHeaderLeft from './SingleHeaderLeft';
import SingleHeaderRight from './SingleHeaderRight';

const SingleHeader = ({loading, item}) => {

	return (
		<div className={'page-single-header'}>
			<Container>
				<Row className="align-items-center">
					<Col>
						{loading ? <div className={'skeleton dark skeleton-text'}></div> :
							<SingleHeaderLeft item={item}/>}
					</Col>
					<Col className={'text-right d-flex align-items-center justify-content-end'}>
						{loading ? <div className={'skeleton dark skeleton-text float-end'}></div> :
							<SingleHeaderRight item={item}/>}
					</Col>
				</Row>
			</Container>
		</div>
	);

};

export default SingleHeader;
