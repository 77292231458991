import FiltersDropdown from './FiltersDropdown';
import CloseOutside from '../../Parts/CloseOutside';
import React, {useEffect, useState} from 'react';
import {FiltersToggleButton} from './FiltersParts';
import {useToggleRefinement} from 'react-instantsearch';
import useUser from "../../../Hooks/ReduxHooks/useUser";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";

const Filters = () => {

	const [visible, setVisible] = useState(false);

	const {value, refine } = useToggleRefinement({ attribute: 'isPremium' });

	const {isMobile} = useUser();

	const {openPopup, closePopup} = usePopup();

	const handleChangePremium = (event) => {

		refine({ isRefined: !event.target.checked });

	};

	useEffect(() => {
		if(isMobile && visible){
			openPopup(FiltersDropdown, {visible: visible, setVisible: setVisible})
		}
		else{
			closePopup()
		}
	}, [visible]);

	return (
		<>
			<CloseOutside handleClose={() => !isMobile ? setVisible(false) : ''} className={'app-browse-filters'}>
				<FiltersToggleButton setVisible={setVisible}/>
				{!isMobile && <FiltersDropdown visible={visible} setVisible={setVisible}/>}
			</CloseOutside>
			<label className="d-flex align-items-center ms-3">
				<div className="toggle d-flex justify-content-between align-items-center cursor-pointer me-2">
					<input className="toggle-checkbox" type="checkbox" checked={value.isRefined} onChange={handleChangePremium}/>
					<div className="toggle-switch"></div>
				</div>
				<p className={`text-uppercase mb-0 font-xl text-primary`}>Premium only</p>
			</label>
		</>);

};

export default Filters;