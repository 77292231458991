import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAuthSession, getCurrentUser, signOut} from 'aws-amplify/auth';
import callToAPI from "../../api";

const initialState = {
	user: null,
	loading: true,
	indexStatus: '',
	isMobile: window.innerWidth <= 991,
	isIOS: /iPad|iPhone|iPod/.test(navigator.userAgent),
	isAdmin: false,
	lang: localStorage.getItem('i18nextLng'),
	observations: [],
	thumbnail: '',
	avatar: '',
	loadingImage: false,
};

export const handleRefresh = createAsyncThunk('user/handleRefresh', async (_, { dispatch }) => {

	try {
		const session = await fetchAuthSession();
		if(session.userSub === undefined) {

			return null;

		}
		else {

			const user = await getCurrentUser();

			// TODO: retretive user account type from the backend
			const loginType = user.username.split('_').length === 1 ? 'User' : user.username.split('_')[0];

			const response = await callToAPI({
				method: 'get',
				url: `${process.env.REACT_APP_API_HOST_NEW}/profile`,
			});

			if(!response){

				throw new Error('Error in request');

			}

			return { ...response, loginType: loginType}

		}

	}
	catch (err) {

		throw new Error('Error in request');

	}

});

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setUser(state, action) {
			state.user = action.payload;
		},
		setIsMobile(state, action) {
			state.isMobile = action.payload;
		},
		setThumbnail(state, action){
			state.user = ({...state.user, thumbnail: action.payload});
		},
		setAvatar(state, action){
			state.user = ({...state.user, avatar: action.payload});
		},
		setImageLoading(state, action){
			state.loadingImage = action.payload;
		}
	},
	extraReducers: builder => {
		builder.addCase(handleRefresh.pending, state => {
			state.loading = true;
		});
		builder.addCase(handleRefresh.fulfilled, (state, action) => {
			state.user = action.payload;
			state.loading = false;
			state.indexStatus = action.payload?.index_status;
		});
	},
});

export const {
	setUser,
	setIsMobile,
	setLoading,
	setThumbnail,
	setAvatar,
	setImageLoading
} = userSlice.actions;

export default userSlice.reducer;
