import React, {useState} from "react";
import VerificationInput from "react-verification-input";
import {Rings} from "react-loader-spinner";
import {Form} from "react-bootstrap";

const ForgotVerifyForm = ({setAuthType, verifyCodeResend, setVerifyCodeResend}) => {

	const [isComplete, setIsComplete] = useState(false)

	const [codeValue, setCodeValue] = useState()

	const [loading, setLoading] = useState(false)

	const handleComplete = (code) => {
		setCodeValue(code)
		setIsComplete(true)
	}

	const handleChange = () => {
		setVerifyCodeResend({
			status: false,
			text: ''
		})
		setIsComplete(false)
	}

	const handleBack = () => {
		setAuthType('forgot')
		localStorage.removeItem('forgot-verify-code')
	}

	const handleSubmit = async (e) => {
		setLoading(true)
		e.preventDefault()
		if(codeValue && isComplete){
			setLoading(false)
			localStorage.setItem('forgot-verify-code', codeValue)
			setAuthType('reset')
		}
	}

	return(
		<Form className="auth-form" onSubmit={handleSubmit}>
			<span className="font-weight-lg font-lg">
				Your verification code:
			</span>
			<div className="verification-input-wrapper">
				<VerificationInput onChange={handleChange} autoFocus onComplete={handleComplete} validChars="0-9" inputProps={{ inputMode: "numeric" }} placeholder="" classNames={{character: "character", container: "container", characterSelected: "character-selected",}} />
				<p className={`mt-4 ${verifyCodeResend.status ? 'resend-error' : 'resend-successful'} font-md position-absolute`}>{verifyCodeResend.text}</p>
			</div>
			<button type="submit" className="btn-primary btn w-100 auth-submit-button d-flex justify-content-center" disabled={!isComplete}>
				{!loading && <>Confirm</>}
				{loading && <Rings height="28" width="28" color="#ffffff" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
			</button>
			<div className="d-flex justify-content-center mt-4">
				<button onClick={handleBack} className="d-flex justify-content-center bg-transparent border-0 text-decoration-underline font-lg font-weight-md">
					Back
				</button>
			</div>
		</Form>
	)
}

export default ForgotVerifyForm;