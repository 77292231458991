import React, {useRef, useState} from "react";
import useUser from '../../Hooks/ReduxHooks/useUser';
import BecomeSeller from './Parts/BecomeSeller';
import { Stats} from 'react-instantsearch';
import Filters from './Filters/Filters';
import AlgoliaConfig, {statsTranslations} from '../../Config/AlgoliaConfig';
import ActiveFilters from './Parts/ActiveFilters';
import SortBy from './Parts/Sort';

const BrowseHeader = () => {

	const BrowseHeaderRef = useRef();

	const {isMobile} = useUser();
	const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
	const [scrollDirection, setScrollDirection] = useState(null);
	const [additionalOptionsMargin, setAdditionalOptionsMargin] = useState(0);
	const [isFixed, setIsFixed] = useState(false);

	// const handleScroll = () => {
	// 	const currentScrollPos = window.pageYOffset;
	// 	setIsFixed(currentScrollPos > 0);
	// 	if(currentScrollPos > 250) {
	// 		const direction = currentScrollPos > prevScrollPos ? 'down' : 'up';
	//
	// 		setScrollDirection(direction);
	// 		setPrevScrollPos(currentScrollPos);
	// 	}
	// 	else if(currentScrollPos < 250) {
	// 		setScrollDirection('up');
	// 		setPrevScrollPos(currentScrollPos);
	// 	}
	// };
	//
	// useEffect(() => {
	// 	window.addEventListener('scroll', handleScroll);
	// 	if(BrowseHeaderRef.current) {
	// 		setAdditionalOptionsMargin(BrowseHeaderRef.current.offsetHeight);
	// 	}
	// 	return () => {
	// 		window.removeEventListener('scroll', handleScroll);
	// 	};
	// }, [prevScrollPos]);

	const classes = isMobile ? `p-3 rounded-bottom-4 d-flex justify-content-between align-items-end additional-options bg-gray w-100 start-0 ${isFixed ? 'position-fixed' : ''} ${scrollDirection}` : ``;

	return (
		<div style={{marginBottom: `${isMobile ? additionalOptionsMargin : 0}px`}}>
			<div className={'browse-header'} ref={BrowseHeaderRef}>
				<div className="d-flex align-items-center">
					<Filters/>
				</div>
				{!isMobile && <BecomeSeller/>}
			</div>
			{/*<Preview/>*/}
			<div className="d-flex align-items-center mt-3 flex-md-column mb-3 mb-lg-0 flex-column flex-lg-row">
				<ActiveFilters/>
				<div className={'d-flex ms-md-auto align-items-center justify-content-md-center align-self-md-center'}>
					<Stats translations={statsTranslations}/>
					<div className={'ms-2'}>
						<SortBy items={AlgoliaConfig.sorting}/>
					</div>
				</div>
			</div>
			{isMobile && <BecomeSeller/>}
		</div>
	);
};

export default BrowseHeader;
