import React from 'react';
import * as Icon from 'react-feather';
import {Link} from 'react-router-dom';
import {Col, Row} from 'react-bootstrap';

const NotFound = () => {

	return (
		<Row>
			<Col sm={{ span: 6, offset: 3}}>
				<div className="justify-content-center">
					<div className="page-404 align-self-center">
						<Icon.AlertOctagon/>
						<h2>404</h2>
						<p>Podstrona nie została znaleziona.</p>
						<Link to="/">przejdź do strony głównej</Link>
					</div>
				</div>
			</Col>
		</Row>
	);

};

export default NotFound;