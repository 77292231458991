import React, {useEffect, useRef, useState} from "react";
import './SliderPopup.scss';
import SliderControls from './SliderControls';
import CloseOutside from '../../Parts/CloseOutside';
import SliderPopupItem from './SliderPopupItem';
import SwipeContainer from "../../Parts/SwipeContainer";
import {HitsPerPage} from '../../../Config/AlgoliaConfig';

const SliderPopup = ({index, setIndex, showMore, items, total}) => {

	const trackRef = useRef(null);
	const itemsRef = useRef(null);

	const [style, setStyle] = useState({});
	const [pristine, setPristine] = useState(true);

	const loadMoreGap = 3;

	useEffect(() => {

		handleCalculate();

		if((index + loadMoreGap) >= total && total >= HitsPerPage) {

			showMore();

		}

	}, [index]);

	useEffect(() => {

		document.body.classList.add('has-popup');

		const handleSwipeDown = (event) => {

			event.preventDefault();

		};

		const handleResize = () => {

			handleCalculate();

		};

		window.addEventListener('touchmove', handleSwipeDown, {passive: false});

		window.addEventListener('resize', handleResize);

		return () => {

			window.removeEventListener('touchmove', handleSwipeDown);

			window.removeEventListener('resize', handleResize);

			document.body.classList.remove('has-popup');

		};

	}, []);

	const handleCalculate =() => {

		if(trackRef.current !== null) {

			let width = window.innerWidth > 991 ? 25 : trackRef.current.clientWidth;

			let transform = window.innerWidth > 991 ? `translateX(-${index * width}%)` : `translateX(-${index * width}px)`;

			setStyle({
				transform: transform
			});

		}

	}

	const handlePrev = () => {

		const prevItem = index > 0 ? index - 1 : -1;

		setIndex(prevItem);

		setPristine(false);

	};

	const handleNext = () => {

		const nextItem = total > (index + 1) ? index + 1 : -1;

		setIndex(nextItem);

		setPristine(false);

	};

	const handleClose = () => {

		setIndex(-1);

	};

	const handleClickOutside = (event) => {

		if (event.target === event.currentTarget) {
			handleClose();
		}

	};

	return (
		<div className={'popup'} onClick={handleClickOutside}>
			<div className={`popup-track-initial`} onClick={handleClickOutside}>
				<div className={`popup-track ${pristine ? 'pristine' : 'animated'}`} style={style} ref={trackRef}>
					<SwipeContainer onSwipeLeft={handleNext} onSwipeRight={handlePrev} className={'items'} ref={itemsRef}>
						{items.map((item, i) => <SliderPopupItem item={item} index={index} key={i} loopIndex={i} handleClose={handleClose} handlePrev={handlePrev}/>)}
					</SwipeContainer>
				</div>
			</div>
			<SliderControls index={index} handlePrev={handlePrev} handleNext={handleNext} total={total}/>
		</div>
	);

};

export default SliderPopup;
