/* eslint-disable */
import {Link} from "react-router-dom";
import thumbnailWhiteIcon from '../../../assets/svg/premium/thumbnail-white.svg';
import {useState} from "react";

const PremiumPayment = () => {

	const [isChecked, setIsChecked] = useState(false)

	const changePayment = (e) => {
		setIsChecked(e.target.checked)
	}

	return(
		<div className="premium-payment pt-3 pb-3 ps-4 pe-4 rounded-5 h-100 d-flex flex-column justify-content-between">
			<div className="d-flex align-items-center justify-content-between">
				<p className={`text-uppercase mb-0 font-xl ${!isChecked ? 'font-weight-xl text-primary' : ''}`}>Monthly</p>
				<label className="toggle d-flex justify-content-between align-items-center cursor-pointer">
					<input className="toggle-checkbox" type="checkbox" onChange={changePayment} />
					<div className="toggle-switch"></div>
				</label>
                <p className={`text-uppercase mb-0 font-xl ${isChecked ? 'font-weight-xl text-primary' : ''}`}>Annualy</p>
			</div>
            <div className="d-flex flex-column align-items-center mt-3 mb-3">
                <div>
                    <p className="font-xxl text-primary font-weight-lg mb-0">Premium</p>
                    <p className="m-0 text-primary font-weight-md price-wrapper">
						{!isChecked ? '$9.99' : '$1.99'}
                        <span className="ms-2">/mo</span>
                    </p>
                </div>
            </div>
            <div className="d-flex flex-column align-items-center">
                <Link to="" className="font-xxl text-uppercase btn btn-primary w-100 pt-3 pb-3 d-flex align-items-center justify-content-center">
                    <img src={thumbnailWhiteIcon} alt="Icon" className="me-2" />
                    Get premium
                </Link>
				<Link to="" className="font-family-open-sans text-uppercase verify-link font-md text-center mt-2 text-decoration-underline">
					Or verify only your account
				</Link>
            </div>
		</div>
	)
}

export default PremiumPayment;