import React from "react";
import './Assets/single.scss';
import './Assets/single-media-query.scss';
import {Col, Container, Row} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {CiCircleInfo} from "react-icons/ci";

const SingleNotFound = () => {

	const { t } = useTranslation();

	return (
		<div className={`page-single type-not-found`}>
			<Container>
				<Row>
					<Col sm={{ span: 6, offset: 3}}>
						<div className="justify-content-center">
							<div className="page-404 page-404-white align-self-center">
								<CiCircleInfo size={40}/>
								<h2 className="font-family-open-sans">404</h2>
								<p className="font-family-open-sans">{t('jobs.single.singleNotFound.notFound')}</p>
								<Link to="/" className="btn btn-primary">{t('jobs.single.singleNotFound.browse')}</Link>
							</div>
						</div>
					</Col>
				</Row>
			</Container>

		</div>
	);

};

export default SingleNotFound;
