import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-bootstrap';
import { Plus, X } from 'react-feather';
import { Rings } from 'react-loader-spinner';
import { Slugify } from '../../../utils/AppUtils';
import useError from '../../../Hooks/ReduxHooks/useError';
import ProfileError from '../Edit/Parts/ProfileError';
import { useFormikContext } from 'formik';
import HintsSkeleton from './Skeleton/HintsSkeleton';
import { TooltipHelper } from '../../Parts/Tooltips';
import { FaLock } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const DisplayRepeater = ({ fetchHints, assignHint, handleRemove, title, items, section, ownValue = true, isBlocked }) => {
	const { t } = useTranslation();
	const { detailsError } = useError();
	const { errors } = useFormikContext() || {};

	return (
		<>
			{title && (
				<div className={`d-flex align-items-center mt-4 mb-2 position-relative repeater-title ${section === 'languages' ? 'w-100' : ''}`}>
					<h4 className={'m-0 title'}>{title}</h4>
					<ProfileError errorValue={detailsError[section]} additionalStyles="ms-2" type="details" profileError={errors[section]} />
				</div>
			)}
			<div className={`d-flex flex-wrap ${section === 'languages' ? 'w-75 pe-3' : ''}`}>
				<div className="w-100 position-relative">
					<div className={`profile-repeater-wrapper`}>
						<DisplayItems items={items} errors={detailsError[section]} handleRemove={handleRemove} />
						<DisplayHintsBox fetchHints={fetchHints} assignHint={assignHint} items={items} ownValue={ownValue} isError={errors[section]} isBlocked={isBlocked} />
					</div>
				</div>
			</div>
		</>
	);
};

const DisplayItems = ({ items, errors, handleRemove }) => (
	<div className="d-flex flex-wrap">
		{items.map((item, id) => (
			<DisplayItem item={item} key={item.id || id} errors={errors} handleRemove={handleRemove} />
		))}
	</div>
);

const DisplayItem = ({ item, errors, handleRemove }) => {
	const [loading, setLoading] = useState(false);
	const hasError = errors && errors.some(error => error.value === item.name);

	const handleRemoveItem = () => {
		setLoading(true);
		handleRemove(item, () => setLoading(false));
	};

	return (
		<span className={`repeater-pill font-family-open-sans ${hasError ? 'border border-danger text-danger' : ''}`}>
			{item.name}
			{loading ? (
				<Rings height="16" width="16" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'ms-1'} />
			) : (
				<X size={16} className={'ms-1'} onClick={handleRemoveItem} />
			)}
		</span>
	);
};

const DisplayHintsBox = ({ fetchHints, assignHint, items, ownValue, isError, isBlocked }) => {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(false);
	const [query, setQuery] = useState("");
	const [hints, setHints] = useState([]);
	const timeoutId = useRef(null);

	const fetchHintsDelayed = (query) => {
		setLoading(true);
		fetchHints(query)
			.then(results => setHints(results))
			.finally(() => setLoading(false));
	};

	useEffect(() => {
		if (query.length > 0 && typeof fetchHints === 'function') {
			clearTimeout(timeoutId.current);
			timeoutId.current = setTimeout(() => {
				fetchHintsDelayed(query);
			}, 500);
		}

		if (Array.isArray(fetchHints)) {
			setHints(fetchHints);
		}
	}, [query]);

	const handleAdd = (event) => {
		event.preventDefault();
		if (loading || !ownValue) return;
		if (query.length !== 0) setError('');

		if (query.length === 0) {
			setError(t('profile.common.useRepeater.translations.itemEmpty'));
			return;
		}

		setLoading(true);

		if (items.some(item => Slugify(item.name) === Slugify(query))) {
			setError(t('profile.common.useRepeater.translations.itemExists'));
		}
		else {
			assignHint({ id: Slugify(query), name: query }, () => {
				setLoading(false)
				setQuery('');
			});
		}
	};

	const handleChange = (event) => {
		if (event.target.value.length === 0) {
			setQuery('');
			handleFocus();
		} else {
			setLoading(true);
			setQuery(event.target.value);
		}
	};

	const handleFocus = () => {
		if (hints.length === 0) {
			setLoading(true);
			fetchHints('')
				.then(results => setHints(results))
				.finally(() => setLoading(false));
		}
	};

	const handleKeyDown = (e) => {
		setError('');
		if (e.keyCode === 13 || e.keyCode === 188) {
			if (!e.shiftKey) {
				e.preventDefault();
				handleAdd(e);
			}
		}
	};

	return (
		<>
			<div className="w-100">
				<>
					<div className={'meta-add-input form-control-with-loader'}>
						<div className="d-flex justify-content-between position-relative">
							<div className={'position-relative d-flex w-100'}>
								<Form.Control disabled={isBlocked.status} className={`${isError || error ? 'border border-danger' : ''}`} onKeyDown={handleKeyDown} onFocus={handleFocus} type="text" placeholder={t('profile.profile.tabs.common.addMeta.placeholder')} size={'sm'} onChange={handleChange} value={query} />
								<ProfileError profileError={error} additionalStyles={'position-absolute top-50 end-0 translate-middle-y me-3'} />
								{isBlocked.status && (
									<TooltipHelper className={'position-absolute top-50 end-0 me-3 translate-middle-y'} icon={FaLock}>
										<div>
											<p className={'mb-0'}>{isBlocked.text}</p>
											{isBlocked.getPremium && (
												<Link className={'btn btn-primary ps-2 pe-2 font-sm pt-1 pb-1 mt-1 mb-1'} to={'/premium'}>Get Premium</Link>
											)}
										</div>
									</TooltipHelper>
								)}
							</div>
							{ownValue && (
								<button title="Dodaj" type="button" className="btn btn-primary btn-circle md flex-shrink-0 ms-2" onClick={handleAdd} disabled={loading || isBlocked.status}>
									<Plus />
								</button>
							)}
						</div>
						{loading && <HintsSkeleton />}
					</div>
				</>
				{hints.length === 0 && query.length > 0 && !loading && <small>{t('profile.common.useRepeater.translations.hintsNotFound')}</small>}
				{!loading && <DisplayHintsResults hints={hints} items={items} assignHint={assignHint} isBlocked={isBlocked} />}
			</div>
		</>
	);
};

const DisplayHintsResults = ({ hints, items, assignHint, isBlocked }) => {
	if (!hints) return null;

	const differenceHints = hints.filter(hint => !items.some(item => Slugify(item.name) === Slugify(hint.name)));

	return (
		<div className={`results ${hints.length !== 0 ? 'mt-2' : ''}`}>
			{differenceHints.map((hint, index) => (
				<DisplayHintsResultsItem key={index} hint={hint} assignHint={assignHint} isBlocked={isBlocked} />
			))}
		</div>
	);
};

const DisplayHintsResultsItem = ({ hint, assignHint, isBlocked }) => {
	const [loading, setLoading] = useState(false);

	const handleAssign = () => {
		if(isBlocked.status){
			return
		}
		setLoading(true);
		assignHint(hint, () => setLoading(false));
	};

	return (
		<button type={'button'} className="result" onClick={handleAssign} disabled={loading}>
			{hint.name}
		</button>
	);
};

export default DisplayRepeater;
