import React, {useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import useUser from "../../../Hooks/ReduxHooks/useUser";

const FloatingButtonsTooltip = ({children, tooltipValue, linkHref, clickFunc}) => {

	const childrenTooltipRef = useRef()
	const [visibleTooltipContent, setVisibleTooltipContent] = useState(false)
	const { t } = useTranslation();
	const {user} = useUser();
	const handleMouseEnter = () => {
		if(user){
			setVisibleTooltipContent(true)
		}
	}

	const handleMouseLeave = () => {
		setVisibleTooltipContent(false)
	}

	const handleClick = () => {
		if(user){
			setVisibleTooltipContent(prevState => !prevState)
		}
	}

	return(
		<div className="position-relative w-100 mb-1 floating-buttons-tooltip" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<div ref={childrenTooltipRef} className="position-relative" style={{zIndex: 2}} onClick={handleClick}>
				{children}
			</div>
			{visibleTooltipContent &&
				<div className={`position-absolute white-space-nowrap tooltip-content me-1`} style={{right: childrenTooltipRef.current ? childrenTooltipRef.current.offsetWidth : ''}} onClick={clickFunc}>
					<p className="mb-0">
						{linkHref ?
							<a href={linkHref}>
								{tooltipValue}
							</a>
							:
							<>{tooltipValue}</>
						}
					</p>

				</div>
			}

		</div>
	)
}

export default FloatingButtonsTooltip;