import AuthHeader from "../Parts/AuthHeader";
import AuthFooter from "../Parts/AuthFooter";
import ForgotForm from "./ForgotForm";

const Forgot = ({setAuthType}) => {
	return(
		<>
			<AuthHeader title="Don't remember your password?" subtitle="Recover your account password by entering your email" />
			<ForgotForm setAuthType={setAuthType} />
			<AuthFooter />
		</>
	)
}

export default Forgot;