import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import FreeRepeater from '../Common/FreeRepeater';
import useConfig from "../../../../../Hooks/ReduxHooks/useConfig";
import { useFormikContext } from "formik";
import { firstLetterUpper } from "../../../../../utils/AppUtils";
import { Plus, X } from "react-feather";
import AnimatedDropdown from "../../../../Parts/AnimatedDropdown";
import CloseOutside from "../../../../Parts/CloseOutside";
import { Col, Form, Row } from "react-bootstrap";
import { IoMdArrowDropdown } from "react-icons/io";
import ProfileError from "../../Parts/ProfileError";

const TabSkills = () => {
	const { t } = useTranslation();
	const { config } = useConfig();
	const { setFieldValue, values } = useFormikContext();

	const [selectedLanguage, setSelectedLanguage] = useState('');
	const [selectedLevel, setSelectedLevel] = useState('');
	const [languageError, setLanguageError] = useState(null);
	const [levelError, setLevelError] = useState(null);
	const [languageDropdownActive, setLanguageDropdownActive] = useState(false);
	const [levelDropdownActive, setLevelDropdownActive] = useState(false);
	const [searchLanguage, setSearchLanguage] = useState('');

	const errorsConfig = {
		language: 'Language is required',
		level: 'Level is required',
		languageExist: 'You have already chosen the language'
	};

	const handleAddLanguage = (e) => {
		e.preventDefault();
		setLanguageError(null);
		setLevelError(null);

		if (!selectedLanguage) {
			setLanguageError('language');
			return;
		}
		if (!selectedLevel) {
			setLevelError('level');
			return;
		}

		const newLanguage = { language: selectedLanguage.toLowerCase(), level: selectedLevel };

		const isDuplicate = values.languages.some(
			lang => lang.language === newLanguage.language
		);

		if (isDuplicate) {
			setLanguageError('languageExist');
			return;
		}

		setFieldValue('languages', [...values.languages, newLanguage]);
		setSelectedLanguage('');
		setSelectedLevel('');
	};

	const handleDeleteLanguage = (lang) => {
		const newLanguages = values.languages.filter(item => item.language !== lang);
		setFieldValue('languages', newLanguages);
	};

	const handleClick = (type, value) => {
		if (type === 'language') {
			setSelectedLanguage(value);
			setLanguageDropdownActive(false);
			setLanguageError(null);
		} else if (type === 'level') {
			setSelectedLevel(value);
			setLevelDropdownActive(false);
			setLevelError(null);
		}
	};

	const filteredLanguages = config.languages.items.filter(language =>
		language.value.toLowerCase().includes(searchLanguage.toLowerCase())
	);

	return (
		<>
			<FreeRepeater
				title={t('profile.profile.tabs.profileTab.index.sections.skills.title')}
				buttonTitle={t('profile.profile.tabs.profileTab.index.sections.skills.buttonTitle')}
				section={'skills'}
			/>
			<div className="d-flex align-items-center mt-4 mb-2 position-relative repeater-title">
				<h4 className="m-0 title">Languages</h4>
			</div>
			<div className="profile-repeater-wrapper">
				<div className="d-flex flex-wrap">
					{values.languages.map((language, index) => (
						<span key={index} className="repeater-pill font-family-open-sans">
							{firstLetterUpper(language.language)} {firstLetterUpper(language.level)}
							<X size={16} onClick={() => handleDeleteLanguage(language.language)} />
						</span>
					))}
				</div>
				<div className="d-flex justify-content-between mt-2 align-items-center">
					<Row className="w-100 me-2">
						<Col md={6}>
							<div className="position-relative">
								<button type="button" className={`position-relative add-language w-100 h-100 text-gray-444 d-flex align-items-center justify-content-center ${languageError ? 'border border-danger' : ''}`} onClick={() => setLanguageDropdownActive(prevState => !prevState)}>
									{selectedLanguage ? firstLetterUpper(selectedLanguage) : 'Select Language'}
									<IoMdArrowDropdown size={18} className={`rotate-animation ${languageDropdownActive ? 'active' : ''}`} />
									<ProfileError profileError={languageError && (errorsConfig[languageError] || '')} additionalStyles={'position-absolute end-0 me-3 top-50 translate-middle-y'} />
								</button>
							</div>
							<div className="position-relative">
								<CloseOutside handleClose={() => setLanguageDropdownActive(false)}>
									<AnimatedDropdown isOpen={languageDropdownActive} className="w-100 mt-2">
										<Form.Control className="mb-2" type="text" placeholder="Search Language" value={searchLanguage} onChange={(e) => setSearchLanguage(e.target.value)}/>
										<ul style={{ maxHeight: '300px' }} className="overflow-y-scroll overflow-x-hidden">
											<Row>
												{filteredLanguages.map((language, index) => (
													<Col md={4} key={index}>
														<li onClick={() => handleClick('language', language.value)}>
															{language.value}
														</li>
													</Col>
												))}
											</Row>
										</ul>
									</AnimatedDropdown>
								</CloseOutside>
							</div>
						</Col>
						<Col md={6} className={'mt-3 m-md-0'}>
							<div className="position-relative">
								<button type="button" className={`position-relative add-language w-100 h-100 text-gray-444 d-flex align-items-center justify-content-center ${levelError ? 'border border-danger' : ''}`} onClick={() => setLevelDropdownActive(prevState => !prevState)}>
									{selectedLevel ? firstLetterUpper(selectedLevel) : 'Select Level'}
									<IoMdArrowDropdown size={18} className={`rotate-animation ${levelDropdownActive ? 'active' : ''}`} />
									<ProfileError profileError={levelError && (errorsConfig[levelError] || '')} additionalStyles={'position-absolute end-0 me-3 top-50 translate-middle-y'}/>
								</button>
							</div>
							<div className="position-relative">
								<CloseOutside handleClose={() => setLevelDropdownActive(false)}>
									<AnimatedDropdown isOpen={levelDropdownActive} className="w-100 mt-2">
										<ul>
											<Row>
												{config.languages.additional.levels.map((level, index) => (
													<Col md={4} key={index}>
														<li className="d-flex justify-content-center" onClick={() => handleClick('level', level.key)}>
															{level.value}
														</li>
													</Col>
												))}
											</Row>
										</ul>
									</AnimatedDropdown>
								</CloseOutside>
							</div>
						</Col>
					</Row>
					<button className="btn btn-circle btn-primary flex-shrink-0" onClick={handleAddLanguage}>
						<Plus />
					</button>
				</div>
			</div>
		</>
	);
};

export default TabSkills;
