const getMapZoom = (radius) => {

	if(radius < 6) {

		return 12

	}

	if(radius < 12) {

		return 11

	}

	if(radius < 24) {

		return 10

	}

	if(radius < 49) {

		return 9

	}

	if(radius < 97) {

		return 8

	}

	if(radius < 140){
		return 7
	}
	return 6
}

export default getMapZoom