import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {fetchAuthSession} from "aws-amplify/auth";

const initialState = {
	isSocketActive: false,
	handlers: {},
	socket: null,
	error: null,
};

export const connectWebSocket = createAsyncThunk(
	'webSocket/connect',
	async (_, { dispatch, getState }) => {
		let accessToken = "";

		try {
			const { tokens } = await fetchAuthSession();
			accessToken = tokens.accessToken;
		} catch (err) {
			throw new Error("WebSocket auth error: " + err.message);
		}

		const socketUrl = `${process.env.REACT_APP_CHAT_SOCKET}?token=${accessToken}`;
		const socket = new WebSocket(socketUrl);

		socket.onopen = () => {
			console.log("WebSocket connected");
			dispatch(setIsSocketActive(true));
			setInterval(() => {
				socket.send(JSON.stringify({action: 'ping'}))
			}, 1000 * 30)
		};

		socket.onclose = () => {
			dispatch(setIsSocketActive(false));
			setTimeout(() => {
				dispatch(connectWebSocket());
			}, 1000);
		};

		socket.onerror = (error) => {
			console.error('WebSocket error:', error);
			dispatch(setIsSocketActive(false));
			dispatch(setError(error.message));
		};

		socket.onmessage = (event) => {
			const data = JSON.parse(event.data);
			const actionHandlers = getState().webSocket.handlers[data.action] || [];
			actionHandlers.forEach(handler => handler(data));
		};

		return socket;
	}
);

const webSocketSlice = createSlice({
	name: 'webSocket',
	initialState,
	reducers: {
		setIsSocketActive(state, action) {
			state.isSocketActive = action.payload;
		},
		setError(state, action) {
			state.error = action.payload;
		},
		registerHandler(state, action) {
			const { actionType, handler } = action.payload;
			if (!state.handlers[actionType]) {
				state.handlers[actionType] = [];
			}
			state.handlers[actionType].push(handler);
		},
		unregisterHandler(state, action) {
			const { actionType, handler } = action.payload;
			if (state.handlers[actionType]) {
				state.handlers[actionType] = state.handlers[actionType].filter(h => h !== handler);
			}
		},
		setSocket(state, action) {
			state.socket = action.payload;
		},
		sendAction(state, action) {
			const message = action.payload;
			if (state.socket && state.socket.readyState === WebSocket.OPEN) {
				state.socket.send(message);
			} else {
				console.error('WebSocket is not open. Unable to send message:', message);
			}
		},
	},
	extraReducers: (builder) => {
		builder.addCase(connectWebSocket.fulfilled, (state, action) => {
			state.socket = action.payload;
		});
		builder.addCase(connectWebSocket.rejected, (state, action) => {
			state.error = action.error.message;
		});
	}
});

export const {
	setIsSocketActive,
	setError,
	registerHandler,
	unregisterHandler,
	setSocket,
	sendAction,
} = webSocketSlice.actions;

export default webSocketSlice.reducer;
