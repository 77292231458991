import React from 'react';
import {useTranslation} from 'react-i18next';
import useUser from "../../../Hooks/ReduxHooks/useUser";

const Logout = () => {
	const {handleLogout, handleGlobalLogout} = useUser();
	const { t } = useTranslation();

	return(
		<>
			<h3 className={'font-xxxl'}>{t('profile.profile.tabs.settingsTab.logout.title')}</h3>
			<button className="mt-3 reset-profile-button btn-has-loader d-flex btn btn-primary text-uppercase" onClick={handleLogout}>{t('profile.profile.tabs.settingsTab.logout.logout')}</button>
			<button className="mt-3 reset-profile-button btn-has-loader d-flex btn btn-primary text-uppercase" onClick={handleGlobalLogout}>Log out from all devices</button>
		</>
	)
}

export default Logout;