import {useTranslation} from 'react-i18next';
import {Form} from 'react-bootstrap';
import React, {useEffect, useState} from 'react';
import AnimatedDropdown from '../../../Parts/AnimatedDropdown';
import CloseOutside from '../../../Parts/CloseOutside';
import CloseIcon from '../../../Parts/CloseIcon';
import useUser from '../../../../Hooks/ReduxHooks/useUser';
import {Folder, Search, Tag, X} from 'react-feather';
import useConfig from '../../../../Hooks/ReduxHooks/useConfig';
import {useInstantSearch, useRefinementList, useSearchBox} from 'react-instantsearch';
import {VirtualRefinementList} from '../../../Browse/Filters/FiltersParts';
import {FaFilter} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import Location from './Location';
import {ButtonBrowse} from './Search';

const ActiveFiltersConfig = {
	tag: {
		icon: <Tag size={14} className={'badge-icon'}/>,
		className: 'badge badge-tag'
	},
	cat: {
		icon: <Folder size={14} className={'badge-icon'}/>,
		className: 'badge badge-cat'
	},
	query: {
		icon: <Search size={14} className={'badge-icon'}/>,
		className: 'badge badge-query'
	}
};

const QueryInput = ({query, setQuery, setVisible, queryRef}) => {

	const {t} = useTranslation();

	const searchBox = useSearchBox();

	const navigate = useNavigate();

	const handleChange = (e) => {

		setQuery(e.target.value);

	};

	const handleKeyDown = (e) => {

		if(e.key === 'Enter') {

			searchBox.refine(query);

			const currentParams = new URLSearchParams(location.search);

			navigate(`/?${currentParams.toString()}`);

		}

	};

	return (
		<Form.Control placeholder={t('jobs.browse.search.query.placeholder')} ref={queryRef} onKeyDown={handleKeyDown} value={query} onFocus={() => setVisible(true)} onChange={handleChange}/>
	);
};

const ActiveFilters = ({shorted = false}) => {

	const {t} = useTranslation();
	const {config} = useConfig();
	const {indexUiState, setIndexUiState} = useInstantSearch();

	const handleRemove = (item) => {

		setIndexUiState((prev) => {

			if(item.type === 'query') {

				const {query, ...rest} = prev;

				return rest;

			}

			return {
				...prev,
				refinementList: {
					...prev.refinementList,
					[item.attribute]: prev.refinementList?.[item.attribute].filter((i) => i !== item.id)
				}

			};
		});

	};

	const active = (indexUiState.refinementList?.categories ?? []).map((category) => {

		const configCategory = config.categories.items.find(cat => cat.slug === category);

		return {
			type: 'cat',
			id: category,
			title: configCategory.title,
			attribute: 'categories'
		};

	});

	const tags = (indexUiState.refinementList?.tags ?? []).map((tag) => {

		return {
			type: 'tag',
			id: tag,
			title: tag,
			attribute: 'tags'
		};

	});

	active.push(...tags);

	if('query' in indexUiState && indexUiState.query !== undefined) {

		active.push({
			type: 'query',
			id: 'query',
			title: indexUiState.query
		});

	}

	if(active.length === 0) {
		return <></>;
	}

	if(shorted === true) {
		return (
			<>
				<div className="badge-filters"><FaFilter/> {active.length}</div>
			</>
		);
	}
	else {

		const Badge = ({item}) => {
			return (
				<span className={ActiveFiltersConfig[item.type].className}>{ActiveFiltersConfig[item.type].icon}{item.title}<X onClick={() => handleRemove(item)} size={16} className={'icon-remove'}/></span>
			);
		};

		return (
			<>
				<h5>{t('jobs.browse.search.autocomplete.selected')}</h5>
				<div className="search-badges search-badges-active">
					{active.map((item, index) => <Badge key={index} item={item}/>)}
				</div>
			</>
		);
	}

};

const QueryRefinement = ({query, attribute, limit = 99999}) => {

	const {items} = useRefinementList({attribute: attribute, limit: limit});

	const {t} = useTranslation();

	const {config} = useConfig();

	const {indexUiState, setIndexUiState} = useInstantSearch();

	/**
	 *
	 * Filter categories
	 *
	 */

	let filtered = items;
	if(attribute === 'categories') {
		filtered = filtered.map((item) => config.categories.items.find(i => i.slug === item.value)).map((item) => ({
			title: item.title,
			id: item.slug
		}));
		filtered = filtered.filter((item) => item?.title.toLowerCase().includes(query.toLowerCase()));
	}
	else {
		filtered = filtered.map((item) => ({title: item.label, id: item.value}));
	}

	filtered = filtered.filter((item) => !(indexUiState.refinementList?.[attribute] ?? []).includes(item.id));

	const handleClick = (item) => {

		setIndexUiState((prev) => {
			return {
				...prev,
				refinementList: {
					...prev.refinementList,
					[attribute]: [
						...(prev.refinementList?.[attribute] ?? []),
						item.id
					]
				}
			};
		});

	};

	if(filtered.length === 0) {

		return null;

	}

	return (
		<>
			<h5>{t(`browse.top.taxonomy.title.${attribute}`)}</h5>
			<div className={`search-badges search-badges-${attribute}`}>
				{filtered.map((item, index) =>
					<span key={index} className={'badge'} onClick={() => handleClick(item)}>{item.title}</span>)}
			</div>
		</>
	);

};

const Query = ({queryRef}) => {

	const {isMobile} = useUser();

	const [visible, setVisible] = useState(false);

	const {indexUiState} = useInstantSearch();

	const [query, setQuery] = useState('query' in indexUiState && indexUiState.query !== undefined ? indexUiState.query : '');

	useEffect(() => {

		setQuery('query' in indexUiState && indexUiState.query !== undefined ? indexUiState.query : '')

	}, [indexUiState]);

	useEffect(() => {
		if(isMobile){
			visible ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'visible';
		}
	}, [visible])

	return (
		<CloseOutside handleClose={() => setVisible(false)}>
			<VirtualRefinementList attribute="categories"/>
			<VirtualRefinementList attribute="tags"/>

			<div className={'search-taxonomy'}>
				<div className={`search-taxonomy-bar ${visible ? 'active' : ''}`} onClick={() => setVisible(true)}>
					{!isMobile && <ActiveFilters shorted={true}/>}
					<QueryInput query={query} setQuery={setQuery} setVisible={setVisible} queryRef={queryRef}/>
				</div>
				<AnimatedDropdown isOpen={visible} className={`dropdown-taxonomy ${isMobile ? 'mobile' : ''}`}>
					{isMobile && <QueryInput query={query} setQuery={setQuery} setVisible={setVisible}/>}
					{isMobile && <Location/>}
					<ActiveFilters/>
					<QueryRefinement query={query} attribute={'categories'}/>
					<QueryRefinement query={query} attribute={'tags'} limit={15}/>
					{isMobile && <ButtonBrowse queryRef={queryRef} setVisible={setVisible}/>}
					{isMobile && <CloseIcon handleClose={() => setVisible(false)} additionalStyles="mt-3 me-3"/>}
				</AnimatedDropdown>
			</div>
		</CloseOutside>
	);

};

export default Query;