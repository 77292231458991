import {toast} from 'react-toastify';

const NotificationComponent = ({title, content}) => {

	return (
		<>
			<h6>{content}</h6>
			<p>{title}</p>
		</>
	)
}

class Notification{
	static success = (title, content) => {
		return toast(<NotificationComponent title={title} content={content} />, {type: 'success'});
	}

	static error = (title, content) => {
		return toast(<NotificationComponent title={title} content={content} />, {type: 'error'});
	}

	static warn = (title, content) => {
		return toast(<NotificationComponent title={title} content={content} />, {type: 'warn'});
	}

	static info = (title, content) => {
		return toast(<NotificationComponent title={title} content={content} />, {type: 'info'});
	}
}

export default Notification;