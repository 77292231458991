import React, {useState} from 'react';
const SwipeContainer = ({ children, className, onSwipeLeft = () => {}, onSwipeRight = () => {}, onSwipeUp = () => {}, onSwipeDown = () => {} }) => {
	const [touchStartX, setTouchStartX] = useState(null);
	const [touchEndX, setTouchEndX] = useState(null);
	const [touchStartY, setTouchStartY] = useState(null);
	const [touchEndY, setTouchEndY] = useState(null);
	const minSwipeDistance = 50;

	const onTouchStart = (e) => {

		setTouchEndX(null);

		setTouchEndY(null);

		setTouchStartX(e.touches[0].clientX);

		setTouchStartY(e.touches[0].clientY);
	};

	const onTouchMove = (e) => {

		setTouchEndX(e.touches[0].clientX);

		setTouchEndY(e.touches[0].clientY);

	};

	const onTouchEnd = (e) => {
		if (!touchStartX || !touchEndX || !touchStartY || !touchEndY || window.innerHeight - touchStartY < 100) return;

		const distanceX = touchStartX - touchEndX;
		const distanceY = touchStartY - touchEndY;
		const isLeftSwipe = distanceX > minSwipeDistance;
		const isRightSwipe = distanceX < -minSwipeDistance;
		const isUpSwipe = distanceY > minSwipeDistance;
		const isDownSwipe = distanceY < -minSwipeDistance;

		if (isLeftSwipe) {
			onSwipeLeft(e);
		}

		else if (isRightSwipe) {
			onSwipeRight(e);
		}

		else if (isUpSwipe) {
			onSwipeUp(e);
		}

		else if (isDownSwipe) {
			onSwipeDown(e);
		}
	};

	return (
		<div id="popup-items" className={className} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} onClick={(e) => e.preventDefault()}>
			{children}
		</div>
	);
};

export default SwipeContainer;