import React from "react";
import {Edit} from 'react-feather';
import {Link} from 'react-router-dom';
import FloatingButtonsTooltip from '../../Parts/FloatingButtons/FloatingButtonsTooltip';

import {useTranslation} from 'react-i18next';

const Floating = () => {

	const { t } = useTranslation();

	const BtnEditProfile = () => {

		return (
			<FloatingButtonsTooltip tooltipValue={t('parts.floatingButtonsTooltip.tooltipValues.editProfile')}>
				<Link to={`/account/edit`} className="floating-button">
					<Edit/>
				</Link>
			</FloatingButtonsTooltip>
		);

	};

	return (
		<div className={'fixed-single'}>
			<div className={'fixed-single-wrapper'}>
				<BtnEditProfile/>
			</div>
		</div>

	);

};

export default Floating;
