import React from 'react';
import {Helmet} from 'react-helmet';
import {useTranslation} from 'react-i18next';

const rest = ' - Job Multiverse!';

export const Page = ({title, children}) => {
	return (
		<>
			<Helmet>
				<title>{``} {title}{rest}</title>
			</Helmet>
			{children}
		</>
	);

};

export const Head = () => {
	const { t } = useTranslation();

	const Default = `Job Multiverse! - ${t('parts.page.default')}`;

	return (
		<Helmet>
			<meta charSet="utf-8"/>
			<title>{Default}</title>
		</Helmet>
	);

}