// TODO: remove this
const ValidationError = ({additionalClass = "", errorMessage, isErrorVisible, guideID}) => {
	if(!isErrorVisible){
		return <></>
	}
	return(
		<p className={`error-message ${additionalClass}`} id={guideID}>
			{errorMessage}
		</p>
	)
}

export default ValidationError;