import useMessage from "../../../../Hooks/ReduxHooks/useMessage";
import SkeletonMessagesContent from "../../Skeleton/SkeletonMessagesContent";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import React, {useEffect, useRef, useState} from "react";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import {Alert, Tooltip} from "react-bootstrap";
import timestampToDate from "../../../../utils/timestampToDate";
import NewMessagePopup from "../../Helpers/NewMessagePopup";
import DrawDateDivider from "../../Helpers/DrawDateDivider";
import ArrowScrollDown from "../../Helpers/ArrowScrollDown";
import MoreHistoryPopup from "../../Helpers/MoreHistoryPopup";

const Message = ({message, messageIndex, setMoreHistoryActive}) => {
	const renderTooltip = (props, message) => {
		return (
			<Tooltip id="button-tooltip" {...props}>
				{timestampToDate(Number(message.time), localStorage.getItem('i18nextLng'))}
			</Tooltip>
		);
	};

	const {user} = useUser()
	const timestamp = message.time;
	const {conversationHistory, conversationData} = useMessage();
	const currentDate = new Date();
	const timestampDate = new Date(timestamp);
	const differenceInMilliseconds = currentDate - timestampDate;
	const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

	if (differenceInDays >= 30) {
		setMoreHistoryActive(true)
		return null;
	}

	return (
		<div>
			<div className={`mb-3 mt-3 d-flex ${message.senderId === user.id && 'w-100 justify-content-end'} ${message.senderId !== user.id && 'justify-content-start align-items-end'}`}>
				<OverlayTrigger trigger={['hover', 'focus', 'click']} placement="top" overlay={(props) => renderTooltip(props, message)} delay={{ show: 100, hide: 100 }}>
					<p className={`rounded-4 m-0 ps-3 pe-3 pt-2 pb-2 font-lg text-break white-space-pre-line ${message.senderId === user.id && 'bg-primary text-light'} ${message.senderId !== user.id && 'message d-flex justify-content-between align-items-center'}`}>
						{message.text}
					</p>
				</OverlayTrigger>
			</div>
			<DrawDateDivider messages={conversationHistory.items} messageIndex={messageIndex} />
		</div>
	)
};

const ChatContent = ({endOfMessagesRef, scrollToBottom, chatContainerRef}) => {
	const {conversationHistory, loadings, getConversationHistory, handleSetIsNewMessage} = useMessage();
	const [isScrollBottom, setIsScrollBottom] = useState(false);
	const [scrollPosition,setScrollPosition] = useState();
	const [moreHistoryActive, setMoreHistoryActive] = useState(false);

	useEffect(() => {
		const handleScroll = () => {
			if (chatContainerRef.current) {
				const {scrollTop, scrollHeight, clientHeight} = chatContainerRef.current;
				const isBottom = scrollHeight - scrollTop <= clientHeight * 1.15;
				if (scrollTop === 0 && conversationHistory.last && !loadings.conversationHistoryPagination) {
					setScrollPosition(scrollHeight - scrollTop);
					chatContainerRef.current.style.overflow = 'hidden';
					getConversationHistory(conversationHistory.last)
				}
				if(isBottom){
					handleSetIsNewMessage(false);
					setIsScrollBottom(false);
				}
				if(!isBottom){
					setIsScrollBottom(true);
				}
			}
		};

		const container = chatContainerRef.current;
		if (container) {
			container.addEventListener('scroll', handleScroll);
		}

		return () => {
			if (container) {
				container.removeEventListener('scroll', handleScroll);
			}
		};
	}, [conversationHistory.last])

	useEffect(() => {
		scrollToBottom()
	}, [endOfMessagesRef.current]);

	useEffect(() => {
		const {scrollTop, scrollHeight, clientHeight} = chatContainerRef.current;
		const isBottom = scrollHeight - scrollTop <= clientHeight * 1.15;
		if(isBottom){
			scrollToBottom();
		}
	}, [conversationHistory]);

	useEffect(() => {
		if(!loadings.conversationHistoryPagination){
			chatContainerRef.current.style.overflow = 'auto';
			chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight - scrollPosition;
		}
	}, [loadings.conversationHistoryPagination])

	return(
		<div ref={chatContainerRef} className="ms-4 me-4 pe-4 message-content">
			{moreHistoryActive && <MoreHistoryPopup />}
			<NewMessagePopup scrollToBottom={scrollToBottom} isActive={isScrollBottom} />
			{loadings.conversationHistoryPagination && <SkeletonMessagesContent />}
			{conversationHistory.items?.length === 0 && !loadings.conversationHistory && <Alert variant="info" className="mt-2">No message</Alert>}
			{loadings.conversationHistory ?
				<SkeletonMessagesContent />
				:
				<div>
					<>{conversationHistory.items?.map((message, index) => <React.Fragment key={index}><Message setMoreHistoryActive={setMoreHistoryActive} message={message} messageIndex={index} /></React.Fragment>)}<div ref={endOfMessagesRef} /></>
				</div>
			}
			<ArrowScrollDown scrollToBottom={scrollToBottom} isActive={isScrollBottom}/>
		</div>
	)
}

export default ChatContent;