const LoadingProgressBar = ({ progress, additionalStyles }) => {

	return(
		<div className={`loading-progress-bar-wrapper-active ${additionalStyles}`}>
			<div className="loading-progress-bar bg-primary" style={{ width: `${progress}%` }}>
			</div>
		</div>
	)
}

export default LoadingProgressBar;