import React from 'react';
import {useTranslation} from 'react-i18next';

const ExperienceFreelanceItem = ({items}) => {

	return(
		<>
			{items.map((item, index) => <li className="font-family-open-sans" key={index}>{item.from} - {item.to} <span></span> {item.value}</li>)}
		</>
	)
}

const DisplayExperience = ({experience}) => {

	const { t } = useTranslation();

	if(!Array.isArray(experience) || experience.length === 0) {

		return <></>

	}

	return(
		<>
			<div className={'d-flex align-items-center mt-4 mb-2 position-relative'}>
				<h2>{t('jobs.single.type.freelancer.freelancer.titles.experience')}</h2>
			</div>
			<div className={'d-flex flex-wrap'}>
				<ul className="experience-list">
					<ExperienceFreelanceItem items={experience}/>
				</ul>
			</div>
		</>
	)
}

export default DisplayExperience;
