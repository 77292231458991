import React, {useEffect, useRef} from "react";

const Map = () => {

	const items = [];

	const mapRef = useRef();

	useEffect(() => {

		if(mapRef.current !== null && window.google) {

			window.GoogleLocationMap = new window.google.maps.Map(
				mapRef.current,
				{

					zoom: 6,
					center: {
						lat: 51,
						lng: 19
					},
					disableDefaultUI: true
				}
			);

			items.map(item => {

				if(Array.isArray(item.locations) && item.locations.length > 0) {

					item.locations.map((location, index) => {

						new window.google.maps.Marker({
							position: {lat: location.lat, lng: location.lon},
							label: item.locations_names[index],
							map: window.GoogleLocationMap
						});

					});

				}

			});

		}

	}, [items]);

	return (
		<div className={'app-browse-map'}>
			<div className={'map'} id={'map'} ref={mapRef}></div>
		</div>
	);

};

export default Map;
