import {useBlocker} from 'react-router-dom';
import {useEffect} from 'react';
import CloseOutside from '../../Parts/CloseOutside';
import {useTranslation} from 'react-i18next';
import {useFormikContext} from "formik";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";

const UnsavedChangesPopup = ({when, handleSubmit, isValid}) => {

	const {openPopup, closePopup} = usePopup();

	const {values, dirty} = useFormikContext();

	const { t } = useTranslation();

	let blocker = useBlocker(
		({ currentLocation, nextLocation }) =>
			when &&
			currentLocation.pathname !== nextLocation.pathname
	);

	useEffect(() => {
		if(blocker.state === 'blocked'){
			openPopup(UnsavedChangesPopupContent)
		}
		else{
			closePopup()
		}
	}, [when, blocker]);

	useEffect(() => {

		const handleBeforeUnload = (event) => {

			if (dirty) {

				event.returnValue = t('parts.unloadWarning.text');

				return t('parts.unloadWarning.text');

			}

		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {

			window.removeEventListener('beforeunload', handleBeforeUnload);

		};

	}, [dirty]);

	const handleLeaveClick = () => {
		closePopup();
		blocker.proceed();
	}

	const handleSaveAndLeave = () => {
		closePopup();
		blocker.proceed();
		handleSubmit(values)
	}

	const UnsavedChangesPopupContent = () => {
		return(
			<CloseOutside handleClose={() => blocker.reset()}>
				<div className="bg-primary pt-3 pb-3 pe-4 ps-4 rounded-4">
					<h3 className="text-light font-xl">
						{t('parts.misc.unsavedChangesPopup.title')}
					</h3>
					<div className="d-flex justify-content-center mt-3">
						<button className="btn btn-outline-secondary me-2 pt-1 pb-1 pe-3 ps-3" onClick={handleSaveAndLeave} disabled={!isValid}>
							Save and leave
						</button>
						<button className="btn btn-outline-secondary pt-1 pb-1 pe-3 ps-3" onClick={handleLeaveClick}>
							Leave without saving
						</button>
					</div>
				</div>
			</CloseOutside>
		)
	}
}

export default UnsavedChangesPopup;