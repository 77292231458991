import PrevLocationBack from '../Parts/PrevLocationBack';
import { useTranslation } from 'react-i18next';
import useUser from "../../Hooks/ReduxHooks/useUser";
import { Element } from 'react-scroll';
import { Container } from "react-bootstrap";

const TopBar = ({ title, desc, percentPopup = false, backButton = false, radius = false }) => {
	const { user, isMobile } = useUser();
	const { t } = useTranslation();

	const CompletedProfile = () => {
		if (!percentPopup) return null;

		return (
			<div className="my-completed-profile">
				<div className="bar" style={{ width: `${user.percent}%` }}>
					<span className="text">
						{t('profile.profile.edit.percentPopup', { percent: user.percent })}
					</span>
				</div>
			</div>
		);
	};

	return (
		<Element name={percentPopup ? 'top-bar-my-profile-scroll' : ''}>
			<div className={`${isMobile ? 'rounded-0' : ''} d-flex justify-content-between flex-column flex-md-row top-bar align-items-md-center align-items-start ${radius ? 'my-profile' : ''}`}>
				<div className="d-flex align-items-center flex-wrap w-100">
					{backButton && <PrevLocationBack />}
					<h3 className="mb-0 me-5">{title}</h3>
					<CompletedProfile />
				</div>
				<p className="mb-0 mt-md-0 mt-2 font-family-open-sans">{desc}</p>
			</div>
		</Element>
	);
};

export default TopBar;
