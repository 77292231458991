import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const DisplayDesc = ({ desc, title }) => {
	const { t } = useTranslation();
	const [showMore, setShowMore] = useState(false);

	const maxLength = 250;

	const handleToggle = () => {
		setShowMore(prevState => !prevState);
	};

	const textToDisplay = showMore ? desc : (desc?.length > maxLength ? desc.slice(0, maxLength) + '...' : desc);

	return (
		<div className="desc-popup">
			<h2>{title}</h2>
			<p className='mb-0 font-family-open-sans'>
				{desc ? textToDisplay : t('jobs.single.type.common.displayDesc')}
			</p>
			{desc && desc.length > maxLength && (
				<button onClick={handleToggle} className="btn btn-primary font-md pt-1 pb-1 ps-2 pe-2 mt-2">
					{showMore ? 'Show Less' : 'Show More'}
				</button>
			)}
		</div>
	);
}

export default DisplayDesc;
