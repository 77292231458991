import React from "react";
import AppConfig from '../../../../Config/AppConfig';
import {useTranslation} from 'react-i18next';
import {firstLetterUpper} from "../../../../utils/AppUtils";

export const MetaList = ({items, itemType, translationName}) => {
	const { t } = useTranslation();
	if(items === null){
		return <></>
	}
	return items.map((p, index) =>
		<li key={index} className="font-family-open-sans">
			{typeof p === 'number' && t(`config.appConfig.job.${translationName}.${itemType[p]}`)}
			{typeof p.value === 'object' && p.name === 'experience'
			&& <>{p.value.from} - {p.value.to}, {p.value.value}</>
			}
			{typeof p.value === 'string' && <>{p.value}</>}
			{typeof p === 'string' && <>{p}</>}
		</li>
	);

};

const Section = ({title, items, type}) => {
	const contract = AppConfig.job.contract;

	const mode = AppConfig.job.working_mode;

	const time = AppConfig.job.working_time

	if(items === undefined || items.length === 0 || (type === 'expectations' && ((!items.time || items.time.length === 0) && (!items.mode || items.mode.length === 0) && (!items.contract || items.contract.length === 0)))) {

		return <></>

	}

	const DisplayMeta = ({items, section, translationName}) => {

		return (
			<div className="d-flex flex-wrap">
				<MetaList items={section ? items : items.map((el, index) => <React.Fragment key={index}>{el}</React.Fragment>)} itemType={section} translationName={translationName}/>
			</div>
		)

	}

	const DisplayLanguage = (items) => {
		return(
			<>
				{items.items.map((item, index) => (
					<li key={index} className="font-family-open-sans">
						{firstLetterUpper(item.language)} {firstLetterUpper(item.level)}
					</li>
				))}
			</>
		)
	}
	return (
		<>
			<div className={'d-flex align-items-center mt-4 mb-2 position-relative'}>
				<h2>{title}</h2>
			</div>
			<div className={'d-flex flex-wrap'}>
				<ul>
					{(type !== 'expectations' && type !== 'languages') && <MetaList items={items}/>}
					{type === 'expectations' &&
						<div className="d-flex flex-column">
							<DisplayMeta items={items.mode ? items.mode.map(el => Number(el)) : []} section={mode} translationName={'working_mode'}/>
							<DisplayMeta items={items.time ? items.time.map(el => Number(el)) : []} section={time} translationName={'working_time'}/>
							<DisplayMeta items={items.contract ? items.contract.map(el => Number(el)) : []} section={contract} translationName={'contract'}/>
						</div>
					}
					{type === 'languages' && <DisplayLanguage items={items} />}
				</ul>
			</div>
		</>

	);

};

export default Section;
