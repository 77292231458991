import React, { useState } from "react";
import GalleryImages from './GalleryImages';
import { Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Lightbox from "./Lightbox";

const Gallery = ({ gallery }) => {
	const { t } = useTranslation();
	const [selectedImage, setSelectedImage] = useState(null);
	const [opened, setOpened] = useState(false);

	const handleClickImage = (item) => {
		setSelectedImage(item);
		setOpened(true);
	};

	const handleCloseLightbox = () => {
		setOpened(false);
	};

	if (!gallery || gallery.length === 0) {
		return (
			<div className={'empty'}>
				<Alert variant={'info'}>{t('jobs.single.type.parts.gallery.alert')}</Alert>
			</div>
		);
	}

	return (
		<>
			<GalleryImages gallery={gallery} handleClickImage={handleClickImage} />
			{selectedImage && (
				<Lightbox
					images={gallery}
					initialIndex={gallery.indexOf(selectedImage)}
					opened={opened}
					onClose={handleCloseLightbox}
				/>
			)}
		</>
	);
};

export default Gallery;
