import AuthHeader from "../Parts/AuthHeader";
import AuthFooter from "../Parts/AuthFooter";
import ForgotVerifyForm from "./ForgotVerifyForm";
import {resetPassword} from "aws-amplify/auth";
import {useState} from "react";
import useCountdown from "../../../Hooks/useCountdown";
import {hideEmail} from "../../../utils/AppUtils";

const ForgotVerify = ({setAuthType}) => {
	const [verifyCodeResend, setVerifyCodeResend] = useState({
		status: false,
		text: ''
	});
	const { startCountdown, timeLeft, isTimeoutRemaining } = useCountdown();

	const buttonHandleClick = async () => {
		if(!localStorage.getItem('forgot-email')){
			setVerifyCodeResend(false)
			return
		}
		try{
			await resetPassword({username: localStorage.getItem('forgot-email')});
			startCountdown(20);
			setVerifyCodeResend({
				status: false,
				text: `The code has been sent to your email: ${hideEmail(localStorage.getItem('forgot-email'))}`
			})
		}
		catch(err){
			setVerifyCodeResend({
				status: true,
				text: err.message
			});
		}

	}

	return(
		<>
			<AuthHeader title="Confirm email (Reset Password)" subtitle={!isTimeoutRemaining ? `Resend code again in ${timeLeft}` : "You didn't get the code?"} buttonText={!isTimeoutRemaining ? '' : 'Resend'} buttonHandleClick={buttonHandleClick} />
			<ForgotVerifyForm setAuthType={setAuthType} verifyCodeResend={verifyCodeResend} setVerifyCodeResend={setVerifyCodeResend} />
			<AuthFooter />
		</>
	)
}

export default ForgotVerify;