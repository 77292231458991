import axios from "axios";

const s3upload = async (url, file, onSuccess = () => { }, onCancel = () => { }, onProgress = () => { }, cancelToken) => {
	try {
		const response = await axios.put(url, file, {
			cancelToken: cancelToken.token,
			onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
				onProgress(percentCompleted);
			}
		});
		onSuccess(response);
	}
	catch (error) {
		if (axios.isCancel(error)) {
			onCancel();
		}
		else {
			alert('Error during file upload');
		}
	}
};

export default s3upload;