import React from "react";

const Skeleton = () => {

	return (

		<div className="app-browse-loop-item">
			<div className={'thumbnail skeleton'}>
				<div className={'thumbnail-img skeleton'}></div>
			</div>
			<h4 className={'skeleton skeleton-text'}>&nbsp;</h4>
			<h3 className={'skeleton skeleton-text'}>&nbsp;</h3>
		</div>

	);

};

export default Skeleton;