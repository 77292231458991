import {useEffect, useRef} from 'react';
import axios from 'axios';
import ErrorConfig from '../Config/ErrorConfig';
import Notification from '../utils/NotificationUtils';
import {useTranslation} from 'react-i18next';
import {fetchAuthSession} from 'aws-amplify/auth';

const useApi = (config = {}, headers = {}, initialDisplayNotifications = true) => {

	const errors = ErrorConfig.errors;

	const ControllerRef = useRef(new AbortController());

	const {t} = useTranslation();

	const cancel = () => {

		ControllerRef.current.abort('Request cancel by user');

		ControllerRef.current = new AbortController();

	};

	useEffect(() => {
		return () => {
			cancel();
		};
	}, []);

	/**
	 *
	 * Create Axios instance
	 *
	 */

	const getInitialHeaders = ({token}) => {
		return {
			'Content-Type': 'application/json',
			'Authorization': token,
			'Accept-Language': localStorage.getItem('i18nextLng')
		}
	};

	/**
	 *
	 * fetchData
	 *
	 */

	const request = async(
		{
			url,
			method,
			body = null,
			onError = () => {},
			onSuccess = () => {},
			displayNotifications = initialDisplayNotifications,
		}) => {

		let accessToken = "";

		try {
			const { tokens } = (await fetchAuthSession());
			accessToken = tokens.accessToken;
		} catch (err) {
			console.log("axios error: ", err);
		}

		const initialHeaders = getInitialHeaders({token: accessToken});

		const initialConfig = {
			// eslint-disable-next-line no-undef
			baseURL: process.env.REACT_APP_API_HOST_NEW,
			timeout: 5000,
			headers: {...initialHeaders, ...headers},
			signal: ControllerRef.current.signal
		};

		const instance = axios.create({...initialConfig, ...config});

		try {

			const res = await instance[method](url, body);

			onSuccess(res.data);

			return res.data;

		} catch (err) {

			if(err.code !== 'ERR_CANCELED') {

				if(err.response && err.response.data && displayNotifications) {

					if(err.response.status === 422) {
						Notification.error(err.response.data.message, `Error ${err.response.status}!`);
					}

					else {
						Notification.error(t(`config.errorConfig.errors.${errors[err.response.status]}`), `Error ${err.response.status}!`);
					}

				}
				else {

					if(err.message !== undefined && displayNotifications) {

						Notification.error(err.message, 'error');

					}

				}

				onError(err);

			}

			//throw err;

		}

	};

	return {request, cancel};

};

export default useApi;
