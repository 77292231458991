import React, {useState} from 'react';
import {Form} from 'react-bootstrap';
import useAddressPredictions from '../../../../Hooks/useAddressPredictions';
import {MapPin, X} from 'react-feather';
import {useTranslation} from 'react-i18next';
import CloseOutside from '../../../Parts/CloseOutside';
import {Rings} from 'react-loader-spinner';
import Notification from '../../../../utils/NotificationUtils';
import {Configure} from 'react-instantsearch';
import LocationRadius from './LocationRadius';
import AnimatedDropdown, {AnimatedDropdownList, AnimatedDropdownListItem} from '../../../Parts/AnimatedDropdown';
import {getLocationName} from '../../../../utils/GoogleMapsUtils';
import {VirtualGeoSearch} from '../../../Browse/Filters/FiltersParts';

const initial = {
	selected: false,
	name: '',
	radius: 50,
	lat: false,
	lng: false
};

const LocationClient = ({setLocation}) => {

	const {t} = useTranslation();

	const [loading, setLoading] = useState(false);

	const handleSetLocation = async(position) => {

		const lat = position.coords.latitude;
		const lng = position.coords.longitude;

		const {name} = await getLocationName(lat, lng);

		setLocation(prevState => ({...prevState, lat: lat, lng: lng, name: name, selected: true}));

		setLoading(false);

	};

	const handleLocate = () => {

		setLoading(true);

		if(navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(handleSetLocation,
				(error) => {
					console.error('Error getting geolocation:', error);
					Notification.error(t('jobs.browse.search.where.notifications.notShared.text'), t('jobs.browse.search.where.notifications.notShared.title'));
				}
			);
		}
		else {
			console.error('Geolocation is not supported by this browser.');
			Notification.error(t('jobs.browse.search.where.notifications.notSupported.text'), t('jobs.browse.search.where.notifications.notSupported.title'));
		}
	};

	return (
		loading ?
			<Rings height="28" width="28" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/> :
			<MapPin width={20} onClick={handleLocate}/>
	);

};

const LocationInput = ({handleKeydown, handleFocus, loading, location, setLocation, handleReset}) => {

	const {t} = useTranslation();

	const handleKeyDown = (e) => {

		handleKeydown(e.target.value);

	};

	const handleChange = (e) => {

		setLocation(prevState => ({...prevState, name: e.target.value}));

	};

	return (
		<div className={'form-control-with-delete'}>
			<Form.Control className="font-weight-sm" placeholder={t('jobs.browse.search.where.enterCity')} value={location.name} onChange={handleChange} onKeyDown={handleKeyDown} onFocus={handleFocus}/>
			{loading &&
				<Rings height="20" width="20" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/>}
			{!location.selected && <LocationClient setLocation={setLocation}/>}
			{location.selected && !loading && <X width={20} onClick={handleReset}/>}
		</div>
	);
};

const Location = () => {

	const [location, setLocation] = useState(initial);

	const [open, setOpen] = useState(false);

	const handleChangePrediction = async(prediction) => {

		let placeId = prediction.place_id;

		const geocoder = new window.google.maps.Geocoder();

		setLocation(prevState => ({...prevState, name: prediction.description}));

		await geocoder.geocode({placeId},

			(results) => {

				setLocation(prevState => ({
					...prevState,
					lat: results[0].geometry.location.lat(),
					lng: results[0].geometry.location.lng(),
					selected: true
				}));

				setOpen(false);

			});

	};

	const {setAddress, loading, predictions} = useAddressPredictions();

	const handleReset = () => {

		setAddress('');

		setLocation(initial);

	};

	const DropdownPredictions = () => {
		return (
			<AnimatedDropdown className={'search-location-places'} isOpen={(open && predictions.length > 0) || loading}>
				{loading ?
					<Rings height="20" width="20" color="#474747" radius="6" ariaLabel="rings-loading" wrapperClass={'rings-loading'}/> :
					<AnimatedDropdownList>
						{predictions.map((prediction, index) =>
							<AnimatedDropdownListItem key={index} handleClick={() => handleChangePrediction(prediction)}>{prediction.description}</AnimatedDropdownListItem>)}
					</AnimatedDropdownList>}
			</AnimatedDropdown>
		);
	};

	return (
		<div className={'search-location'}>
			<VirtualGeoSearch/>
			{location.selected ? <Configure aroundLatLng={location.selected ? `${location.lat}, ${location.lng}` : false} aroundRadius={location.radius * 1000}/> : <Configure/>}
			<CloseOutside className={`search-location-place`} handleClose={() => setOpen(false)}>
				<LocationInput handleKeydown={setAddress} handleFocus={() => setOpen(true)} location={location} setLocation={setLocation} handleReset={handleReset}/>
				<DropdownPredictions/>
			</CloseOutside>
			{location.selected && <LocationRadius className={'ms-md-2'} setLocation={setLocation} location={location}/>}
		</div>
	);

};

export default Location;