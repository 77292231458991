import React from "react";
import {useTranslation} from 'react-i18next';
import FreeRepeater from '../Common/FreeRepeater';

const TabInterest = () => {

	const {t} = useTranslation();

	return (
		<>
			<FreeRepeater title={t('profile.profile.tabs.profileTab.index.sections.interests.title')} buttonTitle={t('profile.profile.tabs.profileTab.index.sections.interests.buttonTitle')} section={'interests'}/>
		</>
	);
};
export default TabInterest;
