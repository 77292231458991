import React from 'react';

const SkeletonMessagesContent = () => {
	return(
		<div className="skeleton-messages-content">
			<div className={'skeleton-chat-wrapper'}>
				<div className="skeleton skeleton-chat"></div>
			</div>
			<div className={'skeleton-chat-wrapper'}>
				<div className="skeleton skeleton-chat"></div>
			</div>
			<div className={'skeleton-chat-wrapper'}>
				<div className="skeleton skeleton-chat"></div>
			</div>
			<div className={'skeleton-chat-wrapper'}>
				<div className="skeleton skeleton-chat"></div>
			</div>
			<div className={'skeleton-chat-wrapper'}>
				<div className="skeleton skeleton-chat"></div>
			</div>
		</div>
	)
}

export default SkeletonMessagesContent;