import React, {useCallback, useEffect, useRef} from "react";
import {Col, Nav, Row, Tab} from 'react-bootstrap';
import {Loader} from '../../Parts/Loaders';
import ProfileStatus from "./ProfileStatus";
import useUser from "../../../Hooks/ReduxHooks/useUser";
const useTabs = (Configuration, sectionDefault = '', scrollTopElement) => {

	const url = new URLSearchParams(window.location.search);

	const tab = url.get('tab');

	const {isMobile} = useUser();

	const active = () => {

		if(tab) {

			return tab

		}

		return sectionDefault;

	};

	const TabsNavItem = ({ tab, navPillScrollRef }) => {
		const navItemRef = useRef();

		const scrollToCenter = () => {
			if (navItemRef.current) {
				const navPillScroll = navPillScrollRef.current;
				const item = navItemRef.current;

				const containerWidth = navPillScroll.offsetWidth;
				const itemWidth = item.offsetWidth;
				const itemOffsetLeft = item.offsetLeft;

				const newScrollLeft = itemOffsetLeft - (containerWidth / 2) + (itemWidth / 2);

				navPillScroll.scrollTo({
					left: Math.max(0, Math.min(newScrollLeft, navPillScroll.scrollWidth - containerWidth)),
					behavior: 'smooth'
				});
			}
		};

		return (
			<>
				{tab.visible !== false &&
					<>
						{tab.isLink ?
							<div className={'d-flex align-items-center mt-0 m-lg-3'}>
								{tab.title}
							</div>
							:
							<Nav.Item id={tab.section_id} ref={navItemRef} onClick={scrollToCenter}>
								<Nav.Link eventKey={`${tab.section_id}`}>
									{tab.title}
								</Nav.Link>
							</Nav.Item>
						}
					</>
				}
			</>
		);
	};

	const TabsNav = () => {
		const navPillScrollRef = useRef();

		useEffect(() => {
			if (navPillScrollRef.current && tab !== null) {
				const activeTab = navPillScrollRef.current.querySelector(`#${tab}`);
				if (activeTab) {
					const containerWidth = navPillScrollRef.current.offsetWidth;
					const itemWidth = activeTab.offsetWidth;
					const itemOffsetLeft = activeTab.offsetLeft;

					navPillScrollRef.current.scrollLeft = itemOffsetLeft - (containerWidth / 2) + (itemWidth / 2);
				}
			}
		}, [tab]);

		return (
			<Col lg={3} className="position-sticky top-0 bg-white nav-pills-wrapper mb-md-0 mb-3">
				<Nav variant="pills" className="flex-row flex-lg-column mb-0" ref={navPillScrollRef}>
					{Configuration.map((tab, index) => (
						<TabsNavItem key={index} tab={tab} navPillScrollRef={navPillScrollRef} />
					))}
				</Nav>
			</Col>
		);
	};

	const TabsContentPane = ({tab}) => {

		return (
			<>
				{tab.visible !== false &&
					<Tab.Pane eventKey={`${tab.section_id}`}>
						{tab.components}
					</Tab.Pane>
				}
			</>
		);

	};

	const TabsContent = ({displayLoader, actionComponent, children}) => {

		return (
			<Col lg={9}>
				{children}
				<Tab.Content>
					{displayLoader ? <Loader/> : Configuration.map((tab, index) => <TabsContentPane key={index} tab={tab}/>)}
				</Tab.Content>
				{actionComponent}
			</Col>
		);
	};

	const Tabs = useCallback(({handleChange = () => {}, displayLoader = false, actionComponent, children}) => {

		const handleChangeInit = (key, e) => {

			const exists = url.get('tab');

			if (exists !== null) {

				url.set('tab', key);

			} else {

				url.append('tab', key);

			}

			window.history.pushState({}, '', `${window.location.origin}${window.location.pathname}?${url.toString()}`);

			handleChange(key);
		}

		return (
			<Tab.Container id="edit-cv-tab" defaultActiveKey={active} onSelect={(key, e) => handleChangeInit(key, e)}>
				{isMobile && <ProfileStatus />}
				<Row className="mt-5 d-flex justify-content-between position-relative">
					<TabsNav/>
					<TabsContent displayLoader={displayLoader} actionComponent={actionComponent}>{children}</TabsContent>
				</Row>
			</Tab.Container>
		);
	}, [])

	return {Tabs, TabsContent, TabsContentPane, TabsNav, TabsNavItem};
};

export default useTabs;
