import React from "react";
import placeholderImage from "../../../assets/images/placeholder.jpg";
import useMessage from "../../../Hooks/ReduxHooks/useMessage";

const UserLogoContent = ({url}) => {

	const handleError = (e) => {
		e.target.src = placeholderImage;
	}

	return(
		<div className="avatar rounded-5">
			{url ? <img src={url} alt="Placeholder Image" onError={handleError} /> : <img src={placeholderImage} alt="Placeholder Image" />}
		</div>
	)
}

const Item = ({conversation}) => {

	const {handleSetChatID, handleSetConversationHistory, chatID} = useMessage();

	const handleClick = () => {
		if(chatID !== conversation.receiverId){
			handleSetChatID(conversation.receiverId)
			handleSetConversationHistory({count: 0, last: null, items: []})
		}
	}

	return(
		<div className={`d-flex align-items-center user cursor-pointer ${conversation.receiverId === chatID && 'active'}`} onClick={handleClick}>
			<UserLogoContent url={conversation.receiverAvatar} />
			<div className="d-flex flex-column ms-2">
				<span className="font-xxs text-gray">
					{/*{conversation.date ? timestampToDate(Number(item.date), localStorage.getItem('i18nextLng')) : ''}*/}
				</span>
				<span className={`font-weight-md ${conversation.unreadCount > 0 ? 'unread' : ''}`}>{conversation.receiverName !== null ? conversation.receiverName.slice(0, 25) : 'No Name'}</span>
			</div>
		</div>
	)
}

export default Item;