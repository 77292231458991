import React from "react";
import Thumbnail from '../Thumbnail/Thumbnail';
import ItemFooter from '../Item/ItemFooter';
import {Eye, X} from 'react-feather';
import MoreOptions from '../../Parts/Misc/MoreOptions';
import {useTranslation} from 'react-i18next';
import {Link} from "react-router-dom";
import useItem from "../../../Hooks/useItem";
import ObserverButton from "../Parts/ObserverButton";

const Content = ({item, handleClose, active}) => {

	const {t} = useTranslation();

	const {itemLink} = useItem(item)

	return (
		<div className={`popup-item-wrapper`}>
			<div className={'popup-item-box overflow-hidden'}>
				<div className={'popup-header'}>
					<div className={'left'}>
						<ObserverButton className={'btn-rounded me-2'} size={18} isText={false} uid={item.uuid}/>
					</div>
					<div className={'center'}>
						<Link to={itemLink()}>
							<button className={'btn-rounded align-self-center pe-3 ps-3'}>
								<Eye size={18} className={'me-2'}/>
								<span>{t(`jobs.popup.header.view`)}</span>
							</button>
						</Link>
					</div>
					<div className={'right'}>
						<MoreOptions item={item} options={['Share', 'Observe', 'ShowSimilar', 'NoMore', 'Report', 'Hide', 'Close']}/>
						<button className={'btn-rounded close'} onClick={handleClose}>
							<X size={18}/>
						</button>
					</div>
				</div>
				<div className={'popup-thumbnail'}>
					<Thumbnail item={item} autoplay={active}/>
				</div>
				<Link to={itemLink()}>
					<div className="popup-footer">
						<div className="popup-footer-wrapper w-100">
							<h3 className="font-xl text-limit w-100">{item.name}</h3>
							<ItemFooter item={item}/>
						</div>
					</div>
				</Link>
				<div className={'overlay'}></div>
			</div>
		</div>
	);

};

const SliderPopupItem = ({index, loopIndex, item, handleClose}) => {

	const active = loopIndex === index;

	const isVisible = !((loopIndex + 1) !== index && (loopIndex - 1) !== index && (loopIndex + 2) !== index && (loopIndex - 2) !== index && (loopIndex + 3) !== index && (loopIndex - 3) !== index && !active);

	return (
		<div className={`popup-item ${active ? `active` : ``}`}>
			{isVisible && <Content item={item} handleClose={handleClose} active={active}/>}
		</div>
	);

};

export default SliderPopupItem;

