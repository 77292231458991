import React, {useEffect, useRef, useState} from "react";
import {MapPin} from 'react-feather';
import {Default} from '../../../utils/GoogleMapsUtils';
import {useTranslation} from 'react-i18next';
import getMapZoom from '../../../utils/getMapZoom';
import useLoadGoogleMaps from "../../../Hooks/useLoadGoogleMaps";
import { PiWarningCircleLight } from "react-icons/pi";
const PlacesName = ({location, setCurrentLocationMap, type}) => {
	const { t } = useTranslation();
	return(
		<>
			<h2 className="mt-4 mb-2">{t('jobs.single.type.freelancer.freelancer.titles.location')}</h2>
			<div className="mt-3 mb-3 places-name">
				{location.map((el, index) => (
					<React.Fragment key={index}>
						{el.name &&
							<button className="mt-2 font-lg font-family-open-sans" onClick={() => setCurrentLocationMap({lat: el.lat, lng: el.lon, radius: el.radius})}>
								<MapPin size={22} /> {el.name} {type === 'free' || type === 'freelance' ? <>+{el.radius} km</> : ''}
							</button>
						}
					</React.Fragment>
				))}
			</div>
		</>
	)
}

const MapComponent = ({ location = [], type }) => {

	const { isLoaded, error } = useLoadGoogleMaps();

	const mapRef = useRef();
	const lat = location.length !== 0 ? location[0].lat : null
	const lng = location.length !== 0 ? location[0].lon : null
	const radius = location.length !== 0 ? location[0].radius : null
	const [currentLocationMap, setCurrentLocationMap] = useState({lat: lat, lng: lng, radius: radius})

	if(!Array.isArray(location) || location.length === 0){
		return(<></>)
	}

	useEffect(() => {
		if (isLoaded && mapRef.current && window.google) {
			const map = new window.google.maps.Map(mapRef.current, {
				zoom: getMapZoom(currentLocationMap.radius),
				center: currentLocationMap,
				mapId: Default.mapId,
				disableDefaultUI: true,
			});
			location.map(el => {
				new window.google.maps.Circle({
					strokeColor: "#aa60f9",
					strokeOpacity: 0.8,
					strokeWeight: 1,
					fillColor: "#5100b9",
					fillOpacity: 0.2,
					map,
					radius: el.radius * 1000,
					position: { lat: el.lat, lng: el.lon },
					center: { lat: el.lat, lng: el.lon },
				});
			})

		}
	}, [currentLocationMap, isLoaded]);

	if(error){
		return(
			<div className={'bg-gray d-flex flex-column align-items-center pt-5 pb-5 mt-3 rounded-3'}>
				<PiWarningCircleLight size={36} className={'mb-1'} />
				<p className='m-0 font-family-open-sans'>
					Error while loading maps
				</p>
			</div>
		)
	}

	return(
		<div className={'get-map'}>
			<PlacesName location={location} setCurrentLocationMap={setCurrentLocationMap} type={type} />
			<div className="map-container" id="map" ref={mapRef}></div>
		</div>
	)

};

export default MapComponent;
