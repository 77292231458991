import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import {useDispatch} from "react-redux";
import useError from "../../Hooks/ReduxHooks/useError";
import {useWebSocket} from "../../Hooks/ReduxHooks/useWebSocket";
import {Container} from "react-bootstrap";
import './Assets/profile-media-query.scss';
import './Assets/profile.scss';
const AccountSection = () => {
	const dispatch = useDispatch();

	const {handleSetIndexStatusValue, handleFetchProfileStatus} = useError();

	const {register, unregister} = useWebSocket();

	useEffect(() => {
		handleFetchProfileStatus()
	}, []);

	useEffect(() => {
		const handleUserVerificationCompleted = (data) => {
			handleSetIndexStatusValue(data.index_status);
			handleFetchProfileStatus();
		};

		register('user_verification_completed', handleUserVerificationCompleted);

		return () => {
			unregister('user_verification_completed', handleUserVerificationCompleted);
		};
	}, [dispatch]);

	return (
		<section className={`profile profile-type-free`}>
			<Container>
				<Outlet />
			</Container>
		</section>
	);
};

export default AccountSection;