import React from "react";

const SwitchBuyer = ({handleChange}) => {

	return(
		<div className="w-100 switch-buyer mt-3 p-4 rounded-5">
			<label className="d-flex align-items-center">
				<p className={`text-uppercase mb-0 font-xxxl text-primary font-weight-md`}>Become a seller</p>
				<div className="toggle d-flex justify-content-between align-items-center cursor-pointer ms-3">
					<input className="toggle-checkbox" type="checkbox" onChange={() => handleChange('provider')} />
					<div className="toggle-switch"></div>
				</div>
			</label>
			<p className="font-xl font-family-open-sans text-gray-444 line mt-2 mb-0 font-weight-sm" style={{lineHeight: '20px'}}>
				To appear in search results, you must add a primary payment method. Payment is collected at the beginning of each billing period from the day of your first transaction.
				You can cancel your subscription at any time by removing your card. Your account will remain active, but other users will not be able to find you through our search engine.
				You will still be able to share your profile using the share function.
			</p>
		</div>
	)
}

export default SwitchBuyer;