import React from "react";
import {Mail, Phone, Send} from 'react-feather';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import FloatingButtonsTooltip from '../../Parts/FloatingButtons/FloatingButtonsTooltip';
import Auth from "../../Auth/Auth";
import useUser from "../../../Hooks/ReduxHooks/useUser";
import usePopup from "../../../Hooks/ReduxHooks/usePopup";

const ContactSection = ({content, tooltipText, status, linkHref}) => {

	const { t } = useTranslation();

	if(status === null || !status) {

		return <></>;

	}

	if(status === 'login') {
		content = t('jobs.single.floating.contact.content');
	}

	return (
		<FloatingButtonsTooltip tooltipValue={tooltipText} linkHref={linkHref}>
			{content}
		</FloatingButtonsTooltip>
	);

};

const Message = ({item}) => {
	const { t } = useTranslation();

	if(!item.contact_methods.direct){
		return <></>
	}
	return(
		<FloatingButtonsTooltip tooltipValue={t('parts.floatingButtonsTooltip.tooltipValues.directMessage')}>
			<Link to={`/messages?uid=${item.userId ? item.userId : item.userId}`} className="floating-button"><Send /></Link>
		</FloatingButtonsTooltip>
	)
}

const Contact = ({item}) => {
	const {user, isMobile} = useUser();
	const {openPopup} = usePopup();
	if(!item){
		return 
	}
	const PhoneContent = () => {
		return(
			<div onClick={() => !user ? openPopup(Auth, { type: 'login' }) : undefined}>
				{isMobile || user === null && <button className={'content floating-button'}><Phone /></button>}
				{!isMobile && user !== null && <button className={'content floating-button'} href={`tel:${item.contact_methods.phone}`}><Phone /></button>}
			</div>
		)
	}

	const EmailContent = () => {
		return(
			<div onClick={() => !user ? openPopup(Auth, { type: 'login' }) : undefined}>
				{isMobile && <button className={'content floating-button'}><Mail /></button>}
				{!isMobile && <a className={'content floating-button'} href={`mailto:${item.contact_methods.email}`}><Mail /></a>}
			</div>
		)
	}

	return (

		<>

			<ContactSection status={item.contact_methods.phone ? true : false} content={<PhoneContent />} tooltipText={item.contact_methods.phone} linkHref={`tel:${item.contact_methods.phone}`}/>
			<ContactSection status={item.contact_methods.email ? true : false} content={<EmailContent />} tooltipText={item.contact_methods.email} linkHref={`mailto:${item.contact_methods.email}`}/>
			<Message item={item}/>
		</>

	);

};

export default Contact;
