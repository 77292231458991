// import
import React from 'react';
import {
	RequireAuth,
	RequireSeller,
	RequireValidUserOrQuest
} from './Requires';
import Browse from './Components/Browse/Browse';
import Single from './Components/Single/Single';
import Premium from './Components/Premium/Premium';
import Observations from './Components/Profile/Observations';
import NewMessages from './Components/Messages/Messages';
import NotFound from './Components/Static/NotFound';
import AccountSection from './Components/Profile/AccountSection';
import Profile from './Components/Profile/Edit';
import Edit from './Components/Profile/Edit';
import Settings from './Components/Profile/Settings';
import {Default} from './Components/Layout/Layouts';

const routes = [
	{
		path: "/",
		element: <RequireValidUserOrQuest/>,
		children: [
			{
				element: <Default/>,
				children: [
					{path: "/", element: <Browse/>},
					{path: "profile/:id", element: <Single type={'free'}/>},
					{path: "profile/:id/:slug", element: <Single type={'free'}/>},
					{path: "offer/:id", element: <Single type={'job'}/>},
					{path: "offer/:id/:slug", element: <Single type={'job'}/>},
					{path: "profile/:id", element: <Single type={'free'}/>},
					{path: "profile/:id/:slug", element: <Single type={'free'}/>},
					{path: '/premium', element: <Premium/>},
					{
						element: <RequireAuth/>,
						children: [
							{
								path: "account/*",
								element: <AccountSection/>,
								children: [
									{
										element: <RequireSeller />,
										children: [
											{path: "", element: <Single/>},
											{path: "edit", element: <Profile/>},
											{path: "edit/:tab", element: <Edit/>},
										]
									},
									{path: "settings", element: <Settings/>}
								]
							},
							{
								path: 'observations/*',
								element: <Observations/>
							},
							{
								path: 'messages/*',
								element: <NewMessages/>
							},
						]
					}
				]
			}
		]
	},
	{
		path: '*',
		element: <NotFound/>
	}
];
export default routes;
