import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Rings} from 'react-loader-spinner';
import {EyeOff} from 'react-feather';
import useUser from "./ReduxHooks/useUser";
import useApi from "./useApi";

const useHide = (uuid) => {

	const {request} = useApi();

	const [loading, setLoading] = useState();

	const {user, handleSetUser} = useUser() || {};

	const isHidden = user && 'hidden_profiles' in user && user.hidden_profiles.includes(uuid);

	/**
	 *
	 * Handle change hide of item
	 *
	 */

	const handleHide = () => {

		if(loading) {

			return;

		}

		setLoading(true)

		request({
			method: 'post',
			url: `/profile/hide/${uuid}`,
			onSuccess: (data) => {
				handleSetUser(({...user, hidden_profiles: user.hidden_profiles.filter(el => el !== uuid).concat(data.status === 'created' ? uuid : [])}));
			}
		}).then(() => setLoading(false))

	};

	/**
	 *
	 * Display Hide Button
	 *
	 * @param item
	 * @param useText
	 * @param Hidden
	 * @constructor
	 *
	 */

	return {
		loading,
		handleHide,
		isHidden
	}

}

export const HideButton = ({item, loading = true, config = {}}, handleHide = () => {}) => {

	const {t} = useTranslation();

	const defaultOptions = {
		showText: true,
	}

	const parsedConfig = {...defaultOptions, ...config};

	const {user} = useUser() || {};

	const isHidden = user && 'hidden_profiles' in user && user.hidden_profiles.includes(user.id);

	if(user === null) {

		return (<></>);

	}

	const Icon = () => {

		return (
			<div className={'hide-icon'}>
				{loading ? <Rings height="16" width="16" color="#ffffff" radius="6" visible={true} ariaLabel="rings-loading"/> : <EyeOff size={16}/>}
			</div>
		);

	};

	const Text = () => {

		return loading ? <>{t('jobs.loop.hide.sending')}</> : <>{isHidden ? t('jobs.loop.hide.show') : t('jobs.loop.hide.hide')}</>;

	};

	return (
		<button className={'hide icon-button'} onClick={handleHide} disabled={loading}>
			<Icon/>
			{parsedConfig.showText && <Text/>}
		</button>
	);

}

export default useHide;
