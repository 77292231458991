import TopBar from "../Profile/TopBar";
import './Assets/main.scss';
import {Col, Container, Row} from "react-bootstrap";
import PremiumFeatures from "./Parts/PremiumFeatures";
import PremiumPayment from "./Parts/PremiumPayment";
import useUser from "../../Hooks/ReduxHooks/useUser";

const Premium = () => {
	const {isMobile} = useUser();
	return(
		<div className="premium">
			<Container>
				<TopBar title="Go Premium" backButton={true}/>
				<Row className="mt-5">
					{isMobile &&
						<Col className="mb-4">
							<PremiumPayment />
						</Col>
					}
					<Col md={8}>
						<PremiumFeatures />
					</Col>
					{!isMobile &&
						<Col>
							<PremiumPayment />
						</Col>
					}
				</Row>
			</Container>
		</div>
	)
}

export default Premium;