import React, {useEffect, useState} from "react";
import {Page} from '../../../Layout/Page';
import {useTranslation} from 'react-i18next';
import TopBar from '../../TopBar';
import ProfileStatus from "../../Common/ProfileStatus";
import useUser from "../../../../Hooks/ReduxHooks/useUser";
import {Container} from "react-bootstrap";
import ImagesSkeleton from "./Skeleton/ImagesSkeleton";
import useApi from "../../../../Hooks/useApi";
import GalleryItems from "./Gallery/GalleryItems";

const GalleryTab = () => {

	const {t} = useTranslation();

	const {isMobile} = useUser();

	const [images, setImages] = useState([])
	const [loading, setLoading] = useState(false)

	const { request } = useApi();

	useEffect(() => {
		setLoading(true)
		request({
			method: 'get',
			url: '/profile/gallery',
			onSuccess: data => {
				setImages(data.sort((a, b) => b.createdAt - a.createdAt));
			}
		}).finally(() => setLoading(false))
	}, []);

	return (
		<Page title={t('pageTitles.galleryAndVideo')}>
			<TopBar title={t('profile.profile.edit.galleryTab.title')} desc={t('profile.profile.edit.galleryTab.description')} radius={window.innerWidth < 991}/>
			{!isMobile && <ProfileStatus />}
			<div className="mt-4 mb-4 profile-gallery d-flex flex-column flex-lg-row">
				<div className={'gallery-items position-relative'} id="gallery-items">
					{loading && <ImagesSkeleton/>}
					{!loading && <GalleryItems images={images} setImages={setImages}/>}
				</div>
			</div>
		</Page>
	);

};

export default GalleryTab;
